import { API_PREFIX, getAxios } from './espAxios';
import { CreateOrder } from '../models/CreateOrder';
import { Order } from '../models/Order';
import { AccountHistory } from '../models/AccountHistory';
// import Axios from 'axios';

const BASE_SERVICE_PATH = '/order';

export type OrderResponse = {
  order: Order;
};

/**
 * Method requests a single property
 */
export function createOrder(order: CreateOrder): Promise<OrderResponse> {
  return getAxios('order')
    .post<OrderResponse>(`${BASE_SERVICE_PATH}${API_PREFIX}/orders`, order)
    .then((resp: any) => resp.data);
}

export function loadAccountHistory(): Promise<AccountHistory> {
  return getAxios('order')
    .get<AccountHistory>(`${BASE_SERVICE_PATH}${API_PREFIX}/account-history`)
    .then((resp: any) => resp.data);
}
