import React, { createContext, ReactNode, useContext, useState } from 'react';
import { AxiosError } from 'axios';
import { FastFactResponse } from '../models/Facts/FastFactResponse';
import { loadFacts } from '../services/FastFacts';
import { TaxFact } from '../models/Facts/TaxFact';

interface FastFactsContextType {
  loading: boolean;
  loaded: boolean;
  error: AxiosError | undefined;
  data: FastFactResponse | null;
  getFastFacts: (county: string) => Promise<void>;
  clearFastFacts: () => void;
}

const FastFactsContext = createContext<FastFactsContextType>(null!);

export function FastFactsProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [data, setData] = useState<TaxFact | null>(null);
  const [error, setError] = useState<AxiosError | undefined>();
  // keep track of last county to prevent excessive requests
  const [lastCounty, setLastCounty] = useState<string>('');

  function handleError(err: AxiosError) {
    setError(err);
    setLoading(false);
    setLoaded(true);
  }

  const getFastFacts = async (county: string) => {
    if (county !== lastCounty) {
      setLoading(true);
      loadFacts(county)
        .then(taxData => {
          setData(taxData);
          setLoaded(true);
          setLoading(false);
          setLastCounty(county);
        })
        .catch(handleError);
    }
  };

  const clearFastFacts = () => {
    setLoaded(false);
    setLoading(false);
    setData(null);
  };

  const value = {
    getFastFacts,
    loading,
    loaded,
    error,
    data,
    clearFastFacts,
  };

  return <FastFactsContext.Provider value={value}>{children}</FastFactsContext.Provider>;
}

export function useFastFacts(): FastFactsContextType {
  return useContext(FastFactsContext);
}
