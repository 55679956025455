import { API_PREFIX, getAxios } from './espAxios';
import { Product } from '../models/Product';

const BASE_SERVICE_PATH = '/product';

export type ProductResponse = Product[];
/**
 * Method requests a list of products
 */
export function getProducts(): Promise<ProductResponse> {
  return getAxios('product')
    .get<ProductResponse>(`${BASE_SERVICE_PATH}${API_PREFIX}/products`)
    .then(resp => resp.data);
}
