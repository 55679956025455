import React, { createContext, FunctionComponent, useReducer } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';
import { BaseState } from '../models/BaseState';
import { CreateOrder } from '../models/CreateOrder';
import { Order } from '../models/Order';
import { ResponseError } from '../models/ResponseError';
import * as orderService from '../services/order';
import { ROUTE } from '../constants';

// State
interface OrdersState extends BaseState {
  currentOrder?: Order;
}

const defaultState: OrdersState = { loading: false };

type ContextProps = {
  state: OrdersState;
  dispatch?: {};
  selectors?: {
    selectCurrentOrder: () => Order | undefined;
    isLoading: () => boolean;
  };
  methods?: {
    createOrder: (createOrder: CreateOrder) => Promise<void>;
  };
};

const OrdersContext = createContext<ContextProps>({
  state: defaultState,
});

// Reducer & Actions
type Action = {
  type: ACTION;
  payload?: Order | ResponseError;
};

enum ACTION {
  CREATE_ORDER = 'actionCreateOrder',
  CREATE_ORDER_SUCCESS = 'actionCreateOrderSuccess',
  CREATE_ORDER_FAILURE = 'actionCreateOrderFailure',
}

function OrdersReducer(state: OrdersState, action: Action): OrdersState {
  switch (action.type) {
    case ACTION.CREATE_ORDER:
      return {
        ...state,
        loading: true,
        errors: undefined,
      };
    case ACTION.CREATE_ORDER_SUCCESS: {
      const order = action.payload as Order;

      return {
        ...state,
        currentOrder: order,
        errors: undefined,
        loading: false,
      };
    }
    case ACTION.CREATE_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        errors: (action.payload as ResponseError).errors,
      };
    default:
      return state;
  }
}

// Business Methods
async function submitCreateOrder(
  dispatch: React.Dispatch<Action>,
  navigate: NavigateFunction,
  createOrder: CreateOrder
) {
  dispatch({ type: ACTION.CREATE_ORDER });

  orderService
    .createOrder(createOrder)
    .then((response: orderService.OrderResponse) => {
      dispatch({
        type: ACTION.CREATE_ORDER_SUCCESS,
        payload: response.order,
      });

      navigate(ROUTE.ACCOUNT);
    })
    .catch((err: any) => {
      // TODO: Get error message from response
      dispatch({
        type: ACTION.CREATE_ORDER_FAILURE,
        payload: {
          errors: {
            message: err.response.data.message,
          },
        },
      });
    });
}

// Provider
export const OrdersProvider: FunctionComponent = props => {
  const [state, dispatch] = useReducer(OrdersReducer, defaultState);
  const navigate = useNavigate();
  return (
    <OrdersContext.Provider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={{
        state,
        dispatch: {},
        selectors: {
          selectCurrentOrder: () => state.currentOrder,
          isLoading: () => state.loading,
        },
        methods: {
          createOrder: (createOrder: CreateOrder) =>
            submitCreateOrder(dispatch, navigate, createOrder),
        },
      }}
    />
  );
};

export default OrdersContext;
