/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Theme } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import classNames from 'classnames';
import CardContainer from '../../../../../../global/components/Layout/CardContainer/CardContainer';
import TitleColumn from '../../../../../../global/components/Layout/TitleColumn/TitleColumn';
import ContentColumn from '../../../../../../global/components/Layout/ContentColumn/ContentColumn';
import ReconciledIcon from '../../../../../../global/components/icons/ReconciledIcon';
import toPrettyUSD from '../../../../../../global/helpers/toPrettyUSD';
import BREAKPOINTS from '../../../../../../global/constants/breakpoints';
import { BORDER_STYLE, COLORS, FONT_SERIF } from '../../../../../../global/constants/styles';
import { FastFactGraphData, FastFactHeader, FastFactsProps } from './models/FastFact';
import { FastFact } from '../../../../../../global/models/Facts/FastFact';
import InfoBox from '../../../../../../global/components/InfoBox/InfoBox';
import { Translate } from '../../../../../../libraries/translate';

function getHeaderData(
  state: string,
  countyName: string,
  billPeriod: string
): FastFactHeader | undefined {
  switch (state) {
    // add new states under new cases
    case 'CA':
      return {
        header: 'Personal Tax Distribution',
        subHeader: `Net property tax collected in ${countyName} County*`,
        contentColumnHeader: 'Tax Bill',
        source: {
          link: 'https://www.counties.org/post/datapile',
          title: 'California County Datapile',
        },
        disclaimers: ['1% of Net taxable assessed value'],
        annotation: 'Agency Taxes + Direct Charges',
        footer: `Net Property Tax collected-(${countyName} County)`,
        billPeriod,
      };

    default:
      return undefined;
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  smallTitle: {
    lineHeight: '10px !important',
    fontWeight: 'bold !important',
    fontStyle: 'italic',
    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'inline-block',
    },
  },
  source: {
    display: 'block !important',
    position: 'absolute',
    bottom: '24px',
    maxWidth: '175px',

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      position: 'initial',
      marginBottom: '0',
      paddingBottom: '16px',
      marginTop: '0 !important',
      maxWidth: '100%',
    },

    '& p': {
      margin: '0',
      fontFamily: 'Lato',
      fontSize: '12px !important',
      letterSpacing: '0.26px',
      lineHeight: '16px',
      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        marginRight: '4px',
      },
      '&:last-child': {
        color: '#666666',
        fontStyle: 'italic',
        marginTop: 8,
        [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
          paddingBottom: 16,
        },
      },
    },
    '& a': {
      margin: 0,
      color: '#34ACE0',
      fontFamily: 'Lato',
      fontSize: '12px',
      fontStyle: 'italic',
      letterSpacing: '0.26px',
      lineHeight: '16px',
      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        lineHeight: '20px',
      },
    },
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    alignItems: 'center',
    padding: '16px 0 16px 40px',

    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'block !important',
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'block',
      padding: 0,
      marginBottom: 16,
      '&:last-child': {
        marginBottom: 0,
      },
      '&:first-child': {
        marginTop: 24,
      },
    },
  },
  graph: {
    gridColumn: 'span 2 / span 2',
    fontFamily: 'Lato',
    fontSize: '16px',
    letterSpacing: '0.34px',
    lineHeight: '24px',
    '& p': {
      marginTop: 4,
      marginBottom: 0,
      '&:last-child': {
        color: COLORS.SUB_HEADER,
        marginLeft: 4,
        fontWeight: 'normal',
      },
    },
  },
  taxAmount: {
    fontFamily: 'Lato',
    fontSize: '16px',
    letterSpacing: '0.34px',
    lineHeight: '24px',
    textAlign: 'right',
    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      textAlign: 'left',
    },
    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      textAlign: 'left',
      marginTop: 4,
    },
  },
  graphBar: {
    height: '100%',
    animation: `$expandRight 2s ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes expandRight': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: '100%',
    },
  },
  contentColumnHeaderWrapper: {
    width: '100%',
    borderBottom: `1px dashed ${COLORS.BORDER}`,
    padding: '16px 24px 16px 24px',
    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      paddingLeft: '16px',
    },
  },
  preFooter: {
    borderTop: `1px dashed ${COLORS.BORDER}`,
    padding: '16px 24px 16px 24px',

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      padding: '16px !important',
    },
  },
  netTaxWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    alignItems: 'center',
    color: COLORS.GREY_02,
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.34px',
    lineHeight: '24px',

    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'block',
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      padding: 0,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  netTax: {
    gridColumn: 'span 2 / span 2',
    display: 'flex',
    justifyContent: 'flex-start',

    '& p': {
      margin: '0 0 0 16px',
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      '& p': {
        margin: '0 0 0 12px',
      },
    },
  },
  netTaxMessageDesktop: {
    display: 'block',
    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'none',
    },
  },
  netTaxMessageMobile: {
    display: 'none',
    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'block',
    },
  },
  bold: {
    fontWeight: 'bold !important',
  },
  totalTax: {
    textAlign: 'right',
    fontSize: 20,
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      marginLeft: 'calc(1em + 20px)',
      marginTop: 8,
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      marginTop: 0,
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 325,
  },
  container: {
    marginBottom: '50px',
    display: 'inherit',
    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'block !important',
      marginBottom: 0,
    },
  },
  steps: {
    paddingTop: '65px !important',
  },
  titleColumn: (inputs: { minWidth: number }) => ({
    padding: '24px',
    margin: 0,
    borderRight: BORDER_STYLE,
    minWidth: 190,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      display: 'block',
      margin: 0,
      padding: 0,
      borderBottom: BORDER_STYLE,
      borderRight: 'none',
    },

    '& > *': {
      '&:last-child': {
        marginRight: 0,
      },
    },

    '& > * > div, & > * > p': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 8px 0',
      margin: 0,

      [theme.breakpoints.down(inputs.minWidth)]: {
        flexDirection: 'row',
        borderRight: 'none',
      },
    },

    '& hr': {
      width: '40px',
      height: '1px',
      backgroundColor: COLORS.BORDER,
      margin: '16px 0',
      borderWidth: 0,
    },

    '& h3': {
      color: COLORS.GREY_02,
      fontWeight: 'bold',
      fontFamily: FONT_SERIF,
      fontSize: '20px',
      lineHeight: '25px',
      marginBottom: '8px',
    },

    '& p': {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.3px',
      lineHeight: '20px',
    },
  }),
  percentLabel: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
}));

function FastFactsPersonal({
  county,
  amount,
  netTaxableValue,
  billPeriod,
  steps,
  error,
}: FastFactsProps) {
  const [dataKeys, setDataKeys] = useState<string[]>([]);
  const [data, setData] = useState<FastFactGraphData[]>();
  const [values, setValues] = useState<any>();

  const styles = useStyles({ minWidth: 768 });

  const colors: string[] = [
    '#CD6133',
    '#34ACE0',
    '#FFDA79',
    '#227093',
    '#33CD61',
    '#6133CD',
    '#EB3B14',
    '#E034AC',
    '#ACE034',
    '#79FFDA',
    '#DA79FF',
  ];

  const calculateValues = () => {
    if (!county) {
      return;
    }

    const xTotal = (netTaxableValue || 0) / 100;
    const xCounty: FastFact = county || {};
    const newValues = {
      id: xCounty.id,
      countyName: xCounty.countyName,
      state: xCounty.state,
      taxRollId: xCounty.taxRollId,
      total: xTotal,
      county: (xTotal * xCounty.county) / 100,
      cities: (xTotal * xCounty.cities) / 100,
      schools: (xTotal * xCounty.schools) / 100,
      otherDistricts: (xTotal * xCounty.otherDistricts) / 100,
    };

    setValues(newValues);
  };

  useEffect(() => {
    setDataKeys(
      Object.keys(county || {}).filter(
        (x: string) =>
          ![
            'id',
            'taxRollId',
            'total',
            'netTaxableValue',
            'countyName',
            'state',
            'amount',
          ].includes(x)
      )
    );

    if (netTaxableValue) {
      calculateValues();
    }
  }, [county, netTaxableValue]);

  useEffect(() => {
    setData(
      dataKeys.map((key: string, index: number) => ({
        percentage: county ? (county[key] as number) : 0,
        hexCode: colors[index],
        label: key.split(/(?=[A-Z])/).join(' '),
        key,
      }))
    );
  }, [dataKeys]);

  function getHeaderDisplayedValue(): string {
    return toPrettyUSD(values.total * 100);
  }

  function getPreFooterDisplayedValue(): string {
    return toPrettyUSD((amount || 0) - values.total * 100);
  }

  function getFooterDisplayedValue(): string {
    return toPrettyUSD(amount || 0);
  }

  function getPreFooterTextIcon() {
    return <InfoOutlined />;
  }

  function getFooterIcon() {
    return <ReconciledIcon />;
  }

  function getContentHeaderIcon() {
    return <InfoOutlined />;
  }

  function getValue(key: string | undefined): string {
    if (!key) {
      return '';
    }
    return toPrettyUSD(values[key] * 100);
  }

  const headerData = getHeaderData(county?.state || '', county?.countyName || '', billPeriod || '');

  const footerText = headerData?.footer?.split('-');

  const preFooterText = headerData?.annotation?.split('-');

  const contentColumnHeaderText = headerData?.contentColumnHeader?.split('-');

  if (!county || !data) {
    return (
      <CardContainer className={styles.container}>
        <ContentColumn>
          <div className={styles.loading}>
            {error && (
              <InfoBox type="error" header="Oops, there was a problem">
                <Translate>
                  Sorry, we are unable to load county fast fact information at this time
                </Translate>
              </InfoBox>
            )}
            {!error && <CircularProgress />}
          </div>
        </ContentColumn>
      </CardContainer>
    );
  }

  return (
    <CardContainer className={styles.container}>
      <TitleColumn
        title={headerData?.header}
        secondaryTitle={headerData?.subHeader}
        tertiaryTitle={headerData?.billPeriod}
        className={classNames([styles.titleColumn, steps ? styles.steps : null])}
        miniDivider={false}
      >
        <div className={styles.source}>
          <hr />
          <p className={styles.smallTitle}>Source:</p>
          <a
            href={headerData?.source.link || 'https://www.counties.org/post/datapile'}
            target="_blank"
            rel="noreferrer"
          >
            {headerData?.source.title || 'California County Datapile'}
          </a>
          {headerData?.disclaimers?.map((disclaimer: string, index: number) => (
            <p key={disclaimer}>
              {'*'.repeat(index + 1)}
              {disclaimer}
            </p>
          ))}
        </div>
      </TitleColumn>
      <ContentColumn
        minWidth={635}
        header={
          !!headerData?.contentColumnHeader && (
            <div className={styles.contentColumnHeaderWrapper}>
              <div className={styles.netTaxWrapper}>
                <div className={styles.netTax}>
                  {getContentHeaderIcon()}
                  {/* on desktop, this text is all on one line */}
                  {/* on mobile, this text splits to a new line */}
                  <div className={styles.netTaxMessageDesktop}>
                    <p>{contentColumnHeaderText?.join(' ')}</p>
                  </div>
                  <div className={styles.netTaxMessageMobile}>
                    {contentColumnHeaderText?.map((text: string) => (
                      <p key={text}>{text}</p>
                    ))}
                  </div>
                </div>
                <div className={styles.totalTax}>{getHeaderDisplayedValue()}</div>
              </div>
            </div>
          )
        }
        preFooter={
          !!headerData?.annotation && (
            <div className={classNames([styles.netTaxWrapper, styles.preFooter])}>
              <div className={styles.netTax}>
                {getPreFooterTextIcon()}
                {/* on desktop, this text is all on one line */}
                {/* on mobile, this text splits to a new line */}
                <div className={styles.netTaxMessageDesktop}>
                  <p>{preFooterText?.join(' ')}</p>
                </div>
                <div className={styles.netTaxMessageMobile}>
                  {preFooterText?.map((text: string) => (
                    <p key={text}>{text}</p>
                  ))}
                </div>
              </div>
              <div className={styles.totalTax}>{getPreFooterDisplayedValue()}</div>
            </div>
          )
        }
        footer={
          <div className={styles.netTaxWrapper}>
            <div className={styles.netTax}>
              {getFooterIcon()}
              {/* on desktop, this text is all on one line */}
              {/* on mobile, this text splits to a new line */}
              <div className={styles.netTaxMessageDesktop}>
                <p>{footerText?.join(' ')}</p>
              </div>
              <div className={styles.netTaxMessageMobile}>
                {footerText?.map((text: string) => (
                  <p key={text}>{text}</p>
                ))}
              </div>
            </div>
            <div className={classNames([styles.totalTax, styles.bold])}>
              {getFooterDisplayedValue()}
            </div>
          </div>
        }
      >
        {data?.map(field => (
          <div className={styles.wrapper} key={field.label}>
            <div className={styles.graph}>
              <div style={{ width: `${field.percentage}%`, height: '3px' }}>
                <div className={styles.graphBar} style={{ background: field.hexCode }} />
              </div>
              <div className={styles.percentLabel}>
                <p>{`${field.label} `}</p>
                <p>{`${field.percentage}%`}</p>
              </div>
            </div>
            <div className={styles.taxAmount}>{getValue(field?.key)}</div>
          </div>
        ))}
      </ContentColumn>
    </CardContainer>
  );
}

export default FastFactsPersonal;
