import { Product } from '../../models/Product';

/**
 * Function formats the product's APN to include
 * mask, placeholder,and length values.
 *
 * TODO: Write tests that valid
 * - Validates delimiter is applied to mask and pattern
 * - Pattern uses # if numericOnly is set, otherwise *
 * - Mask uses 9 if numericOnly is set, otherwise *
 * - Validates correct number of "blocks" are created for Mask
 * - Validates correct number of "blocks" are created for Pattern
 * @param product Product to be formatted.
 */
function formatProductAPN(product: Product): Product {
  const formattedProduct = { ...product };
  const { blocks, delimiter } = product.apnLookupSettings;
  const maskChar = product.apnLookupSettings.numericOnly ? '9' : '*';
  const patternChar = product.apnLookupSettings.numericOnly ? '#' : '*';
  let placeholder = '';
  let mask = '';
  let length = 0;

  blocks?.forEach((block: number) => {
    let phValue = '';
    let maskValue = '';

    for (let i = 0; i < block; i += 1) {
      phValue += patternChar;
      maskValue += maskChar;
      length += 1;
    }

    if (placeholder) {
      placeholder += delimiter;
    }

    if (mask) {
      mask += delimiter;
    }

    placeholder += phValue;
    mask += maskValue;
  });

  formattedProduct.billNumberSettings = formattedProduct.billNumberSettings || {
    displayName: 'Bill Number',
  };

  formattedProduct.apnLookupSettings.abbreviation =
    formattedProduct.apnLookupSettings.abbreviation || 'APN';
  formattedProduct.apnLookupSettings.displayName =
    formattedProduct.apnLookupSettings.displayName || 'assessor parcel number';

  formattedProduct.apnLookupSettings = {
    ...formattedProduct.apnLookupSettings,
    placeholder,
    mask,
    length,
  };

  return formattedProduct;
}

const formatProduct = (product: Product): Product => {
  const formattedProduct = formatProductAPN(product);

  if ((formattedProduct as any).countyId) {
    formattedProduct.taxrollId = (formattedProduct as any).countyId;
  }

  return formattedProduct;
};

export default formatProduct;
