import React, { FunctionComponent, MouseEvent, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import { useMediaQuery } from '@material-ui/core';
import { Installment, TaxBill } from '../../../../../global/models/Property';
import PaymentScheduledIcon from '../../../../../global/components/icons/PaymentScheduledIcon';
import toPrettyUSD from '../../../../../global/helpers/toPrettyUSD';
import toPrettyDate from '../../../../../global/helpers/toPrettyDate';
import { BORDER_STYLE, COLORS, FONT_SERIF } from '../../../../../global/constants/styles';
import TitleColumn from '../../../../../global/components/Layout/TitleColumn/TitleColumn';
import PaymentSummaryColumn from './PaymentSummaryColumn';
import useTabsStyles from '../../../../../global/hooks/useTabsStyles';
import SectionCarousel from '../../../../../global/components/SectionCarousel';
import CanceledIcon from '../../../../../global/components/icons/CanceledIcon';
import PendingIcon from '../../../../../global/components/icons/PendingIcon';
import FailedIcon from '../../../../../global/components/icons/FailedIcon';
import BREAKPOINTS from '../../../../../global/constants/breakpoints';
import DisclaimerTag from '../../../../../global/components/DisclaimerTag';
import Disclaimer from '../../../../../global/components/Disclaimer';
import formatTaxYear from '../../../../../global/helpers/formatTaxYear';
import { Payment, PaymentContract } from '../../../../../global/models/PaymentContract';
import OrderStatus from '../../../../../global/components/OrderStatus';
import ScheduledPaymentDetails from './ScheduledPaymentDetails';
import { Translate } from '../../../../../libraries/translate';

interface Props {
  paymentContract: PaymentContract;
  taxBill: TaxBill;
  disclaimer?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    maxWidth: 'initial !important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.3)',
    borderRadius: '3px',

    '&::after': {
      content: '" "',
      position: 'absolute',
      left: '24px',
      top: 0,
      height: '8px',
      width: '40px',
      backgroundColor: COLORS.DEFAULT,
    },

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      display: 'block',
      border: `${BORDER_STYLE} !important`,
      boxShadow: `rgba(0, 0, 0, 0.3) 0px 1px 4px 0px !important`,
      marginBottom: 24,

      '& > div': {
        border: '0 !important',
      },
    },
  },

  table: {
    tableLayout: 'fixed',
    // width: '100%',
    minWidth: 430,
    display: 'table',
    padding: '0 24px',
    borderTop: BORDER_STYLE,
    paddingBottom: '24px',

    '& table': {
      width: '100%',
    },

    '& td, & th': {
      textAlign: 'left',
      padding: '16px 0',
      borderBottom: BORDER_STYLE,

      '&:last-child': {
        textAlign: 'right',

        '& > *': {
          marginRight: '8px',
        },
      },

      '&:first-child': {
        '& > *': {
          marginLeft: '8px',
        },
      },
    },

    '& th': {
      padding: '24px 0 16px 0',
      borderBottom: BORDER_STYLE,
      borderColor: `${COLORS.GREY_02} !important`,
    },

    '& tr:last-child': {
      '& td': {
        borderBottomWidth: 3,
        borderColor: COLORS.GREY_02,
      },
    },
  },

  status: {
    // width: '15%',
  },

  date: {
    // width: '10%',
  },

  receiptId: {
    color: `${COLORS.DEFAULT} !important`,
  },

  amount: {
    // width: '5%',
  },

  nav: {
    '& ul': {
      padding: '0 24px',
      borderColor: '#DDDDDD',
      fontSize: '14px',
      margin: 0,
      position: 'relative',
      zIndex: 1,

      '& li button': {
        height: '100%',
      },

      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        display: 'flex',
        justifyContent: 'space-evenly',

        '& li button': {
          padding: '0 24px',
        },
      },
    },
  },

  disclaimers: {
    padding: '0 24px',
  },

  canceledRow: {},

  pendingRow: {
    '& td:last-child': {
      fontWeight: 'bold',
    },
  },

  processedRow: {},

  scheduledRow: {},

  failedRow: {
    '& td': {
      color: COLORS.RED_01,
    },
  },

  cancelRowMarker: {
    textAlign: 'center !important' as any,
    border: 'none !important',
    padding: '0 !important',

    '& div': {
      marginTop: -1,
      backgroundColor: 'white',
      position: 'relative',

      '&::after': {
        content: '" "',
        borderTop: BORDER_STYLE,
        position: 'absolute',
        width: '100%',
        top: 'calc(50% - .5px)',
        left: 0,
      },
    },

    '& span': {
      border: BORDER_STYLE,
      borderRadius: 18,
      padding: '8px 24px',
      color: 'rgba(52,172,224,1)',
      position: 'relative',
      backgroundColor: COLORS.WHITE,
      zIndex: 1,
    },
  },

  orderStatus: {
    background: COLORS.WHITE,
    position: 'relative',
    zIndex: 1,

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      marginBottom: 16,
      '& > *': {
        borderTop: BORDER_STYLE,
      },
    },
  },

  multiPayment: {
    '& > * > *': {
      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        boxShadow: 'none !important',
        border: 'none !important',
      },
    },
  },

  titleCol: (inputs: { minWidth: number }) => ({
    padding: '24px !important',
    margin: '0',
    borderRight: BORDER_STYLE,
    minWidth: 190,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    [theme.breakpoints.down(inputs.minWidth)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '0',
      padding: '24px 16px 16px 16px',
      borderBottom: BORDER_STYLE,
      borderRight: 'none',
    },

    '& > *': {
      marginRight: 24,

      '&:last-child': {
        marginRight: 0,
      },
    },
    '& > * > div, & > * > p': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 8px 0',
      margin: 0,

      [theme.breakpoints.down(inputs.minWidth)]: {
        flexDirection: 'row',
        borderRight: 'none',
      },
    },

    '& h3': {
      color: COLORS.GREY_02,
      fontWeight: 'bold',
      fontFamily: FONT_SERIF,
      fontSize: '20px',
      lineHeight: '25px',
      marginBottom: '8px',
    },

    '& p': {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.3px',
      lineHeight: '20px',
    },
  }),
}));

const MultiplePaymentDetails: FunctionComponent<Props> = ({
  paymentContract,
  taxBill,
  disclaimer,
}: Props) => {
  const isMinWidth = useMediaQuery(`(min-width:${BREAKPOINTS.SM + 1}px)`);
  const styles = useStyles({ minWidth: BREAKPOINTS.SM });
  const tabsStyles = useTabsStyles();

  const [tab, setTab] = useState<'payments' | 'summary'>('payments');
  const [installments, setInstallments] = useState<Installment[]>();
  const [title, setTitle] = useState<string>();
  const [secondaryTitle, setSecondaryTitle] = useState<string>();
  const [disclaimers, setDisclaimers] = useState<React.ReactNode[]>();

  useEffect(() => {
    if (paymentContract && taxBill) {
      setInstallments(
        taxBill.installments.filter(
          installment =>
            paymentContract.installmentContracts.findIndex(
              i => i.installmentNumber === installment.installmentNumber
            ) > -1
        )
      );

      setTitle(paymentContract.billType === 3 ? 'Estimated Monthly' : 'Monthly');

      setSecondaryTitle(
        `${
          paymentContract.installmentContracts.length > 1 ? 'Installments' : 'Installment'
        } ${paymentContract.installmentContracts
          .sort((a, b) => a.installmentNumber - b.installmentNumber)
          .map(i => i.installmentNumber)
          .join(' and ')}`
      );
    }
  }, [paymentContract && taxBill]);

  useEffect(() => {
    const values = [disclaimer];
    if (paymentContract) {
      if (paymentContract.billType === 3) {
        values.push(
          <Disclaimer tag="1" key="1">
            Payments that will be recalculated when the official taxbill becomes available.
          </Disclaimer>
        );
      }
    }

    setDisclaimers(values);
  }, [paymentContract, disclaimer]);

  const handleActivePayment = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setTab('payments');
  }, []);

  const handleActiveSummary = useCallback((event: MouseEvent) => {
    event.preventDefault();
    setTab('summary');
  }, []);

  function renderCancelRow() {
    return (
      <td className={styles.cancelRowMarker} colSpan={4}>
        <div>
          <span>
            <Translate>Payment Canceled</Translate>
          </span>
        </div>
      </td>
    );
  }

  function renderRow(payment: Payment, canceled = false) {
    let icon;
    let rowStyle;

    if (payment.status === 'SUCCESS') {
      icon = (
        <span aria-label="Payment Processed">
          <CheckIcon />
        </span>
      );
      rowStyle = styles.processedRow;
    } else if (payment.status === 'FAILURE') {
      icon = (
        <span aria-label="Payment Failed">
          <FailedIcon />
        </span>
      );
      rowStyle = styles.failedRow;
    } else if (canceled) {
      icon = (
        <span aria-label="Payment Canceled">
          <CanceledIcon />
        </span>
      );
      rowStyle = styles.canceledRow;
    } else if (payment.status === 'SCHEDULED') {
      icon = (
        <span aria-label="Payment Scheduled">
          <PaymentScheduledIcon />
        </span>
      );
      rowStyle = styles.scheduledRow;
    } else {
      icon = (
        <span aria-label="Payment Pending">
          <PendingIcon />
        </span>
      );
      rowStyle = styles.pendingRow;
    }

    const amount = toPrettyUSD(payment.total);
    const paidDate = toPrettyDate(new Date(payment.dateKey));

    return (
      <tr key={payment.dateKey} className={rowStyle}>
        <td className={styles.status}>{icon}</td>
        <td className={styles.date}>
          <span>
            <Translate>{paidDate}</Translate>
          </span>
        </td>
        <td className={styles.receiptId}>
          <span>{payment.receiptId}</span>
        </td>
        <td className={styles.amount}>
          <span>
            {amount} {!payment.voucherInstallmentId && <DisclaimerTag tag="1" />}
          </span>
        </td>
      </tr>
    );
  }

  function renderRows() {
    const { status } = paymentContract;
    let cancelMarked = false;

    return paymentContract.schedule.map((payment: Payment) => {
      const rows = [];

      if (payment.total === 0) {
        rows.push(<></>);
      } else {
        if (!payment.orderId && status === 'CANCELED' && !cancelMarked) {
          cancelMarked = true;
          rows.push(renderCancelRow());
        }
        rows.push(renderRow(payment, cancelMarked));
      }
      return rows;
    });
  }

  function renderOrderStatus() {
    if (paymentContract.status === 'FAILURE') {
      return (
        <div className={styles.orderStatus}>
          <OrderStatus type="ERROR" />
        </div>
      );
    }

    if (paymentContract.status === 'CANCELED') {
      return (
        <div className={styles.orderStatus}>
          <OrderStatus type="INFO" status={paymentContract.status} />
        </div>
      );
    }

    return '';
  }

  if (paymentContract) {
    return (
      <>
        <section className={classnames([styles.container])}>
          <TitleColumn
            title={<Translate>{title}</Translate>}
            secondaryTitle={<Translate>{secondaryTitle}</Translate>}
            tertiaryTitle={formatTaxYear(taxBill.taxYear)}
            className={styles.titleCol}
          />

          {isMinWidth && (
            <div>
              {renderOrderStatus()}
              <PaymentSummaryColumn
                amountLabel="Total amount paid to ESP"
                amount={paymentContract.amountPaid}
                installments={installments}
                status=""
              />
              <div className={styles.table}>
                <table cellPadding="0" cellSpacing="0">
                  <thead>
                    <tr>
                      <th className={styles.status}>
                        <span>
                          <Translate>Status</Translate>
                        </span>
                      </th>
                      <th className={styles.date}>
                        <span>
                          <Translate>Payment Date</Translate>
                        </span>
                      </th>
                      <th className={styles.receiptId}>
                        <span>
                          <Translate>Receipt ID</Translate>
                        </span>
                      </th>
                      <th className={styles.amount}>
                        <span>
                          <Translate>Amount</Translate>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderRows()}</tbody>
                </table>
                {disclaimers}
              </div>
            </div>
          )}

          {!isMinWidth && (
            <>
              {renderOrderStatus()}
              <nav className={classnames([tabsStyles.tabs, styles.nav])}>
                <ul>
                  <li>
                    <button
                      type="button"
                      className={tab === 'payments' ? tabsStyles.activeTab : ''}
                      onClick={handleActivePayment}
                    >
                      <Translate>Payments</Translate>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className={tab === 'summary' ? tabsStyles.activeTab : ''}
                      onClick={handleActiveSummary}
                    >
                      <Translate>Summary</Translate>
                    </button>
                  </li>
                </ul>
              </nav>
              {tab === 'summary' && (
                <>
                  <PaymentSummaryColumn
                    amountLabel="Total  amount paid to ESP"
                    amount={paymentContract.amountPaid}
                    installments={installments}
                    status={paymentContract.status}
                  />
                </>
              )}
              {tab === 'payments' && (
                <SectionCarousel
                  items={paymentContract.schedule}
                  label="Payment"
                  showBorder={false}
                >
                  {(payment: Payment) => (
                    <ScheduledPaymentDetails payment={payment} paymentContract={paymentContract} />
                  )}
                </SectionCarousel>
              )}
            </>
          )}
        </section>
        {!isMinWidth && <div className={styles.disclaimers}>{disclaimers}</div>}
      </>
    );
  }

  return <></>;
};

export default MultiplePaymentDetails;
