import React, { useReducer, FunctionComponent } from 'react';
import {
  requestLogin,
  requestProfile,
  requestLogout,
  updateProfile,
  createProfile,
  APIResponseProfile,
} from '../services/auth';
import { BaseState } from '../models/BaseState';
import { ResponseError } from '../models/ResponseError';
import { UserData } from '../models/UserData';
import { SubmitProfileData, UpdateProfileData } from '../models/SubmitProfileData';

export interface AuthState extends BaseState {
  isLoggedIn: boolean;
  userData?: UserData;
}

type AuthAction = {
  type: ACTION;
  payload?: APIResponseProfile | ResponseError;
};

enum ACTION {
  SUBMIT_LOG_IN_INFO = 'actionSubmitLoginInfo',
  LOG_IN = 'actionLogIn',
  LOG_OUT = 'actionLogOut',
  FAILURE = 'actionFailure',
  LOADING = 'actionLoading',
  UPDATE_PROFILE_SUCCESS = 'actionUpdateProfileSuccess',
  CREATE_PROFILE = 'actionCreateProfile',
  CREATE_PROFILE_SUCCESS = 'actionCreateProfileSuccess',
  CLEAR_ERRORS = 'actionClearErrors',
}

type ContextProps = {
  state: AuthState;
  dispatch?: {
    updateProfile: (userData: APIResponseProfile) => void;
  };
  methods?: {
    initAuth: () => Promise<void>;
    logIn: (email: string, password: string) => Promise<void>;
    logOut: () => Promise<void>;
    updateProfile: (profile: UpdateProfileData) => Promise<void>;
    createProfile: (profile: SubmitProfileData) => Promise<void>;
    clearErrors: () => void;
  };
};

const defaultAuthState: AuthState = { isLoggedIn: false, loading: false };

const AuthContext = React.createContext<ContextProps>({
  state: defaultAuthState,
});

/**
 * START ACTIONS
 *
 * Form values here that will get passed into `dispatch()` (from `useReducer()` below)
 */

const setLogOutAction = (): AuthAction => ({
  type: ACTION.LOG_OUT,
});

const setUpdateProfileAction = (apiProfile: APIResponseProfile): AuthAction => ({
  type: ACTION.UPDATE_PROFILE_SUCCESS,
  payload: apiProfile,
});

/**
 * END ACTIONS
 */

/**
 * START METHODS
 *
 * Async stuff can happen in here
 */

async function initAuth(dispatch: React.Dispatch<AuthAction>) {
  let storedProfile;

  if (!storedProfile) {
    const { cookie } = document;

    let decodedUserDataFromCookie = decodeURIComponent(cookie)
      .split(';')
      .filter(x => x.includes('customer'));

    if (decodedUserDataFromCookie.length) {
      const [x] = decodedUserDataFromCookie;
      decodedUserDataFromCookie = x.split('customer=');
      const [, z] = decodedUserDataFromCookie;

      storedProfile = JSON.stringify({
        isLoggedIn: true,
        loading: false,
        userData: JSON.parse(z),
      });
    }
  }

  if (!storedProfile) {
    // No existing profile was found in localStorage, so the user isn't logged in
    return dispatch({
      type: ACTION.LOG_OUT,
    });
  }

  const { isLoggedIn }: AuthState = JSON.parse(storedProfile);

  if (isLoggedIn) {
    let profile;
    try {
      profile = await requestProfile();
    } catch (error) {
      // The request failed, likely due to an expired session. Be safe and log the user out
      dispatch(setLogOutAction());
      throw error;
    }

    return dispatch(setUpdateProfileAction(profile));
  }

  return undefined;
}

const submitLogin = async (
  dispatch: React.Dispatch<AuthAction>,
  email: string,
  password: string
) => {
  dispatch({ type: ACTION.LOADING });
  requestLogin(email, password)
    .then((resp: APIResponseProfile) => {
      dispatch({
        type: ACTION.LOG_IN,
        payload: {
          id: resp.id,
          firstname: resp.firstname,
          lastname: resp.lastname,
          email: resp.email,
          phone: resp.phone || '',
          language: resp.language,
        },
      });
    })
    .catch(() => {
      // TODO: Get error message from response
      dispatch({
        type: ACTION.FAILURE,
        payload: {
          errors: {
            message: 'Email address and password are invalid. Please try again.',
          },
        },
      });
    });
};

async function submitLogout(dispatch: React.Dispatch<AuthAction>) {
  await requestLogout();
  return dispatch(setLogOutAction());
}

function clearErrors(dispatch: React.Dispatch<AuthAction>) {
  dispatch({ type: ACTION.CLEAR_ERRORS });
}

async function submitUpdateProfile(
  dispatch: React.Dispatch<AuthAction>,
  profile: UpdateProfileData
) {
  dispatch({ type: ACTION.LOADING });
  updateProfile(profile)
    .then((resp: APIResponseProfile) => {
      dispatch({
        type: ACTION.UPDATE_PROFILE_SUCCESS,
        payload: resp,
      });
    })
    .catch(() => {
      // TODO: Get error message from response
      dispatch({
        type: ACTION.FAILURE,
        payload: {
          errors: {
            message:
              'Unable to send reset password instructions at this time. Please try again later.',
          },
        },
      });
    });
}

async function submitCreateProfile(
  dispatch: React.Dispatch<AuthAction>,
  profile: SubmitProfileData
) {
  dispatch({ type: ACTION.LOADING });
  createProfile(profile)
    .then((resp: APIResponseProfile) => {
      dispatch({
        type: ACTION.CREATE_PROFILE_SUCCESS,
        payload: resp,
      });
    })
    .catch(() => {
      // TODO: Get error message from response
      dispatch({
        type: ACTION.FAILURE,
        payload: {
          errors: {
            message: 'Unable to create an account at this time. Please try again later.',
          },
        },
      });
    });
}

/**
 * END METHODS
 */

/**
 * Reducer for app state
 */
function AuthReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case ACTION.LOG_IN:
    case ACTION.UPDATE_PROFILE_SUCCESS:
    case ACTION.CREATE_PROFILE_SUCCESS: {
      const newState: AuthState = {
        ...state,
        isLoggedIn: true,
        userData: action.payload as APIResponseProfile,
        loading: false,
        errors: undefined,
      };

      return newState;
    }

    case ACTION.CLEAR_ERRORS:
      return {
        ...state,
        errors: undefined,
      };
    case ACTION.LOADING:
      return {
        ...state,
        loading: true,
        errors: undefined,
      };
    case ACTION.FAILURE:
      return {
        ...state,
        loading: false,
        errors: (action.payload as ResponseError).errors,
      };

    case ACTION.LOG_OUT:
      // Clear basic profile info from cookies
      document.cookie = 'customer=;expires=Thu, 01 Jan 1970 00:00:01 GMT';

      return defaultAuthState;
    default:
      return defaultAuthState;
  }
}

export const AuthProvider: FunctionComponent = props => {
  const [state, dispatch] = useReducer(AuthReducer, defaultAuthState);

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={{
        state,
        dispatch: {
          updateProfile: (userData: APIResponseProfile) =>
            dispatch(setUpdateProfileAction(userData)),
        },
        methods: {
          initAuth: () => initAuth(dispatch),
          logIn: (email: string, password: string) => submitLogin(dispatch, email, password),
          logOut: () => submitLogout(dispatch),
          createProfile: userData => submitCreateProfile(dispatch, userData),
          updateProfile: userData => submitUpdateProfile(dispatch, userData),
          clearErrors: () => clearErrors(dispatch),
        },
      }}
    />
  );
};

export default AuthContext;
