import React, { useContext, useState } from 'react';
import { TranslateContext, LanguageContext } from './translator';

export default function Translate({ children: value }: { children: string | undefined }) {
  const language = useContext(LanguageContext);
  const handleTranslate = useContext(TranslateContext);
  const [translation, setTranslation] = useState(value);

  React.useEffect(() => {
    if (value) {
      handleTranslate(value, setTranslation);
    }
  }, [value, language, handleTranslate]);

  return <>{translation}</>;
}

export function InlineTranslation(value: any): string {
  const language = useContext(LanguageContext);
  const handleTranslate = useContext(TranslateContext);
  const [translation, setTranslation] = useState(value);
  React.useEffect(() => {
    if (handleTranslate && value && language) {
      handleTranslate(value, setTranslation);
    }
  }, [value, language, handleTranslate]);

  if (typeof value !== 'string') {
    return value;
  }
  return translation || value;
}

const websiteWithSpaceLink =
  '<a href="https://www.easysmartpay.net/" rel="noopener noreferrer" target="_blank"> www.easysmartpay.net</a>';
const websiteLink =
  '<a href="https://www.easysmartpay.net/" rel="noopener noreferrer" target="_blank">www.easysmartpay.net</a>';
const supportLink =
  '<a href="mailto:support@easysmartpay.net" rel="noopener noreferrer">support@easysmartpay.net</a>';

const tagsToRemoveAndReplace = [websiteWithSpaceLink, websiteLink, supportLink];

function removeNestedTags(input: string) {
  let text = input;

  tagsToRemoveAndReplace.forEach((nestedTag, index) => {
    text = text.replaceAll(nestedTag, `esp-nested-tag-${index}`);
  });

  return text;
}

function addEspLinks(input: string) {
  let text = input;

  tagsToRemoveAndReplace.forEach((nestedTag, index) => {
    text = text.replaceAll(`esp-nested-tag-${index}`, InlineTranslation(nestedTag));
  });

  return text;
}

function removeNonBreakingSpaces(input: string) {
  // FIXME: This is not an ideal solution, but required to resolve builder.io issues
  return input
    .replaceAll('.&nbsp;', '. ')
    .replaceAll('<h4>Cookies</h4>', '<h4 id="cookie-policy">Cookies</h4>')
    .replaceAll('<h3>CA Residents</h3>', '<h3 id="#california-privacy">CA Residents</h3>')
    .replaceAll('>&nbsp;', '><p>&nbsp;</p>')
    .replaceAll('<p> </p>', '<p>&nbsp;</p>')
    .replaceAll('<pstyle', '<p style')
    .replaceAll(
      '<p style="text-align: justify;> </p>',
      '<p style="text-align: justify;>&nbsp;</p>'
    );
}

// Translates all the text in tags
export function translateTextInTags(input: string): string {
  // Leave commented out for now, as it's not working as consistently as desired
  // const regex = /<[^>]*>([^<]*)<\/[^>]*>/gm;
  // const regex = /<[^>]*>([^<]*)<\/[^>]*>|<[^>]*\sstyle="[^"]*"[^>]*>([^<]*)<\/[^>]*>/gm;
  const regex = />([^<]+)</gm;
  const sanitizedText = removeNonBreakingSpaces(removeNestedTags(input));

  const translated = sanitizedText.replace(regex, (match, group) => {
    const translatedString = InlineTranslation(group.trim());

    return match.replace(group, translatedString);
  });

  return addEspLinks(translated);
}
