export const VALID_EMAIL = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: 'invalid email address',
  },
  required: 'email address is required',
};

export const VALID_PHONE = {
  pattern: {
    value: /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/,
    message: 'invalid phone number',
  },
};

export const DEFAULT_MESSAGES: { [key: string]: any } = {
  required: 'field is required',
};

export const VALID_PASSWORD = {
  required: 'password is required',
};
