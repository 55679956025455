import React from 'react';
import {
  createGenerateClassName,
  createTheme,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core/styles';
import BREAKPOINTS from '../constants/breakpoints';
import { FONT_SANS_SERIF, FONT_SERIF } from '../constants/styles';
import 'normalize.css';
import '../../index.css';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    zero: true;
    xxs: true;
    xs: true; // removes the `xs` breakpoint
    ssm: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

const AppWrapper = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const theme = createTheme({
    breakpoints: {
      values: {
        zero: BREAKPOINTS.ZERO,
        xxs: BREAKPOINTS.XXS,
        xs: BREAKPOINTS.XS,
        ssm: BREAKPOINTS.SSM,
        sm: BREAKPOINTS.SM,
        md: BREAKPOINTS.MD,
        lg: BREAKPOINTS.LG,
        xl: BREAKPOINTS.XL,
      },
    },
  });

  theme.typography = {
    ...theme.typography,
    h1: {
      fontFamily: FONT_SERIF,
      fontWeight: 'normal',
      fontSize: 42,
      lineHeight: '50px',
      letterSpacing: '0.9px',

      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        fontSize: 24,
        lineHeight: '36px',
        letterSpacing: '0.5px',
      },
    },
    h2: {
      fontFamily: FONT_SERIF,
      fontWeight: 'lighter',
      fontSize: 28,
      lineHeight: '36px',
      letterSpacing: '0.6px',

      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        fontSize: 20,
        lineHeight: '24px',
        letterSpacing: '0.4px',
      },
    },
    h3: {
      fontFamily: FONT_SERIF,
      fontWeight: 'normal',
      fontSize: 24,
      lineHeight: '36px',
      letterSpacing: '0.5px',

      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        fontSize: 20,
        lineHeight: '28px',
        letterSpacing: '0.4px',
      },
    },
    h4: {
      fontFamily: FONT_SANS_SERIF,
      fontWeight: 'normal',
      color: '#666666',
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: '0.5px',

      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.4px',
        fontWeight: 'bold',
      },
    },
    button: {
      background: 'none',
      padding: '12px 16px',
    },
  };

  const generateClassName = createGenerateClassName({
    seed: 'esp',
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StylesProvider>
  );
};

export default AppWrapper;
