import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const FailedIcon: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <path
      fill="#FF5252"
      d="M13.0543478,14.9673913 L13.423913,4 L10.076087,4 L10.4456522,14.9673913 L13.0543478,14.9673913 Z M11.75,20 C12.2789855,20 12.7047101,19.8442029 13.0271739,19.5326087 C13.3496377,19.2210145 13.5108696,18.826087 13.5108696,18.3478261 C13.5108696,17.8623188 13.3496377,17.4637681 13.0271739,17.1521739 C12.7047101,16.8405797 12.2789855,16.6847826 11.75,16.6847826 C11.2282609,16.6847826 10.8061594,16.8423913 10.4836957,17.1576087 C10.1612319,17.4728261 10,17.8695652 10,18.3478261 C10,18.826087 10.1612319,19.2210145 10.4836957,19.5326087 C10.8061594,19.8442029 11.2282609,20 11.75,20 Z"
    />
  </SvgIcon>
);

export default FailedIcon;
