import React, { FunctionComponent, ReactNode, useEffect } from 'react';

interface RerouteProps {
  route: string;
  children?: ReactNode;
}

const Reroute: FunctionComponent<RerouteProps> = ({ route, children }: RerouteProps) => {
  useEffect(() => {
    window.location.replace(route);
  }, [route]);

  return <>{children}</>;
};

export default Reroute;
