import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const InstallmentScheduledIcon: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <g fill="none" transform="translate(2 1)">
      <polygon fill="#FFF" points="2.667 8.592 17.333 8.592 17.333 19.593 2.667 19.593" />
      <polygon fill="#B2B2B2" points="2.667 4.593 17.333 4.593 17.333 7.333 2.667 7.333" />
      <path
        fill="#000"
        d="M17.3333333,2.75 L16.4166667,2.75 L16.4166667,0.916666667 L14.5833333,0.916666667 L14.5833333,2.75 L5.41666667,2.75 L5.41666667,0.916666667 L3.58333333,0.916666667 L3.58333333,2.75 L2.66666667,2.75 C1.65833333,2.75 0.833333333,3.575 0.833333333,4.58333333 L0.833333333,19.25 C0.833333333,20.2583333 1.65833333,21.0833333 2.66666667,21.0833333 L17.3333333,21.0833333 C18.3416667,21.0833333 19.1666667,20.2583333 19.1666667,19.25 L19.1666667,4.58333333 C19.1666667,3.575 18.3416667,2.75 17.3333333,2.75 Z M17.3333333,4.58333333 L17.3333333,7.33333333 L2.66666667,7.33333333 L2.66666667,4.58333333 L17.3333333,4.58333333 Z M2.66666667,19.25 L2.66666667,9.16666667 L17.3333333,9.16666667 L17.3333333,19.25 L2.66666667,19.25 Z"
      />
    </g>
    <g fill="none" transform="translate(2 1)">
      <polygon fill="#FFF" points="2.667 8.592 17.333 8.592 17.333 19.593 2.667 19.593" />
      <polygon fill="#B2B2B2" points="2.667 4.593 17.333 4.593 17.333 7.333 2.667 7.333" />
      <path
        fill="#000"
        d="M17.3333333,2.75 L16.4166667,2.75 L16.4166667,0.916666667 L14.5833333,0.916666667 L14.5833333,2.75 L5.41666667,2.75 L5.41666667,0.916666667 L3.58333333,0.916666667 L3.58333333,2.75 L2.66666667,2.75 C1.65833333,2.75 0.833333333,3.575 0.833333333,4.58333333 L0.833333333,19.25 C0.833333333,20.2583333 1.65833333,21.0833333 2.66666667,21.0833333 L17.3333333,21.0833333 C18.3416667,21.0833333 19.1666667,20.2583333 19.1666667,19.25 L19.1666667,4.58333333 C19.1666667,3.575 18.3416667,2.75 17.3333333,2.75 Z M17.3333333,4.58333333 L17.3333333,7.33333333 L2.66666667,7.33333333 L2.66666667,4.58333333 L17.3333333,4.58333333 Z M2.66666667,19.25 L2.66666667,9.16666667 L17.3333333,9.16666667 L17.3333333,19.25 L2.66666667,19.25 Z"
      />
    </g>
  </SvgIcon>
);

export default InstallmentScheduledIcon;
