const {
  REACT_APP_WEB_URL,
  REACT_APP_GOOGLE_STREET_VIEW_API,
  REACT_APP_API_URL,
  REACT_APP_SERVER_ENVIRONMENT,
  REACT_APP_GTM_ID,
  REACT_APP_GTM_ENV,
  REACT_APP_GTM_AUTH,
} = process.env;
export {
  REACT_APP_WEB_URL,
  REACT_APP_API_URL,
  REACT_APP_GOOGLE_STREET_VIEW_API,
  REACT_APP_SERVER_ENVIRONMENT,
  REACT_APP_GTM_ID,
  REACT_APP_GTM_ENV,
  REACT_APP_GTM_AUTH,
};
