import { useEffect } from 'react';

const useStickyListener = (container: string, target: string) => {
  useEffect(() => {
    const scrollContainer = document.getElementById(container)!;
    const summary = document.getElementById(target)!;

    const sticky = summary?.offsetTop;
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > sticky + 50) {
        scrollContainer.classList.add('sticky');
      } else {
        scrollContainer.classList.remove('sticky');
      }
    });
    // return () => {
    //   window.removeEventListener('scroll', scrollCallBack);
    // };
  }, []);
};

export default useStickyListener;
