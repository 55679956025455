/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Theme } from '@material-ui/core';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import CardContainer from '../../../../../../global/components/Layout/CardContainer/CardContainer';
import TitleColumn from '../../../../../../global/components/Layout/TitleColumn/TitleColumn';
import ContentColumn from '../../../../../../global/components/Layout/ContentColumn/ContentColumn';
import BREAKPOINTS from '../../../../../../global/constants/breakpoints';
import { BORDER_STYLE, COLORS, FONT_SERIF } from '../../../../../../global/constants/styles';
import { FastFactHeader } from './models/FastFact';
import NacoMemberIcon from '../../../../../../global/components/icons/NacoMemberIcon';
import { DemographicProps } from './models/Demographic';
import InfoBox from '../../../../../../global/components/InfoBox/InfoBox';
import { Translate } from '../../../../../../libraries/translate';

function getHeaderData(state: string, countyName: string): FastFactHeader | undefined {
  switch (state) {
    // add new states under new cases
    case 'CA':
      return {
        header: `${countyName}, ${state}`,
        subHeader: '2022 Census Data',
        source: {
          link: 'https://explorer.naco.org/',
          title: 'NACo County Explorer',
        },
        disclaimers: [],
      };
    default:
      return undefined;
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    display: 'block !important',
    bottom: '24px',
    maxWidth: 175,

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      position: 'initial',
      marginBottom: 0,
      paddingBottom: 16,
      marginTop: '0 !important',
    },

    '& p': {
      margin: 0,
      fontFamily: 'Lato',
      fontSize: '12px !important',
      letterSpacing: '0.26px',
      lineHeight: '16px',
      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        marginRight: 4,
      },
      '&:last-child': {
        color: '#757575',
        fontStyle: 'italic',
        marginTop: 8,
        [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
          paddingBottom: 16,
        },
      },
    },
    '& a': {
      margin: 0,
      color: '#34ACE0',
      fontFamily: 'Lato',
      fontSize: '12px',
      fontStyle: 'italic',
      letterSpacing: '0.26px',
      lineHeight: '16px',
      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        lineHeight: '20px',
      },
    },
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    alignItems: 'center',
    padding: '16px 0',

    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'block !important',
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'block',
      padding: 0,
      marginBottom: 16,
      '&:last-child': {
        marginBottom: 0,
      },
      '&:first-child': {
        marginTop: 24,
      },
    },
  },
  gridWrapper: {
    padding: 0,
    '& > div': {
      paddingLeft: 0,
    },
  },
  grid: {
    display: 'grid',
    // display: 'block',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridGap: '1px',
    backgroundColor: '#ddd',

    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'block',
    },

    '& div': {
      backgroundColor: 'white',
      padding: '1rem 2rem 1rem 1.75rem',
      textAlign: 'center',

      [theme.breakpoints.down(BREAKPOINTS.MD)]: {
        borderBottom: '1px solid #ddd',
      },

      '& h4': {
        color: '#222',
        fontFamily: 'Lato',
        textTransform: 'uppercase',
        fontSize: '16px',
        textAlign: 'left',
        margin: 0,
      },

      '& dl': {
        display: 'block',
        width: '100%',
        textAlign: 'left',
        marginBlockStart: '4px',
        marginBlockEnd: '4px',

        '& dt': {
          marginLeft: '0',
          display: 'inline-block',
        },

        '& dd': {
          marginLeft: '5px',
          display: 'inline-block',
          color: '#9E9E9E',
        },
      },
    },
  },
  netTaxWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    alignItems: 'center',
    color: COLORS.GREY_02,
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.34px',
    lineHeight: '24px',

    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'block',
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      padding: 0,
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 325,
  },
  container: {
    marginBottom: '50px',
    display: 'inherit',
    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'block !important',
      marginBottom: 0,
    },
  },
  flex: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  smallTitle: {
    lineHeight: '10px !important',
    fontWeight: 'bold !important',
    fontStyle: 'italic',
  },
  source: {
    display: 'block !important',
    position: 'absolute',
    bottom: '24px',
    maxWidth: 175,

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      position: 'initial',
      marginBottom: 0,
      paddingBottom: 16,
      marginTop: '0 !important',
    },

    '& p': {
      margin: 0,
      fontFamily: 'Lato',
      fontSize: '12px !important',
      letterSpacing: '0.26px',
      lineHeight: '16px',
      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        marginRight: 4,
      },
      '&:first-child': {
        marginBottom: 0,
        lineHeight: '10px',
      },
      '&:last-child': {
        color: '#666666',
        fontStyle: 'italic',
        marginTop: 8,
        [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
          paddingBottom: 16,
        },
      },
    },
    '& a': {
      margin: 0,
      color: '#34ACE0',
      fontFamily: 'Lato',
      fontSize: '12px',
      fontStyle: 'italic',
      letterSpacing: '0.26px',
      lineHeight: '16px',
      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        lineHeight: '20px',
      },
    },
  },
  steps: {
    paddingTop: '65px !important',
  },
  titleColumn: (inputs: { minWidth: number }) => ({
    padding: '24px',
    margin: 0,
    borderRight: BORDER_STYLE,
    minWidth: 190,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    minHeight: 400,
    // justifyContent: 'space-between',

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      display: 'block',
      margin: 0,
      padding: 0,
      borderBottom: BORDER_STYLE,
      borderRight: 'none',
    },
    '& > *': {
      '&:last-child': {
        marginRight: 0,
      },
    },

    '& > * > div, & > * > p': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 8px 0',
      margin: 0,

      [theme.breakpoints.down(inputs.minWidth)]: {
        flexDirection: 'row',
        borderRight: 'none',
      },
    },

    '& hr': {
      width: '40px',
      height: '1px',
      backgroundColor: COLORS.BORDER,
      margin: '16px 0',
      borderWidth: 0,
    },

    '& h3': {
      color: COLORS.GREY_02,
      fontWeight: 'bold',
      fontFamily: FONT_SERIF,
      fontSize: '20px',
      lineHeight: '25px',
      marginBottom: '8px',
    },

    '& p': {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.3px',
      lineHeight: '20px',
    },
  }),
  icon: {
    width: 100,
    height: 55,
  },
}));

function FastFactsDemographics({ data, steps, error, countyName }: DemographicProps) {
  const county = data?.county;
  const styles = useStyles({ minWidth: 768 });
  const headerData = getHeaderData('CA', countyName || county?.countySeat || '');
  const [address, setAddress] = useState<any>('-');

  useEffect(() => {
    setAddress(county?.fullAddress || '-');
  }, [county]);

  if (!county || !data) {
    return (
      <CardContainer className={styles.container}>
        <ContentColumn>
          <div className={styles.loading}>
            {error && (
              <InfoBox type="error" header="Oops, there was a problem">
                <Translate>
                  Sorry, we are unable to load county fast fact information at this time
                </Translate>
              </InfoBox>
            )}
            {!error && <CircularProgress />}
          </div>
        </ContentColumn>
      </CardContainer>
    );
  }

  return (
    <CardContainer className={styles.container}>
      <TitleColumn
        title={headerData?.header}
        secondaryTitle={headerData?.subHeader}
        className={classNames([styles.titleColumn, steps ? styles.steps : null])}
        miniDivider={false}
      >
        <hr />
        <div className={styles.info}>
          <p className={styles.smallTitle}>County Contact:</p>
          {
            // eslint-disable-next-line
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(address) }} />
          }
        </div>

        <div className={styles.source}>
          <hr />
          <p className={styles.smallTitle}>Source:</p>
          <a
            href={headerData?.source.link || 'https://www.counties.org/post/datapile'}
            target="_blank"
            rel="noreferrer"
          >
            {headerData?.source.title || 'California County Datapile'}
          </a>
          <NacoMemberIcon className={styles.icon} />
          {headerData?.disclaimers?.map((disclaimer: string, index: number) => (
            <p key={disclaimer}>
              {'*'.repeat(index + 1)}
              {disclaimer}
            </p>
          ))}
        </div>
      </TitleColumn>
      <ContentColumn minWidth={635} className={styles.gridWrapper}>
        <div className={styles.grid}>
          <div>
            <h4>County Details</h4>
            <dl>
              <dt>County Seat:</dt>
              <dd>{county.countySeat || '-'}</dd>
            </dl>
            <dl>
              <dt>Year Organized:</dt>
              <dd>{county.yearFounded || '-'}</dd>
            </dl>
            <dl>
              <dt>Total Square Miles:</dt>
              <dd>{county.landArea || '-'}</dd>
            </dl>
            <dl>
              <dt>2020 Population:</dt>
              <dd>{county.populationLevel || '-'}</dd>
            </dl>
            <dl>
              <dt>Persons/Square Mile:</dt>
              <dd>{county.populationDensity || '-'}</dd>
            </dl>
          </div>
          <div>
            <h4>County Structure</h4>
            <dl>
              <dt>Government Type:</dt>
              <dd>{county.countyGovernmentType || '-'}</dd>
            </dl>
            <dl>
              <dt>Authority:</dt>
              <dd>{county.governingAuthority || '-'}</dd>
            </dl>
            <dl>
              <dt>Legislative Body:</dt>
              <dd>{county.governingBody || '-'}</dd>
            </dl>
            <dl>
              <dt>Size of Legislative Body:</dt>
              <dd>{county.boardSize || '-'}</dd>
            </dl>
          </div>
          <div>
            <h4>Economy</h4>
            <dl>
              <dt>2019 Gross Domestic Product (GDP):</dt>
              <dd>{county.countyGDP || '-'}</dd>
            </dl>
            <dl>
              <dt>2020 Labor Force:</dt>
              <dd>{county.countyLaborForce || '-'}</dd>
            </dl>
            <dl>
              <dt>2020 Unemployment Rate:</dt>
              <dd>{county.countyUnemploymentRate || '-'}</dd>
            </dl>
          </div>
          <div>
            <h4>2020 Census</h4>
            <dl>
              <dt>Population:</dt>
              <dd>{county.populationLevel || '-'}</dd>
            </dl>
            <dl>
              <dt>2010 to 2020 Population Change:</dt>
              <dd>{county.populationChange || '-'}</dd>
            </dl>
            <dl>
              <dt>Total Housing Units:</dt>
              <dd>{county.housingUnits || '-'}</dd>
            </dl>
            <dl>
              <dt>Share Occupied Housing Units:</dt>
              <dd>{county.pctHousingUnits || '-'}</dd>
            </dl>
          </div>
        </div>
      </ContentColumn>
    </CardContainer>
  );
}

export default FastFactsDemographics;
