import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Theme } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { Installment } from '../../models/Property';
import { BORDER_STYLE, COLORS, FONT_SANS_SERIF, FONT_SERIF } from '../../constants/styles';
import useButtonStyles from '../../hooks/useButtonStyles';
import toPrettyDate from '../../helpers/toPrettyDate';
import ReconciledIcon from '../icons/ReconciledIcon';
import PaymentScheduledIcon from '../icons/PaymentScheduledIcon';
import CardContainer from '../Layout/CardContainer/CardContainer';
import TitleColumn from '../Layout/TitleColumn/TitleColumn';
import ContentColumn from '../Layout/ContentColumn/ContentColumn';
import InstallmentScheduledIcon from '../icons/InstallmentScheduledIcon';
import DisclaimerTag from '../DisclaimerTag';
import AmountDueIcon from '../icons/AmountDueIcon';
import toPrettyUSD from '../../helpers/toPrettyUSD';
import formatTaxYear from '../../helpers/formatTaxYear';
import BREAKPOINTS from '../../constants/breakpoints';
import OrderStatus from '../OrderStatus';
import usePaymentRowStyles from '../../hooks/usePaymentRowStyles';
import { Translate } from '../../../libraries/translate';

export interface TaxBillInstallmentProps {
  installment: Installment;
  taxRollId: string;
  showPayNow: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  payStatus: {
    flexBasis: 320,
  },

  payNow: {
    padding: '15px 12px',
    borderRadius: '3px',
    backgroundColor: '#F3FBFF',
    color: '#227093',
    fontFamily: FONT_SANS_SERIF,
    fontWeight: 'bold',
    lineHeight: '24px',
    letterSpacing: 0.34,
    border: 'none',
    cursor: 'pointer',

    '&:hover, &:active, &:focus': {
      backgroundColor: 'rgba(52,172,224,1)',
      color: COLORS.WHITE,
    },
  },

  installmentDetails: {
    width: '100%',
  },

  titleColumn: (inputs: { minWidth: number }) => ({
    padding: '24px !important',
    margin: '0',
    borderRight: BORDER_STYLE,
    minWidth: 190,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    [theme.breakpoints.down(inputs.minWidth)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '0',
      padding: '24px 16px 16px 16px',
      borderBottom: BORDER_STYLE,
      borderRight: 'none',
    },

    '& > *': {
      '&:last-child': {
        marginRight: 0,
      },
    },
    '& > * > div, & > * > p': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 8px 0',
      margin: 0,

      [theme.breakpoints.down(inputs.minWidth)]: {
        flexDirection: 'row',
        borderRight: 'none',
      },
    },

    '& h3': {
      color: COLORS.GREY_02,
      fontWeight: 'bold',
      fontFamily: FONT_SERIF,
      fontSize: '20px',
      lineHeight: '25px',
      marginBottom: '8px',
    },

    '& p': {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.3px',
      lineHeight: '20px',
    },
  }),

  cardContainerOverride: {
    marginBottom: 0,
    display: 'inherit',
    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'block !important',
    },
    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      '& > *:first-child': {
        marginBottom: '0 !important',
      },
    },
  },
}));

const TaxBillInstallment: FunctionComponent<TaxBillInstallmentProps> = ({
  installment,
  taxRollId,
  showPayNow,
}: TaxBillInstallmentProps) => {
  const paymentStyles = usePaymentRowStyles({ minWidth: 768 });
  const styles = useStyles({ minWidth: 768 });
  const buttonStyles = useButtonStyles();
  const dueDate = toPrettyDate(installment.dueDate);
  const billedAmount = toPrettyUSD(installment.amount);
  const lastPayDate = toPrettyDate(installment.lastPayDate);

  function renderPaidSection() {
    let icon;
    let payCopy;

    if (installment.isUnderContract) {
      if (installment.isPaid) {
        icon = <ReconciledIcon className="reconciled-icon" />;
        payCopy = 'Paid to your county by ESP on';
      } else {
        icon = <PaymentScheduledIcon className="payment-scheduled-icon" />;
        payCopy = 'Will be paid to your county by ESP on';
      }
    } else if (installment.isPaid) {
      icon = <ReconciledIcon className="reconciled-icon" />;
      payCopy = 'Paid to your county';
    } else {
      icon = <AmountDueIcon className="amount-due-icon" />;
      payCopy = 'No payment issued to ESP or your county';
    }

    return (
      <>
        <p>{icon}</p>
        <div>
          <span className="label">
            <Translate>{payCopy}</Translate>
          </span>
          <p>{dueDate}</p>
        </div>
      </>
    );
  }

  function getGracePeriod(): boolean {
    const today = DateTime.now();
    const formattedDueDate = DateTime.fromISO(
      new Date(
        `${installment.dueDate.month}/${installment.dueDate.day + 10}/${installment.dueDate.year}`
      ).toISOString()
    );

    const formattedLastPayDate = DateTime.fromISO(
      new Date(
        `${installment.lastPayDate.month}/${installment.lastPayDate.day}/${installment.lastPayDate.year}`
      ).toISOString()
    );
    return today >= formattedLastPayDate && today <= formattedDueDate;
  }

  const isWithinGracePeriod = getGracePeriod();
  const { isUnderContract, isPaid } = installment;
  const showBannerGracePeriodBanner = !isPaid && isUnderContract && isWithinGracePeriod;

  return (
    <CardContainer
      minWidth={768}
      className={showBannerGracePeriodBanner ? styles.cardContainerOverride : ''}
    >
      <TitleColumn
        title={<Translate>{`Installment ${installment.installmentNumber}`}</Translate>}
        secondaryTitle={formatTaxYear(installment.taxYear)}
        className={styles.titleColumn}
      >
        {showPayNow && !installment.isPaid && !installment.isUnderContract && (
          <NavLink to={`/property/${taxRollId}/${installment.apn}`}>
            <Button className={buttonStyles.lightBlue}>
              <Translate>Pay Now</Translate>
            </Button>
          </NavLink>
        )}
      </TitleColumn>
      <ContentColumn
        className={styles.installmentDetails}
        header={
          showBannerGracePeriodBanner ? (
            <OrderStatus type="GRACE_PERIOD" status="GRACE_PERIOD" description={dueDate} />
          ) : null
        }
      >
        <div className={classNames([paymentStyles.row])}>
          <div>
            <p>
              <AttachMoneyIcon />
            </p>
            <div>
              <span>
                <Translate>Billed Amount</Translate>
              </span>
              <p>{billedAmount}</p>
            </div>
          </div>
          <div>
            <p>
              <InstallmentScheduledIcon />
            </p>
            <div>
              <span>
                <Translate>Last Day to Pay ESP</Translate>
              </span>
              <p>
                {lastPayDate}
                <DisclaimerTag tag="1" />
              </p>
            </div>
          </div>
          <div className={styles.payStatus}>{renderPaidSection()}</div>
        </div>
      </ContentColumn>
    </CardContainer>
  );
};

export default TaxBillInstallment;
