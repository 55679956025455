import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';
import classnames from 'classnames';
import BREAKPOINTS from '../../../constants/breakpoints';
import { COLORS, BORDER_STYLE } from '../../../constants/styles';

export interface TitleColumnProps {
  children?: React.ReactNode;
  minWidth?: number;
  className?: string;
  title: string | React.ReactNode;
  secondaryTitle?: string | React.ReactNode | null;
  tertiaryTitle?: string | React.ReactNode | null;
  miniDivider?: boolean;
}

const TitleColumn: FunctionComponent<TitleColumnProps> = (props: TitleColumnProps) => {
  const {
    children,
    minWidth = 200,
    className,
    title,
    secondaryTitle,
    tertiaryTitle,
    miniDivider = true,
  } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    leftCol: {
      minWidth,
      borderRight: BORDER_STYLE,
      padding: '24px 24px 0px 24px',

      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        minWidth: '100%',
        borderRight: 'none',
        paddingTop: '1px',
        paddingRight: '0px',
        paddingLeft: '0px',
        paddingBottom: '8px',
        borderBottom: BORDER_STYLE,
      },
    },
    children: {
      '&:last-child': {
        paddingTop: 0,
      },

      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        padding: '16px 16px 0 16px',
      },
    },
    title: {
      color: COLORS.DEFAULT,
      fontFamily: 'Merriweather',
      fontSize: '20px',
      fontWeight: 'bold',
      letterSpacing: '0.43px',
      lineHeight: '28px',
      margin: 0,
      textTransform: 'capitalize',
    },
    secondaryTitle: {
      color: COLORS.GREY,
      fontFamily: 'Lato',
      fontSize: '14px',
      letterSpacing: '0.3px',
      lineHeight: '20px',
      margin: 0,
    },
    tertiaryTitle: {
      color: '#757575',
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.3px',
      lineHeight: '20px',
      margin: 0,
    },
    miniDivider: {
      width: '40px',
      height: '1px',
      backgroundColor: COLORS.BORDER,
      margin: '16px 0',

      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        margin: '8px 16px',
      },
    },
    fullDivider: {
      width: '100%',
      height: '1px',
      backgroundColor: COLORS.BORDER,
      display: 'none',
      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        display: 'block',
      },
    },
  }));

  const styles = useStyles();
  const columnClassname = className || styles.leftCol;
  return (
    <div className={classnames([columnClassname])}>
      <div className={styles.children}>
        <Typography variant="h3" className={styles.title}>
          {title}
        </Typography>
        {!!secondaryTitle && <p className={styles.secondaryTitle}>{secondaryTitle}</p>}
        {!!tertiaryTitle && <p className={styles.tertiaryTitle}>{tertiaryTitle}</p>}
      </div>
      {!!children && (
        <>
          {miniDivider && <div className={styles.miniDivider} />}
          <div className={styles.children}>{children}</div>
        </>
      )}
    </div>
  );
};

export default TitleColumn;
