import React, { FunctionComponent } from 'react';
import { FieldError } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import useFormStyles from '../hooks/useFormStyles';
import { DEFAULT_MESSAGES } from '../constants/formValidation';
import { Translate } from '../../libraries/translate';

interface Props {
  children: React.ReactNode;
  inputName: string;
  hideError?: boolean;
  label?: string;
  error?: FieldError | string | undefined;
  layout?: 'inline';
}

const useStyles = makeStyles({
  test: {},
  inputContainer: ({ layout }: { layout?: string }) => {
    let styles = {};

    if (layout === 'inline') {
      styles = {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',

        '& > div': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginRight: 0,
          '& > *': {
            width: 'auto',
          },
        },

        '& > label': {
          marginBottom: 0,
          lineHeight: 'initial',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        },
      };
    }
    return styles;
  },
});

const FormGroup: FunctionComponent<Props> = ({
  children,
  inputName,
  label,
  hideError,
  error,
  layout,
}: Props) => {
  const formStyles = useFormStyles({});
  const styles = useStyles({ layout });
  let message;

  if ((error as FieldError)?.message !== undefined) {
    const fieldError = error as FieldError;
    message = fieldError.message;

    if (!message) {
      message = DEFAULT_MESSAGES[fieldError.type];
    }
  } else {
    message = error;
  }

  return (
    <div className={error && formStyles['.formGroup.error']}>
      <div className={styles.inputContainer}>
        {label && (
          <label htmlFor={inputName}>
            <Translate>{label}</Translate>
          </label>
        )}
        <div>{children}</div>
      </div>
      {!hideError && <span className={formStyles.errorMessage}>{message || '\u00a0'}</span>}
    </div>
  );
};

export default FormGroup;
