import React, { useReducer, FunctionComponent } from 'react';

import { BaseState } from '../models/BaseState';
import { Product } from '../models/Product';
import { ResponseError } from '../models/ResponseError';
import { getProducts } from '../services/products';
import formatProduct from './helpers/formatProducts';

// State
interface ProductsState extends BaseState {
  products?: Product[];
}

const defaultState: ProductsState = { loading: false };

// Context
type ContextProps = {
  state: ProductsState;
  dispatch?: {};
  selectors?: {
    selectProductByAddress: (zipCode: string) => Product | undefined;
  };
  methods?: {
    getProducts: () => Promise<void>;
  };
};

const ProductsContext = React.createContext<ContextProps>({
  state: defaultState,
});

// Reducer & Actions
type Action = {
  type: ACTION;
  payload?: Product[] | ResponseError;
};

enum ACTION {
  LOAD_PRODUCTS = 'actionLoadProducts',
  LOAD_PRODUCTS_SUCCESS = 'actionLoadProductsSuccess',
  LOAD_PRODUCTS_FAILURE = 'actionLoadProductsFailure',
}

function ProductsReducer(state: ProductsState, action: Action): ProductsState {
  switch (action.type) {
    case ACTION.LOAD_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case ACTION.LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload as Product[],
        loading: false,
        errors: undefined,
      };
    case ACTION.LOAD_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: (action.payload as ResponseError).errors || {
          message: 'Products are unavailable at this time.',
        },
      };
    default:
      return defaultState;
  }
}
// Business Methods

function selectProductByAddress(state: ProductsState, address: string) {
  if (state.products) {
    return state.products.find(
      (product: Product) =>
        !!product.zipCodes.find((zipCode: string) => address.indexOf(zipCode) > -1)
    );
  }

  return undefined;
}

async function submitGetProducts(dispatch: React.Dispatch<Action>) {
  dispatch({ type: ACTION.LOAD_PRODUCTS });

  getProducts()
    .then((response: Product[]) => {
      dispatch({
        type: ACTION.LOAD_PRODUCTS_SUCCESS,
        payload: response.map(product => formatProduct(product)),
      });
    })
    .catch(() => {
      // TODO: Get error message from response
      dispatch({
        type: ACTION.LOAD_PRODUCTS_FAILURE,
        payload: {
          errors: {
            message: 'Unable to load available Easy Smart pay counties.',
          },
        },
      });
    });
}

// Provider
export const ProductsProvider: FunctionComponent = props => {
  const [state, dispatch] = useReducer(ProductsReducer, defaultState);

  return (
    <ProductsContext.Provider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={{
        state,
        dispatch: {},
        selectors: {
          selectProductByAddress: address => selectProductByAddress(state, address),
        },
        methods: {
          getProducts: () => submitGetProducts(dispatch),
        },
      }}
    />
  );
};

export default ProductsContext;
