import React, { createContext, FunctionComponent, useReducer } from 'react';
import { BaseState } from '../models/BaseState';
import { ResponseError } from '../models/ResponseError';
import * as orderService from '../services/order';
import { AccountHistory, AccountHistoryByYear, PropertyHistory } from '../models/AccountHistory';
import { Order } from '../models/Order';

// State
interface OrdersState extends BaseState {
  accountHistoryByAPN: AccountHistory;
  accountHistoryByYear: AccountHistoryByYear;
  loaded: boolean;
}

const defaultState: OrdersState = {
  loading: false,
  accountHistoryByAPN: {},
  accountHistoryByYear: {},
  loaded: false,
};

type ContextProps = {
  state: OrdersState;
  selectors: {
    selectAvailableYears: () => number[];
    selectLatestYear: () => number | undefined;
  };
  methods: {
    loadOrders: () => Promise<void>;
  };
};

const AccountHistoryContext = createContext<ContextProps>({
  state: defaultState,
  selectors: {
    selectAvailableYears: () => [],
    selectLatestYear: () => undefined,
  },
  methods: {
    loadOrders: () => Promise.resolve(),
  },
});

// Reducer & Actions
type Action = {
  type: ACTION;
  payload?: Order | AccountHistory | ResponseError;
};

enum ACTION {
  LOAD_ORDERS = 'actionLoadOrder',
  LOAD_ORDERS_SUCCESS = 'actionLoadOrdersSuccess',
  LOAD_ORDERS_FAILURE = 'actionLoadOrdersFailure',
}

function formatByYear(orders: AccountHistory) {
  const years: AccountHistoryByYear = {};

  Object.keys(orders).forEach((apn: string) => {
    const property: PropertyHistory = orders[apn];
    Object.keys(property.taxYears).forEach((year: string) => {
      years[year] = years[year] || {};
      years[year][apn] = property;
    });
  });

  return years;
}

function OrdersReducer(state: OrdersState, action: Action): OrdersState {
  switch (action.type) {
    case ACTION.LOAD_ORDERS:
      return {
        ...state,
        accountHistoryByAPN: {},
        accountHistoryByYear: {},
        loading: true,
        loaded: false,
        errors: undefined,
      };

    case ACTION.LOAD_ORDERS_SUCCESS: {
      const accountHistoryByAPN = action.payload as AccountHistory;
      const accountHistoryByYear = formatByYear(accountHistoryByAPN);

      return {
        ...state,
        accountHistoryByAPN,
        accountHistoryByYear,
        errors: undefined,
        loading: false,
        loaded: true,
      };
    }
    case ACTION.LOAD_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: (action.payload as ResponseError).errors,
      };
    default:
      return state;
  }
}

// Business Methods

async function loadOrders(dispatch: React.Dispatch<Action>) {
  dispatch({ type: ACTION.LOAD_ORDERS });

  orderService
    .loadAccountHistory()
    .then((response: AccountHistory) => {
      dispatch({
        type: ACTION.LOAD_ORDERS_SUCCESS,
        payload: response,
      });
    })
    .catch((err: any) => {
      // TODO: Get error message from response
      dispatch({
        type: ACTION.LOAD_ORDERS_FAILURE,
        payload: {
          errors: {
            message:
              err.response?.data?.message ||
              'Unable to retrieve account details at this time. Please try again later.',
          },
        },
      });
    });
}

// Provider
export const AccountHistoryProvider: FunctionComponent = props => {
  const [state, dispatch] = useReducer(OrdersReducer, defaultState);
  return (
    <AccountHistoryContext.Provider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={{
        state,
        selectors: {
          selectAvailableYears: () =>
            Object.keys(state.accountHistoryByYear)
              .map(year => parseInt(`${year}`))
              .sort(),
          selectLatestYear: () => {
            const years = Object.keys(state.accountHistoryByYear).sort();
            return parseInt(years[years.length - 1]);
          },
        },
        methods: {
          loadOrders: () => loadOrders(dispatch),
        },
      }}
    />
  );
};

export default AccountHistoryContext;
