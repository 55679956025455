import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTE } from '../../constants';
import { REACT_APP_WEB_URL } from '../../helpers/constants';
import { Translate } from '../../../libraries/translate';

const Footer = () => (
  <footer className="footer">
    <nav>
      <div>
        <ul className="links">
          <li>
            <Link to={ROUTE.HOME}>
              <Translate>Home</Translate>
            </Link>
          </li>
          <li>
            <a href={`${REACT_APP_WEB_URL}/how-it-works`}>
              <Translate>How it works</Translate>
            </a>
          </li>
          <li>
            <a href={`${REACT_APP_WEB_URL}/`}>
              <Translate>Find my bill</Translate>
            </a>
          </li>
          <li>
            <a href={`${REACT_APP_WEB_URL}/faq`}>
              <Translate>FAQ</Translate>
            </a>
          </li>
          <li>
            <a href={`${REACT_APP_WEB_URL}/about-us`}>
              <Translate>About us</Translate>
            </a>
          </li>
        </ul>
        <ul className="links">
          <li>
            <a href={`${REACT_APP_WEB_URL}/legal/privacy`}>
              <Translate>Privacy policy</Translate>
            </a>
          </li>
          <li>
            <a href={`${REACT_APP_WEB_URL}/legal/privacy#california-privacy`}>
              <Translate>CA Residents</Translate>
            </a>
          </li>
          <li>
            <a href={`${REACT_APP_WEB_URL}/legal/privacy#cookie-policy`}>
              <Translate>Cookie Policy</Translate>
            </a>
          </li>
          <li>
            <a href={`${REACT_APP_WEB_URL}/legal/user-agreement`}>
              <Translate>User Agreement</Translate>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <div>
      <div className="bottom-divider">&nbsp;</div>
      <p className="disclaimer">
        <strong>
          <Translate>Easy Smart Pay is a private company.</Translate>
        </strong>{' '}
        <Translate>
          Although we work closely with many government agencies, and may be endorsed by some to
          perform certain services, we are not a government agency.
        </Translate>
      </p>
      <p className="address">1017 L Street #595, Sacramento, CA 95814</p>
      <p className="copyright">
        <Translate>Copyright &copy; 2018-2023 Smart Easy Pay Inc. All rights reserved</Translate>
      </p>
    </div>
  </footer>
);

export default Footer;
