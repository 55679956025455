import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const YogaIcon: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <rect width="24" height="24" />
      <g fill="#000" fillRule="nonzero" transform="translate(3 4)">
        <circle cx="9" cy="2" r="2" />
        <path d="M18,12 L18,10 C15.76,10 13.84,9.04 12.4,7.32 L11.06,5.72 C10.68,5.26 10.12,5 9.53,5 L8.48,5 C7.89,5 7.33,5.26 6.95,5.72 L5.61,7.32 C4.16,9.04 2.24,10 0,10 L0,12 C2.77,12 5.19,10.83 7,8.75 L7,11 L3.12,12.55 C2.45,12.82 2,13.48 2,14.21 C2,15.2 2.8,16 3.79,16 L6,16 L6,15.5 C6,14.12 7.12,13 8.5,13 L11.5,13 C11.78,13 12,13.22 12,13.5 C12,13.78 11.78,14 11.5,14 L8.5,14 C7.67,14 7,14.67 7,15.5 L7,16 L14.21,16 C15.2,16 16,15.2 16,14.21 C16,13.48 15.55,12.82 14.88,12.55 L11,11 L11,8.75 C12.81,10.83 15.23,12 18,12 Z" />
      </g>
    </g>
  </SvgIcon>
);

export default YogaIcon;
