import { Payment } from '../../../global/models/PaymentContract';
import { BillDate } from '../../../global/models/Property';

export default function getBillDate(payment: Payment): BillDate {
  const datePieces = payment.dateKey.split('/');
  return {
    month: parseInt(datePieces[0]),
    day: parseInt(datePieces[1]),
    year: parseInt(datePieces[2]),
  };
}
