import { PropertyCardData } from '../../../global/components/PropertyCard/PropertyCard';
import formatTaxYear from '../../../global/helpers/formatTaxYear';
import { PropertyYearHistory } from '../../../global/models/AccountHistory';
// import { Payment } from '../../../global/models/PaymentContract';
import { CurrentYearProperty } from '../models/currentYearProperty';
import parseAddressForCard from './parseAddressForCard';

// function hasScheduledPayment(schedule: Payment[]) {
//   const hasScheduled = schedule.some(sch => !sch.orderId);

//   return hasScheduled;
// }

// function getScheduledOrders(propertyYearHistory: PropertyYearHistory) {
//   return propertyYearHistory.paymentContracts.filter(paymentContract =>
//     hasScheduledPayment(paymentContract.schedule)
//   );
// }

function isValidPaymentContractStatus(status: string) {
  return ['PENDING', 'ACTIVE', 'IN_PROCESS'].includes(status);
}

function hasFailedOrder(propYearHistory: PropertyYearHistory) {
  return propYearHistory.paymentContracts.some(
    paymentContract => !isValidPaymentContractStatus(paymentContract.status)
  );
}

function hasCanceledOrder(propYearHistory: PropertyYearHistory) {
  return propYearHistory.paymentContracts.some(
    paymentContract => paymentContract.status === 'CANCELED'
  );
}

function hasOrders(propYearHistory: PropertyYearHistory) {
  return propYearHistory.paymentContracts?.length > 0;
}

function getScheduledText(installments: number[]) {
  const sortedInstallments = installments.sort();
  let verb = 'is';
  if (installments.length > 0) {
    verb = 'are';
  }
  return `Installment ${sortedInstallments.join(' & ')} ${verb} scheduled to be paid to ESP`;
}

function getPaidText(orderInstallments: number[], installments: number[]) {
  if (orderInstallments.length === installments.length) {
    return 'Both installments were paid to your county';
  }
  let installmentText = 'Installment';
  if (installments.length > 0) {
    installmentText += 's';
  }

  return `${installmentText} ${installments.join(' & ')} paid to your county`;
}

const parseCurrentYearPropertyToCard = (
  currentYearProperty: CurrentYearProperty
): PropertyCardData => {
  const { propertyYearHistory } = currentYearProperty;
  let hasPaymentPlan;
  const isEstimate = propertyYearHistory.paymentContracts[0]?.billType === 3;

  propertyYearHistory.paymentContracts.forEach(paymentContract => {
    if (paymentContract.hasPaymentPlan) {
      hasPaymentPlan = true;
    }
  });

  let status: 'PAID' | 'SCHEDULED' | 'INSUFFICIENT' = 'PAID';
  let statusText: string = '';
  // const scheduledOrders = getScheduledOrders(propertyYearHistory);

  const scheduledInstallments: number[] = [];
  const espPaidInstallments: number[] = [];

  propertyYearHistory.paymentContracts.forEach(paymentContract =>
    paymentContract.installmentContracts.forEach(installmentContract => {
      if (installmentContract.isPaid) {
        if (!espPaidInstallments.includes(installmentContract.installmentNumber)) {
          espPaidInstallments.push(installmentContract.installmentNumber);
        }
      } else if (!scheduledInstallments.includes(installmentContract.installmentNumber)) {
        scheduledInstallments.push(installmentContract.installmentNumber);
      }
    })
  );

  if (
    hasFailedOrder(propertyYearHistory) ||
    hasCanceledOrder(propertyYearHistory) ||
    !hasOrders(propertyYearHistory)
  ) {
    status = 'INSUFFICIENT';
    statusText = 'Insufficient funds to pay your county';
  } else if (scheduledInstallments.length > 0) {
    status = 'SCHEDULED';
    statusText = getScheduledText(scheduledInstallments);
  } else if (espPaidInstallments.length > 0) {
    status = 'PAID';
    statusText = `${getPaidText(
      espPaidInstallments,
      [1, 2] // TODO: This is hard coded because we do not have the actual installments for the tax bill. Only those that have been paid for.
    )} by ESP`;
  }
  // else {
  //   status = 'PAID';
  //   statusText = getPaidText(
  //     otherPaidInstallments.map(installment => installment.installmentNumber),
  //     installmentNumbers
  //   );
  // }
  const cardData: PropertyCardData = {
    hasImage: true,
    address: parseAddressForCard(currentYearProperty.address),
    isMonthly: hasPaymentPlan,
    isEstimate,
    taxBalanceText: isEstimate ? 'Estimated tax bill balance' : 'Tax bill balance',
    billNumber: currentYearProperty.billNumber,
    apn: currentYearProperty.apn,
    taxYear: formatTaxYear(currentYearProperty.taxYear),
    showDisclaimers: false,
    countyName: currentYearProperty.product?.displayName,
    lastBillingDays: currentYearProperty.product?.billing?.lastDayToPayNumDays! * -1,
    abbreviation: currentYearProperty.product?.apnLookupSettings.abbreviation || 'APN',
    billNumberLabel: currentYearProperty.product?.billNumberSettings?.displayName,
    status,
    statusText,
  } as any;

  return cardData;
};

export default parseCurrentYearPropertyToCard;
