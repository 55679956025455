import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import pxToEms from '../../helpers/pxToEms';
import { FONT_SERIF, FONT_SANS_SERIF, COLORS } from '../../constants/styles';
import toPrettyUSD from '../../helpers/toPrettyUSD';

import DisclaimerTag from '../DisclaimerTag';
import Disclaimer from '../Disclaimer';
import PropertyBanner from '../PropertyBanner';
import formatTransactionRate from '../../helpers/formatTransactionRate';
import Translate from '../../../libraries/translate/translate';

export interface PropertyCardData {
  abbreviation: string;
  address: {
    formattedAddress: string;
    address1: string;
    address2: string;
  };
  apn: string;
  balance?: {
    title: string;
    value: string;
    nextPaymentTitle: string;
    nextPaymentDate: string;
  };
  balanceTitle?: string;
  balanceValue?: string;
  billNumber: string;
  billNumberLabel: string;
  countyName: string;
  descTitle?: string;
  descValue?: string;
  disclaimerPaymentCopy?: string;
  hasImage: boolean;
  isEstimate: boolean;
  isMonthly: boolean;
  lastBillingDays?: number;
  paymentAmount?: string;
  paymentTitle?: string;
  rate: number | string;
  showBalance?: boolean;
  showDisclaimers: boolean;
  status?: string;
  statusText?: string;
  taxBillBalance?: number;
  taxBalanceText?: string;
  taxYear: string;
}

export interface PropertyCardProps {
  cardData: PropertyCardData;
  width?: number;
  header?: React.ReactNode;
  rows: React.ReactNode[];
  onPropertySelect?: (apn: string, year: number) => void;
  year?: number;
  rowDisplay?: 'flex' | 'block' | 'inherit';
}

const cardHeight = 380;
const cardTopHeight = 224;
const cardBottomHeight = cardHeight - cardTopHeight;

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: ({ width }: { width?: number }) => ({
    width: pxToEms(width || 432),
    textAlign: 'left',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  }),
  card: {
    position: 'relative',
    borderRadius: pxToEms(3),
    boxShadow: 'inset 0 0 140px 40px rgba(0,0,0,0.7), 0 1px 4px 0 rgba(0,0,0,0.3)',
    minHeight: pxToEms(cardHeight),
    width: '100%',
    marginBottom: pxToEms(24),
  },
  cardTop: {
    borderTopLeftRadius: pxToEms(3),
    borderTopRightRadius: pxToEms(3),
    // background: `url(${info.bannerURL}) no-repeat no-repeat top center`,
    backgroundSize: 'cover',
    height: pxToEms(cardTopHeight),
  },
  banner: {
    height: '224px',
  },
  balance: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    padding: `8px 16px`,
    backgroundColor: '#34ACE0',
    borderTopRightRadius: pxToEms(3),

    '& > * ': {
      color: '#FFFFFF !important',
      display: 'block',
    },

    '& > *:last-child': {
      fontSize: pxToEms(20),
      fontWeight: 'bold',
    },
  },
  cardBottom: {
    borderBottomLeftRadius: pxToEms(3),
    borderBottomRightRadius: pxToEms(3),
    backgroundColor: '#FFFFFF',
    minHeight: pxToEms(cardBottomHeight),
    paddingTop: pxToEms(16),

    '& tr': {
      display: 'block',
    },

    '& td': {
      display: 'block',
      '& div': {
        borderRight: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      '&:last-child div': {
        marginBottom: 0,
        paddingBottom: '1rem',
      },

      '& div > span': {
        display: 'inline-block',
      },
    },

    [theme.breakpoints.up('ssm')]: {
      '& tr': {
        display: 'table-row',
      },

      '& td': {
        display: 'table-cell',
        '& div': {
          borderRight: 'unset',
        },

        '&:last-child div': {
          marginBottom: '1rem',
          paddingBottom: '0',
        },

        '& div > span': {
          display: 'inherit !important',

          '&:last-child': {
            float: 'none !important',
          },
        },
      },
    },
  },
  addressLine1: {
    margin: 0,
    padding: `0 ${pxToEms(24)}`,
    fontFamily: FONT_SERIF,
    color: COLORS.GREY_02,
    fontSize: pxToEms(20),
    letterSpacing: pxToEms(0.43),
    lineHeight: pxToEms(28),
    textTransform: 'capitalize',
  },
  addressLine2: {
    margin: 0,
    marginTop: pxToEms(4),
    padding: `0 ${pxToEms(24)}`,
    fontFamily: FONT_SANS_SERIF,
    color: '#666666',
    fontSize: pxToEms(16),
    letterSpacing: pxToEms(0.34),
    lineHeight: pxToEms(19),
    textTransform: 'capitalize',
  },
  divider: {
    marginTop: pxToEms(16),
    height: pxToEms(1),
    backgroundColor: COLORS.BORDER,
  },
  paymentInfo: {
    margin: 0,
    // paddingTop: pxToEms(16),
    width: '100%',
    '& tr': {
      width: '100%',
      borderBottomLeftRadius: pxToEms(3),
      borderBottomRightRadius: pxToEms(3),
    },
    '& td': {
      borderTop: `1px solid ${COLORS.BORDER}`,

      '& div': {
        margin: `${pxToEms(16)} 0`,
        padding: `0 ${pxToEms(12)}`,
      },
      '&:not(:last-of-type)  div': {
        [theme.breakpoints.up('ssm')]: {
          borderRight: `${pxToEms(1)} solid #DDDDDD`,
        },
      },
    },
    '& div > span': {
      display: 'block',

      '& sup': {
        top: '-1em',
      },
    },
  },
  td: {
    borderTop: `1px solid ${COLORS.BORDER}`,

    '& div': {
      margin: `${pxToEms(16)} 0`,
      padding: `0 ${pxToEms(12)}`,
    },
    '&:not(:last-of-type)  div': {
      [theme.breakpoints.up('ssm')]: {
        borderRight: `${pxToEms(1)} solid #DDDDDD`,
      },
    },
  },
  infoColTitle: {
    color: COLORS.GREY_02,
    fontFamily: FONT_SANS_SERIF,
    fontSize: pxToEms(14),
    fontStyle: 'italic',
    letterSpacing: pxToEms(0.3),
    lineHeight: pxToEms(17),
    flex: 1,
  },
  infoColSubTitle: {
    color: '#666666',
    fontFamily: FONT_SANS_SERIF,
    fontSize: pxToEms(16),
    letterSpacing: pxToEms(0.34),
    lineHeight: pxToEms(26),
  },
  infoPaymentAmount: {
    color: '#227093',
    fontFamily: FONT_SANS_SERIF,
    fontSize: pxToEms(20),
    fontWeight: 700,
    letterSpacing: pxToEms(0.43),
    lineHeight: pxToEms(26),
  },

  billing: {
    backgroundColor: '#F3FBFF',
  },

  countyName: {
    textTransform: 'capitalize',
  },

  buttonWrapper: {
    marginLeft: 8,
    marginRight: 8,
    cursor: 'pointer',
    padding: 0,
  },
}));

const PropertyCard: FunctionComponent<PropertyCardProps> = ({
  width,
  cardData,
  header,
  rows,
  onPropertySelect,
  year,
  rowDisplay = 'inherit',
}: PropertyCardProps) => {
  const styles = useStyles({ width });

  const card = (
    <section className={styles.cardContainer}>
      <div className={styles.card}>
        {cardData.taxBillBalance && (
          <div className={styles.balance}>
            <span className={styles.infoColTitle}>
              <Translate>{cardData.taxBalanceText}</Translate>
            </span>
            <span className={styles.infoColSubTitle}>{toPrettyUSD(cardData.taxBillBalance)}</span>
          </div>
        )}
        <section className={styles.cardTop}>
          <PropertyBanner
            className={styles.banner}
            hideBackground
            address={cardData.hasImage ? cardData.address.formattedAddress : ''}
          />
        </section>
        <section className={styles.cardBottom}>
          <p className={styles.addressLine1}>{cardData.address.address1}</p>
          <p className={styles.addressLine2}>{cardData.address.address2 || '\u00a0'}</p>
          <div style={{ paddingTop: pxToEms(16) }}>{header}</div>
          <table className={styles.paymentInfo} cellPadding={0} cellSpacing={0}>
            <tbody>
              {cardData.paymentAmount && (
                <tr>
                  <td>
                    <div>
                      <span className={styles.infoColTitle}>
                        <Translate>{cardData.paymentTitle}</Translate>
                      </span>
                      <span className={styles.infoPaymentAmount}>
                        <span>{cardData.paymentAmount}</span>
                        <DisclaimerTag tag="1" />
                      </span>
                    </div>
                  </td>

                  <td colSpan={2}>
                    <div>
                      <span className={styles.infoColTitle}>
                        <Translate>{cardData.descTitle}</Translate>
                      </span>
                      <span className={styles.infoColSubTitle}>
                        <Translate>{cardData.descValue}</Translate>
                      </span>
                    </div>
                  </td>
                </tr>
              )}
              {cardData.balance && (
                <tr>
                  <td colSpan={cardData.balance.nextPaymentDate ? 1 : 3}>
                    <div style={{ display: rowDisplay }}>
                      <span className={styles.infoColTitle}>
                        <Translate>{cardData.balanceTitle}</Translate>
                      </span>
                      <span className={styles.infoColSubTitle}>{cardData.balanceValue}</span>
                    </div>
                  </td>
                  {cardData.balance.nextPaymentDate && (
                    <td colSpan={2}>
                      <div style={{ display: rowDisplay }}>
                        <span className={styles.infoColTitle}>
                          <Translate>{cardData.balance.nextPaymentTitle}</Translate>
                        </span>
                        <span className={styles.infoColSubTitle}>
                          <span>{cardData.balance.nextPaymentDate}</span>
                          <DisclaimerTag tag="2" />
                        </span>
                      </div>
                    </td>
                  )}
                </tr>
              )}
              <tr className={styles.billing}>
                {rows.map((row: React.ReactNode, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <td key={index} className={styles.td}>
                    <div style={{ display: rowDisplay }}>{row}</div>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </section>
      </div>
      <div>
        {cardData.showDisclaimers && (
          <>
            <Disclaimer tag="1">
              <Translate>{`You are agreeing to make ${
                cardData.disclaimerPaymentCopy
              } to Smart Easy Pay Inc. in
              the amount of ${cardData.paymentAmount}, which includes the${' '}
              ${formatTransactionRate(cardData.rate as number)} transaction fee; and in return, we
              will pay your property tax on time to${' '}`}</Translate>
              <span className={styles.countyName}>{cardData.countyName}.</span>
            </Disclaimer>
            {cardData.balance?.nextPaymentDate && (
              <Disclaimer tag="2">
                <Translate>
                  {`Your last day to pay Easy Smart Pay is ${cardData.lastBillingDays} days prior to
                  the installment due date.`}
                </Translate>
              </Disclaimer>
            )}
          </>
        )}
      </div>
    </section>
  );

  return (
    <>
      {!!onPropertySelect && !!year ? (
        <button
          type="button"
          onClick={() => onPropertySelect(cardData.billNumber, year)}
          className={styles.buttonWrapper}
        >
          {card}
        </button>
      ) : (
        <>{card}</>
      )}
    </>
  );
};

export default PropertyCard;
