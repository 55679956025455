import { Address } from '../../../global/models/Address';

const parseAddressForCard = (address?: Address) => {
  let address1 = 'Address unavailable';
  let address2 = '';
  let formattedAddress = '';
  if (address) {
    address1 = address.fullAddress;
    address2 =
      address.city &&
      address.state &&
      `${address.city}, ${address.state.toUpperCase()} ${address.zipCode}`;
    formattedAddress = address.formattedAddress;
  }

  return { address1, address2, formattedAddress };
};

export default parseAddressForCard;
