import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { LinearProgress, makeStyles, Theme } from '@material-ui/core';
import { BORDER_STYLE, FONT_SERIF } from '../constants/styles';
import BREAKPOINTS from '../constants/breakpoints';
import { Translate } from '../../libraries/translate';

interface Props {
  label?: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    fontFamily: FONT_SERIF,
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.34px',
    lineHeight: '24px',
    textAlign: 'center',
    marginTop: 32,
    padding: 32,
    '& p': {
      margin: 0,
      padding: 0,
      marginBottom: 32,
    },
    border: BORDER_STYLE,
    maxWidth: 720,
    margin: '0 auto',

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      maxWidth: 430,
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      margin: '0 16px',
    },
  },
}));

const Loading: FunctionComponent<Props> = ({ label, className = '' }: Props) => {
  const styles = useStyles();
  return (
    <section className={classnames(styles.container, className)}>
      {label && (
        <p>
          <Translate>{label}</Translate>
        </p>
      )}
      <LinearProgress />
    </section>
  );
};

export default Loading;
