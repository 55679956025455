import Translator from './translator';

export default class IdenticalTranslator extends Translator {
  // eslint-disable-next-line class-methods-use-this
  translate(value: string): Promise<string | undefined> {
    return new Promise((resolve): void => {
      resolve(value);
    });
  }
}
