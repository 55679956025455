import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import toPrettyUSD from '../helpers/toPrettyUSD';
import { OrderViaParams } from '../models/Order';
import PropertyBillContext from '../contexts/PropertyBillContext';
import InfoBox from './InfoBox/InfoBox';
import { Translate } from '../../libraries/translate';

interface Props {
  orderViaParams?: OrderViaParams;
}
const OrderSuccess: FunctionComponent<Props> = ({ orderViaParams }: Props) => {
  const { state, selectors, methods } = useContext(PropertyBillContext);
  const [address, setAddress] = useState<string>('');
  const [selectedOrder, setSelectedOrder] = useState<OrderViaParams>();
  const [alreadyGotProperty, setAlreadyGotProperty] = useState<boolean>(false);
  const [tagFired, setTagFired] = useState<boolean>(false);

  useEffect(() => {
    setSelectedOrder(orderViaParams);
  }, []);

  useEffect(() => {
    if (selectedOrder && !tagFired) {
      setTagFired(true);

      TagManager.dataLayer({
        dataLayer: {
          event: 'create-order-success',
          value: selectedOrder.amount / 100,
          transactionId: selectedOrder.receiptId,
        },
      });
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (selectedOrder && !alreadyGotProperty) {
      const { apn, billNumber } = selectedOrder;
      const property = selectors?.selectProperty(apn, billNumber);
      if (property) {
        const addr: string = property.address?.fullAddress || property.taxBill!.billNumber;
        setAddress(addr);
      } else if (!state.loading && !state.errors) {
        setAlreadyGotProperty(true);
        methods?.getProperty(selectedOrder.taxrollId, apn, billNumber, false, '');
      }
    }
  }, [methods, selectedOrder, selectors, state.errors, state.loading, alreadyGotProperty]);
  function renderInfoBox() {
    if (selectedOrder) {
      const message = address
        ? `Awesome! You’ve just paid ${toPrettyUSD(
            selectedOrder.amountPaid
          )} toward the tax bill for ${address}.`
        : `Awesome! You've just paid ${toPrettyUSD(
            selectedOrder.amountPaid
          )} towards your tax bill.`;
      return (
        <InfoBox header={message}>
          <Translate>{`We’ve emailed you a receipt confirming this transaction (ID:
          ${selectedOrder?.receiptId})`}</Translate>
        </InfoBox>
      );
    }

    return <></>;
  }
  return <>{renderInfoBox()}</>;
};

export default OrderSuccess;
