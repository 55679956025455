import React, { FunctionComponent, useContext } from 'react';
import { RouteProps } from 'react-router';

import AuthContext from '../contexts/AuthContext';
import { REACT_APP_WEB_URL } from '../helpers/constants';
import Reroute from './Reroute';

interface Props extends RouteProps {
  children: React.ReactNode;
}

/**
 * An enhanced Route that ensures the user is logged in. Logged-out users will be redirected away.
 */
const ProtectedRoute: FunctionComponent<Props> = ({ children }: Props) => {
  const { state } = useContext(AuthContext);
  return <>{state.isLoggedIn ? children : <Reroute route={`${REACT_APP_WEB_URL}/`} />}</>;
};

export default ProtectedRoute;
