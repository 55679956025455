import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import classnames from 'classnames';
import BREAKPOINTS from '../../../constants/breakpoints';
import { BORDER_STYLE } from '../../../constants/styles';

export interface ContentColumnProps {
  children: React.ReactNode;
  minWidth?: number;
  className?: string;
  preFooter?: React.ReactNode | null;
  footer?: React.ReactNode;
  header?: React.ReactNode | null;
}

const ContentColumn: FunctionComponent<ContentColumnProps> = (props: ContentColumnProps) => {
  const { children, minWidth, className, preFooter, footer, header } = props;

  const useStyles = makeStyles((theme: Theme) => ({
    contentCol: (inputs: { addPadding: boolean }) => ({
      minWidth,
      padding: inputs.addPadding ? '8px 0 0 0px' : '0',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignContent: 'space-between',

      [theme.breakpoints.down(BREAKPOINTS.LG)]: {
        padding: 0,
        minWidth: 0,
      },
    }),
    children: {
      width: '100%',
      padding: '0 24px 16px 24px',

      '&:first-child': {
        flexGrow: 1,
      },

      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        padding: '0 16px 16px 16px',
      },
    },
    footer: {
      borderTop: BORDER_STYLE,
      width: '100%',
      paddingTop: '16px',
    },
    annotation: {
      width: '100%',
    },
    header: {
      width: '100%',
    },
  }));

  const styles = useStyles({ addPadding: !header });
  const columnClassname = className || styles.contentCol;
  return (
    <div className={classnames([styles.contentCol, columnClassname])}>
      {!!header && <div className={classnames([styles.header])}>{header}</div>}
      <div className={styles.children}>{children}</div>
      {!!preFooter && <div className={classnames([styles.annotation])}>{preFooter}</div>}
      {!!footer && (
        <footer className={classnames([styles.children, styles.footer])}>{footer}</footer>
      )}
    </div>
  );
};

export default ContentColumn;
