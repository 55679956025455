import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { BillDate } from '../../../../../global/models/Property';
import toPrettyDate from '../../../../../global/helpers/toPrettyDate';
import ReconciledIcon from '../../../../../global/components/icons/ReconciledIcon';
import FailedIcon from '../../../../../global/components/icons/FailedIcon';
import PaymentScheduledIcon from '../../../../../global/components/icons/PaymentScheduledIcon';
import toPrettyUSD from '../../../../../global/helpers/toPrettyUSD';
import { BORDER_STYLE } from '../../../../../global/constants/styles';
import DisclaimerTag from '../../../../../global/components/DisclaimerTag';
import ReceiptIcon from '../../../../../global/components/icons/ReceiptIcon';
import usePaymentRowStyles from '../../../../../global/hooks/usePaymentRowStyles';
import { Translate } from '../../../../../libraries/translate';

interface Props {
  amount: number;
  amountLabel?: string;
  installments?: { installmentNumber: number; isPaid: boolean; lastPayDate: BillDate }[];
  dueDate?: Date | BillDate;
  receiptId?: string;
  status: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  money: {
    width: '.7em',
    height: '.7em',
  },

  amount: {
    flexBasis: 150,

    [theme.breakpoints.down(900)]: {
      flexBasis: '100%',
    },
  },

  dueDate: {},

  payStatus: {
    flexBasis: 234,

    [theme.breakpoints.down(900)]: {
      flexBasis: '100%',
    },
  },

  payDate: {
    marginTop: '4px',
  },

  installmentRow: {
    '& > *': {
      flexBasis: '100% !important',
      display: 'flex',
      flexDirection: 'row',
      margin: '0',
      padding: '24px',
      marginLeft: -1,
      borderTop: BORDER_STYLE,

      '&:last-child': {},

      '& .label': {
        fontWeight: 'bold',
        lineHeight: '24px',
      },

      '& > p': {
        paddingRight: '24px',
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: 3,
      },

      '& > div': {
        paddingRight: '16px',
        width: '100%',

        '& *': {
          margin: 0,
          display: 'inline',

          '&:last-child': {
            paddingLeft: 5,
          },
        },
      },
    },
  },
}));

const PaymentSummaryColumn: FunctionComponent<Props> = ({
  amount,
  amountLabel = 'Amount paid to ESP',
  installments = [],
  dueDate,
  receiptId,
  status,
}: Props) => {
  const styles = useStyles({ minWidth: 768 });
  const rowStyles = usePaymentRowStyles({ minWidth: 768 });
  const billedAmount = toPrettyUSD(amount);

  function renderPaidSection(installment: {
    installmentNumber: number;
    isPaid: boolean;
    lastPayDate: BillDate;
  }) {
    let icon;
    let payCopy;
    const date = toPrettyDate(installment.lastPayDate);

    if (installment.isPaid) {
      icon = <ReconciledIcon />;
      payCopy = `Installment ${installment.installmentNumber} was paid to your county by ESP on`;
    } else if (status === 'CANCELED') {
      icon = <FailedIcon />;
      payCopy = `Installment ${installment.installmentNumber} will not be paid by ESP on`;
    } else {
      icon = <PaymentScheduledIcon />;
      payCopy = `Installment ${installment.installmentNumber} scheduled to be paid by ESP on`;
    }

    return (
      <div key={installment.installmentNumber} className={styles.payStatus}>
        <p>{icon}</p>
        <div>
          <span className="label">
            <Translate>{payCopy}</Translate>
          </span>
          <p className={styles.payDate}>{date}</p>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={rowStyles.row}>
        <div className={styles.amount}>
          <p>
            <AttachMoneyIcon className={styles.money} />
          </p>
          <div>
            <span className="label">
              <Translate>{amountLabel}</Translate>
            </span>
            <p className={styles.payDate}>
              {billedAmount}
              <DisclaimerTag tag="*" />
            </p>
          </div>
        </div>
        {dueDate && (
          <div className={styles.dueDate}>
            <p>
              <CheckIcon />
            </p>
            <div>
              <span className="label">
                <Translate>Payment date</Translate>
              </span>
              <p>{toPrettyDate(dueDate)}</p>
            </div>
          </div>
        )}
        {receiptId && (
          <div className={styles.dueDate}>
            <p>
              <ReceiptIcon />
            </p>
            <div>
              <span className="label">
                <Translate>ReceiptId</Translate>
              </span>
              <p>{receiptId}</p>
            </div>
          </div>
        )}
        {!receiptId && <>{installments?.map(installment => renderPaidSection(installment))}</>}
      </div>
      {receiptId && (
        <div className={styles.installmentRow}>
          {installments?.map(installment => renderPaidSection(installment))}
        </div>
      )}
    </>
  );
};

export default PaymentSummaryColumn;
