import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { AuthProvider } from './global/contexts/AuthContext';
import { ProductsProvider } from './global/contexts/ProductsContext';
import { PropertyBillsProvider } from './global/contexts/PropertyBillsContext';
import { OrdersProvider } from './global/contexts/OrderContext';
import { AccountHistoryProvider } from './global/contexts/OrderHistoryContext';
import { PropertyBillProvider } from './global/contexts/PropertyBillContext';
import { FastFactsProvider } from './global/contexts/FastFactsContext';
import { PaymentMethodsProvider } from './global/contexts/PaymentMethodsContext';
import { SnackbarMessageProvider } from './global/contexts/SnackbarMessageContext';
import {
  REACT_APP_GTM_ID,
  REACT_APP_GTM_ENV,
  REACT_APP_GTM_AUTH,
} from './global/helpers/constants';
import { DemographicsProvider } from './global/contexts/DemographicsContext';

const tagManagerArgs: TagManagerArgs = {
  gtmId: REACT_APP_GTM_ID || '',
  auth: REACT_APP_GTM_AUTH,
  preview: REACT_APP_GTM_ENV,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Router>
    <AuthProvider>
      <SnackbarMessageProvider>
        <ProductsProvider>
          <PropertyBillsProvider>
            <PropertyBillProvider>
              <OrdersProvider>
                <AccountHistoryProvider>
                  <FastFactsProvider>
                    <DemographicsProvider>
                      <PaymentMethodsProvider>
                        <App />
                      </PaymentMethodsProvider>
                    </DemographicsProvider>
                  </FastFactsProvider>
                </AccountHistoryProvider>
              </OrdersProvider>
            </PropertyBillProvider>
          </PropertyBillsProvider>
        </ProductsProvider>
      </SnackbarMessageProvider>
    </AuthProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
