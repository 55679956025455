import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { FormControl, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import ContentColumn from '../../../../global/components/ContentColumn';
// import PropertyBillContext from '../../../../global/contexts/PropertyBillContext';
import { AccountHistory, PropertyHistory } from '../../../../global/models/AccountHistory';
import { Product } from '../../../../global/models/Product';
import PropertyBanner from '../../../../global/components/PropertyBanner';
import BREAKPOINTS from '../../../../global/constants/breakpoints';
import {
  BORDER_COLOR,
  BORDER_STYLE,
  COLORS,
  FONT_SERIF,
} from '../../../../global/constants/styles';
import pxToEms from '../../../../global/helpers/pxToEms';
import PropertySummaryBar from '../../../../global/components/PropertySummaryBar';
import { TaxBill } from '../../../../global/models/Property';
import useStickyListener from '../../../../global/hooks/useStickyListener';
import useTabsStyles from '../../../../global/hooks/useTabsStyles';
import PropertyBillContext from '../../../../global/contexts/PropertyBillContext';
import Loading from '../../../../global/components/Loading';
import formatTaxYear from '../../../../global/helpers/formatTaxYear';
import TaxBillDetails from './components/TaxBillDetails';
import PaymentDetails from './components/PaymentDetails';
import FastFactsWrapper from './components/fastFacts/FastFactsWrapper';
import isValidPaymentContractStatus from '../../helpers/isValidPaymentContractStatus';
import { Translate } from '../../../../libraries/translate';

interface Props {
  year: string;
  products: Product[];
  accountHistory: AccountHistory;
  onYearChange: (year: number, billNumber: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    height: 364,
    margin: '0 auto',

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      height: 144,
    },
  },

  loading: {
    backgroundColor: '#FFF',
    marginBottom: 32,
  },

  scrollContainer: {
    '& .image-banner': {
      zIndex: 4,
    },

    [theme.breakpoints.up(BREAKPOINTS.SM)]: {
      '&.sticky': {
        marginTop: 400,

        '& .image-banner': {
          height: 144,
          position: 'fixed',
          top: '-274px',
          right: 0,
          left: 0,
        },

        [theme.breakpoints.down(BREAKPOINTS.SM)]: {
          // top: '-80px',
        },
      },
    },
  },

  backArrow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    transform: 'rotate(180deg)',
  },

  mobileBack: {
    display: 'none',
    position: 'absolute',
    top: 36,
    left: 90,
    color: '#FFF',
    zIndex: 6,

    boxSizing: 'border-box',
    height: '41px',
    width: '41px',
    border: '1px solid #FFFFFF',
    borderRadius: '20px',
    backgroundColor: 'rgba(255,255,255,0)',
    flexDirection: 'column',
    justifyContent: 'center',

    '& span': {
      display: 'none',
      fontFamily: FONT_SERIF,
      color: COLORS.DEFAULT,
    },

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      display: 'flex',
      border: 'none',
      position: 'relative',
      backgroundColor: '#FFF',
      top: 0,
      left: 0,
      width: '100%',
      color: '#000',
      flexDirection: 'row',
      justifyContent: 'start',
      height: 'auto',
      padding: 16,

      '& span': {
        display: 'inline-block',
        paddingLeft: '16px',
      },
    },
  },

  container: {
    position: 'relative',
    '&::before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: '50%',
      height: '100%',
      backgroundColor: '#F8F8F8',
      zIndex: 1,

      [theme.breakpoints.down(768)]: {
        display: 'none',
      },
    },

    '& > *': {
      position: 'relative',
      zIndex: 2,
    },
  },
  content: {
    margin: '0',

    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },

    '& > *': {
      marginTop: -64,
    },
  },
  contentColumn: {
    padding: 0,
    margin: '0 auto -40px auto',
    maxWidth: '1250px',
    width: '100%',

    '& > section': {
      padding: '0 66px 0 0',
      margin: 0,
      display: 'flex',
      flexDirection: 'row',

      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        display: 'block',
        padding: 0,
        marginLeft: 16,
        marginRight: 16,
      },
    },
  },
  topDetails: {
    marginTop: 40,
    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      marginTop: 0,
      padding: '16px',
      border: BORDER_STYLE,
      marginBottom: '16px',
    },
  },
  sectionDetails: {
    backgroundColor: '#FFF',
    paddingLeft: pxToEms(40),
    marginTop: pxToEms(2),
    marginRight: pxToEms(18),
    maxWidth: '960px',
    width: '100%',

    [theme.breakpoints.down(768)]: {
      padding: '0',
    },
  },

  allProperties: {
    '& a:first-child': {
      color: COLORS.GREY_02,
      fontFamily: FONT_SERIF,
      fontWeight: 'bold',
      letterSpacing: '0.34px',
      lineHeight: '28px',
      display: 'flex',
      padding: '11px 24px 11px 18px',
      border: '1px solid #F8F8F8',
      maxWidth: '200px',

      '&:hover,&:active,&:focus': {
        border: BORDER_STYLE,
        backgroundColor: '#FFF',
      },

      '& > *:last-child': {
        paddingLeft: '16px',
      },
    },
  },

  navigation: {
    borderRight: BORDER_STYLE,
    paddingTop: pxToEms(16),
    maxWidth: 284,

    '& label': {
      display: 'block',
      color: COLORS.GREY_02,
      fontFamily: FONT_SERIF,
      fontSize: pxToEms(20),
      fontWeight: 'bold',
      letterSpacing: pxToEms(0.43),
      lineHeight: pxToEms(28),
      padding: pxToEms(24),
      borderBottom: BORDER_STYLE,
    },

    '& li': {
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: '0.3px',
      marginLeft: 66,

      '&:first-child': {
        paddingRight: 24,
        marginLeft: 0,
        width: '100% !important',

        [theme.breakpoints.down(BREAKPOINTS.SM)]: {
          display: 'none',
        },
      },
    },

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      borderRight: 0,
      paddingTop: 36,
      maxWidth: '100%',
      '& label': {
        display: 'none',
      },

      '& ul': {
        borderColor: BORDER_COLOR,
        padding: '0 12px',
        textAlign: 'center',
        justifyContent: 'space-evenly',
        display: 'flex',

        '& li': {
          paddingLeft: 20,
          margin: 0,
          lineHeight: '18px',
        },
      },
    },

    [theme.breakpoints.up(768)]: {
      '& ul': {
        margin: 0,
        padding: 0,
        border: 'none',
      },

      '& li': {
        listStyle: 'none',
        padding: pxToEms(24),
        paddingRight: 0,
        width: 'calc(100% - 66px)',
        borderBottom: `${BORDER_STYLE} !important`,

        '&:last-child': {
          borderBottom: 'none',
        },

        '&.active': {
          color: '#34ACE0',
        },
      },
    },
  },

  navItem: {
    color: '#666666',
    '& a': {
      color: '#666666',
    },

    [theme.breakpoints.down(430)]: {
      padding: '0 12px !important',
      fontWeight: 'bold',
    },
  },

  navItemActive: {
    color: '#34ACE0',
    '& a': {
      color: '#34ACE0',
    },
  },

  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      marginBottom: 32,

      [theme.breakpoints.up('md')]: {
        '&:first-child': {
          marginRight: pxToEms(24),
        },
      },
    },

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      justifyContent: 'center',
      display: 'block',
      '& > *': {
        float: 'none !important',
        margin: 0,
      },
    },
  },

  titleDropdown: {
    [theme.breakpoints.down('sm')]: {
      borderTop: BORDER_STYLE,
      marginTop: 16,
      paddingTop: 16,
    },
  },

  propertySummary: {
    // marginTop: pxToEms(24),
    borderTop: BORDER_STYLE,
    paddingTop: pxToEms(24),
    paddingLeft: pxToEms(24),
    paddingBottom: pxToEms(24),
    display: 'flex',
    clear: 'both',
    textTransform: 'capitalize',

    '& .label': {
      fontWeight: 'bold',
    },

    '& > *': {
      marginRight: pxToEms(36),

      '&:last-child': {
        marginRight: 0,
      },
    },

    [theme.breakpoints.down(768)]: {
      flexDirection: 'column',
      padding: `16px 0 8px 0`,
      marginTop: 16,

      '& > *': {
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: 0,
        marginBottom: 16,

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  },

  details: {
    marginBottom: 16,
  },

  errorContainer: {
    maxWidth: 1250,
    marginBottom: 72,
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginRight: 16,
      marginLeft: 16,
    },
  },
}));

const SECTIONS: any = {
  taxbill: {
    navItem: 'Tax Bill',
    title: 'Secured Property Tax Bill',
  },
  payments: {
    navItem: 'Payments',
    title: 'ESP Payment History',
  },
  fastfacts: {
    navItem: 'Fast Facts',
    title: 'Fast Facts',
  },
};

const PropertyYearDetails: FunctionComponent<Props> = ({
  products,
  accountHistory,
  year,
  onYearChange,
}: Props) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const tabsStyles = useTabsStyles();
  const { billNumber } = useParams();
  const [propertyHistory, setPropertyHistory] = useState<PropertyHistory>();
  const [product, setProduct] = useState<Product>();
  const [taxBill, setTaxBill] = useState<TaxBill>();
  const [availableBillNumbers, setAvailableBillNumbers] = useState<string[]>();
  const [availableYears, setAvailableYears] = useState<string[]>();
  const [netTaxableValue, setNetTaxableValue] = useState<number | null>(null);
  const { state, selectors, methods } = useContext(PropertyBillContext);
  const [navigation, setNavigation] = useState<{
    previousBillNumber?: string | undefined;
    nextBillNumber?: string | undefined;
  }>({});

  const urlParam = useParams()
    ['*']!.split('/')
    .pop();
  const activeNav = urlParam || 'taxbill';

  useStickyListener('scrollContainer', 'summary');

  const handleYearChange = useCallback(
    event => {
      onYearChange(parseInt(event.target.value), billNumber!);
    },
    [billNumber]
  );

  const handleBackToOverview = useCallback(() => {
    navigate(`../../${year}`);
  }, [year]);

  const handlePrevious = useCallback(() => {
    if (navigation.previousBillNumber) {
      navigate(`../../${year}/${navigation.previousBillNumber}`);
    }
  }, [navigation.previousBillNumber, year]);

  const handleNext = useCallback(() => {
    if (navigation.nextBillNumber) {
      navigate(`../../${year}/${navigation.nextBillNumber}`);
    }
  }, [navigation.nextBillNumber, year]);

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();
    let date: string = `10/15/${year}`;

    if (currentYear === year) {
      date = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentYear}`;
    }
    if (!propertyHistory) {
      return;
    }

    const propByNumber = selectors?.selectProperty(
      propertyHistory.apn,
      propertyHistory.billNumber,
      date
    );

    if (propByNumber) {
      const propertyTaxBill = propByNumber.taxBill;
      const netTaxable =
        propertyTaxBill.installments.find(item => item.netTaxableValue)?.netTaxableValue || null;
      setNetTaxableValue(netTaxable);

      if (!propertyTaxBill.isUnderContract) {
        const { paymentContracts } = propertyHistory.taxYears[year];
        propertyTaxBill.isUnderContract = paymentContracts.length > 0;

        propertyTaxBill.installments.forEach(installment => {
          installment.isUnderContract = !!paymentContracts.find(
            contract =>
              isValidPaymentContractStatus(contract.status) &&
              contract.installmentContracts.find(
                ic => ic.installmentNumber === installment.installmentNumber
              )
          );
        });
      }
      setTaxBill(propertyTaxBill);
    } else if (!state.loading && !state.errors) {
      const { taxRollId, apn } = propertyHistory;
      setTaxBill(undefined);
      methods?.getProperty(taxRollId, apn, propertyHistory.billNumber, currentYear === year, date);
    }
  }, [propertyHistory, methods, selectors, year]);

  useEffect(() => {
    if (availableBillNumbers && billNumber) {
      const index = availableBillNumbers.indexOf(billNumber);
      let previousBillNumber;
      let nextBillNumber;

      if (index > 0) {
        previousBillNumber = availableBillNumbers[index - 1];
      }

      if (index < availableBillNumbers.length - 1) {
        nextBillNumber = availableBillNumbers[index + 1];
      }
      setNavigation({ previousBillNumber, nextBillNumber });
    } else {
      setNavigation({});
    }
  }, [billNumber, availableBillNumbers]);

  useEffect(() => {
    if (accountHistory && billNumber && accountHistory[billNumber]) {
      const propHistory = accountHistory[billNumber];

      setPropertyHistory(propHistory);
      setAvailableBillNumbers(Object.keys(accountHistory));
      setProduct(products.find(prod => prod.taxrollId === propHistory.taxRollId));
      setAvailableYears(
        Object.keys(propHistory.taxYears).sort((a, b) => parseInt(b) - parseInt(a))
      );
    } else {
      setPropertyHistory(undefined);
      setAvailableBillNumbers([]);
      setProduct(undefined);

      handleBackToOverview();
    }
  }, [accountHistory, billNumber]);

  useEffect(() => {}, [taxBill]);

  function renderNavItem(section: string) {
    let navStyle = styles.navItem;

    if (section === activeNav) {
      navStyle = `${navStyle} ${styles.navItemActive} ${tabsStyles.activeTab}`;
      return (
        <li className={navStyle} key={section}>
          <NavLink to={section}>
            <Translate>{SECTIONS[section].navItem}</Translate>
          </NavLink>
        </li>
      );
    }
    return (
      <li className={navStyle} key={section}>
        <NavLink to={section}>
          <Translate>{SECTIONS[section].navItem}</Translate>
        </NavLink>
      </li>
    );
  }

  // Repsonsibilities
  // Get Tax Bill
  // Render Header and bar
  // Render Available Years for APN
  // Route to sub pages
  return (
    <section className={styles.scrollContainer} id="scrollContainer">
      {propertyHistory && (
        <>
          <NavLink to="../" className={styles.mobileBack}>
            <div className={styles.backArrow}>
              <ArrowRightAltIcon />
            </div>
            <span>
              <Translate>All properties</Translate>
            </span>
          </NavLink>
          <div className={styles.container}>
            <section className="image-banner">
              <PropertyBanner
                className={styles.banner}
                address={propertyHistory.address?.formattedAddress}
              />
              <section className={styles.content}>
                <div id="summary">
                  <PropertySummaryBar
                    onPrevious={navigation.previousBillNumber ? handlePrevious : undefined}
                    onNext={navigation.nextBillNumber ? handleNext : undefined}
                    property={{
                      address: propertyHistory.address,
                      taxBill,
                      product,
                    }}
                  />
                </div>
              </section>
            </section>

            {state.loading && (
              <Loading className={styles.loading} label="Loading Tax Bill Details..." />
            )}

            {!state.loading && (
              <ContentColumn className={styles.contentColumn} width={1000}>
                {taxBill && product && (
                  <section>
                    <div className={styles.navigation}>
                      <nav className={tabsStyles.tabs}>
                        <ul>
                          <li className={styles.allProperties}>
                            <NavLink to="../">
                              <div className={styles.backArrow}>
                                <ArrowRightAltIcon />
                              </div>
                              <span>
                                <Translate>All properties</Translate>
                              </span>
                            </NavLink>
                          </li>
                          {Object.keys(SECTIONS).map((section: string) => renderNavItem(section))}
                        </ul>
                      </nav>
                    </div>
                    <div className={styles.sectionDetails}>
                      <div className={styles.topDetails}>
                        <div className={styles.titleContainer}>
                          <Typography variant="h1">
                            <Translate>{SECTIONS[activeNav].title}</Translate>
                          </Typography>
                          {urlParam !== 'fastfacts' && (
                            <div className={styles.titleDropdown}>
                              <FormControl variant="outlined">
                                <Select
                                  labelId="billingYears"
                                  id="billingYears"
                                  displayEmpty
                                  value={year || ''}
                                  onChange={handleYearChange}
                                  inputProps={{ name: 'billingYear', 'aria-label': 'billing year' }}
                                >
                                  {availableYears?.map((availableYear: string) => (
                                    <MenuItem key={availableYear} value={availableYear}>
                                      {formatTaxYear(availableYear)}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          )}
                        </div>
                        <div className={styles.propertySummary}>
                          <div>
                            <span className="label">
                              <Translate>County: </Translate>{' '}
                            </span>
                            <span>{product?.county}</span>
                          </div>
                          <div>
                            <span className="label">
                              {product?.apnLookupSettings.abbreviation}:{' '}
                            </span>
                            <span>{taxBill.apn}</span>
                          </div>

                          <div>
                            <span className="label">
                              <Translate>{product?.billNumberSettings.displayName}</Translate>:{' '}
                            </span>
                            <span>{taxBill.billNumber}</span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.details}>
                        <Routes>
                          <Route
                            path="/"
                            element={<TaxBillDetails taxBill={taxBill} product={product} />}
                          />
                          <Route
                            path="/taxBill"
                            element={<TaxBillDetails taxBill={taxBill} product={product} />}
                          />
                          <Route
                            path="/payments"
                            element={
                              <PaymentDetails
                                paymentContracts={propertyHistory.taxYears[year].paymentContracts}
                                taxBill={taxBill}
                                product={product}
                              />
                            }
                          />
                          <Route
                            path="/fastfacts"
                            element={
                              <FastFactsWrapper
                                taxrollId={product?.taxrollId}
                                netTaxableValue={netTaxableValue}
                                amount={taxBill.amount}
                                billPeriod={taxBill.billPeriod}
                                countyName={product.county}
                              />
                            }
                          />
                        </Routes>
                      </div>
                    </div>
                  </section>
                )}
              </ContentColumn>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default PropertyYearDetails;
