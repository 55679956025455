export enum Language {
  en = 'en',
  es = 'es',
  hy = 'hy',
  cn = 'zh-CN',
  fa = 'fa',
  pa = 'pa',
  ru = 'ru',
  tl = 'tl',
  vi = 'vi',
  pt = 'pt',
}

const languages = new Map([
  [Language.en, 'English'],
  [Language.hy, 'Հայերեն'],
  [Language.cn, '简体中文'],
  [Language.fa, 'فارسی'],
  [Language.pa, 'ਪੰਜਾਬੀ ਭਾਸ਼ਾ'],
  [Language.ru, 'Русский'],
  [Language.es, 'Español'],
  [Language.tl, 'Tagalog'],
  [Language.vi, 'Tiếng Việt'],
  [Language.pt, 'Português'],
]);

export default languages;
