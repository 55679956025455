import { makeStyles, Theme } from '@material-ui/core';
import BREAKPOINTS from '../constants/breakpoints';
import { BORDER_STYLE, COLORS, FONT_SANS_SERIF } from '../constants/styles';

const usePaymentRowStyles = makeStyles((theme: Theme) => ({
  row: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 'initial !important',
    margin: '0',
    width: '100%',
    overflow: 'hidden',

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      flexWrap: 'wrap',
    },

    '& svg': {
      color: COLORS.DEFAULT,
    },

    '& > *': {
      position: 'relative',
      flex: '1 1 auto',
      borderLeft: BORDER_STYLE,
      margin: '16px 0',
      padding: '8px 24px',
      marginLeft: -1,

      '&::after': {
        content: '" "',
        position: 'absolute',
        top: '-17px',
        left: 0,
        right: 0,
        borderTop: BORDER_STYLE,
      },

      '&:first-child::after': {
        display: 'none',
      },

      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        display: 'flex',
        flexDirection: 'row',
        borderLeft: 'none',
        margin: '0',
        padding: '16px 16px 0 16px',
        width: '100%',

        '&::after': {
          top: 0,
          display: 'none',
        },

        '& > p': {
          paddingRight: '24px',
        },

        '& > div': {
          paddingBottom: '16px',
          paddingRight: '16px',
          borderBottom: BORDER_STYLE,
          width: '100%',
        },
      },

      '&:first-child': {
        borderLeft: 'none',
      },

      '&:last-child': {
        borderBottom: 'none',

        '& > div': {
          borderBottom: 0,
        },
      },

      '& .label': {
        fontFamily: FONT_SANS_SERIF,
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: 0.34,
        lineHeight: '24px',
      },

      '& *': {
        padding: 0,
        margin: 0,
      },
    },
  },
}));

export default usePaymentRowStyles;
