const getUrlParams = () => {
  const search = window.location.search.substring(1);
  if (search) {
    const url = decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"');

    return JSON.parse(`{"${url}"}`);
  }

  return {};
};

export default getUrlParams;
