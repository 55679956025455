import { DateTime } from 'luxon';

import { SERVER_DATE_FORMAT } from '../constants';
import { BillDate } from '../models/Property';

type Styles = 'shortMonth' | 'expiration' | 'international' | 'default';

/**
 * Take a date string like '2019-12-03' and format it into a human-readable date string
 */
export default function toPrettyDate(
  serverDate: string | BillDate | Date,
  style: Styles = 'default'
): string {
  let dateAsString: any = serverDate;
  if ((serverDate as BillDate).year) {
    const dateAsBillDate = { ...(serverDate as BillDate) };
    const day =
      dateAsBillDate.day.toString().length === 1 ? `0${dateAsBillDate.day}` : dateAsBillDate.day;
    const month =
      dateAsBillDate.month.toString().length === 1
        ? `0${dateAsBillDate.month}`
        : dateAsBillDate.month;
    dateAsString = `${month}/${day}/${dateAsBillDate.year}`;
  } else if (serverDate as Date) {
    dateAsString = (serverDate as Date).toLocaleDateString();
  }

  const date = DateTime.fromFormat(dateAsString, SERVER_DATE_FORMAT);

  switch (style) {
    case 'international':
      return date.toFormat('yyyy-MMM-dd');
    case 'shortMonth':
      // 'Dec 03, 2019'
      return date.toFormat('MMM d, yyyy');
    case 'expiration':
      return date.toFormat('MM/yyyy');
    default:
      // 'December 03, 2019'
      return date.toFormat('MMM dd, yyyy');
  }
}
