import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Theme } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useFastFacts } from '../../../../../../global/contexts/FastFactsContext';
import { FastFact } from '../../../../../../global/models/Facts/FastFact';
import FastFacts from './FastFacts';
import FastFactsDemographics from './FastFactsDemographics';
import { useDemographics } from '../../../../../../global/contexts/DemographicsContext';
import { DemographicFact } from '../../../../../../global/models/Facts/DemographicFact';
import FastFactsPersonal from './FastFactsPersonal';
import BREAKPOINTS from '../../../../../../global/constants/breakpoints';

interface FastFactsWrapperProps {
  taxrollId: string;
  netTaxableValue: number | null;
  amount: number | null;
  billPeriod: string | null;
  countyName: string | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonContainer: {
    position: 'absolute',
    zIndex: '100',
    left: '25px',
    top: '20px',
    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      left: '16px',
    },
    '& > button': {
      border: '1px solid rgb(211, 212, 213)',
      minWidth: '34px',
    },
    '& > button:last-child': {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderLeft: 0,
    },
    '& > button:first-child': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}));

function FastFactsWrapper({
  taxrollId,
  netTaxableValue,
  amount,
  billPeriod,
  countyName,
}: FastFactsWrapperProps) {
  const fastFactsContext = useFastFacts();
  const demographicsContext = useDemographics();

  const [selectedCountyDataSet, setSelectedCountyDataSet] = useState<FastFact | null>(null);
  const [
    selectedDemographicDataSet,
    setSelectedDemographicDataSet,
  ] = useState<DemographicFact | null>(null);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<string[]>(['tax', 'demographics']);

  const styles = useStyles({ minWidth: 768 });

  const handleNext = () => {
    setActiveStep(prevActiveStep => {
      let nextStep = prevActiveStep + 1;

      if (nextStep >= steps.length) {
        nextStep = 0;
      }

      return nextStep;
    });
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => {
      let prevStep = prevActiveStep - 1;

      if (prevStep < 0) {
        prevStep = steps.length - 1;
      }

      return prevStep;
    });
  };

  const urlParam = useParams()
    ? useParams()
        ['*']?.split('/')
        .pop()
    : '';

  useEffect(() => {
    if (urlParam === 'fastfacts' && taxrollId) {
      setSteps(['tax', 'demographics']);
      fastFactsContext.getFastFacts(taxrollId);
      demographicsContext.getTaxDistribution(taxrollId);

      if (netTaxableValue) {
        setSteps(['tax', 'personal', 'demographics']);
      }
    }
  }, [urlParam, taxrollId, netTaxableValue]);

  useEffect(() => {
    if (fastFactsContext.data) {
      setSelectedCountyDataSet(fastFactsContext.data as any);
    }
  }, [fastFactsContext.data]);

  useEffect(() => {
    if (demographicsContext.data) {
      setSelectedDemographicDataSet(demographicsContext.data as any);
    }
  }, [demographicsContext.data]);

  return (
    <div className={styles.wrapper}>
      {steps.length > 1 && (
        <div className={styles.buttonContainer}>
          <Button size="small" onClick={handleBack}>
            <KeyboardArrowLeft />
          </Button>
          <Button size="small" onClick={handleNext}>
            <KeyboardArrowRight />
          </Button>
        </div>
      )}
      {steps[activeStep] === 'tax' && (
        <FastFacts
          county={selectedCountyDataSet}
          type="tax"
          steps={steps.length > 1}
          error={fastFactsContext.error}
        />
      )}
      {steps[activeStep] === 'personal' && (
        <FastFactsPersonal
          county={selectedCountyDataSet}
          amount={amount}
          netTaxableValue={netTaxableValue}
          billPeriod={billPeriod}
          steps={steps.length > 1}
          error={fastFactsContext.error}
        />
      )}
      {steps[activeStep] === 'demographics' && (
        <FastFactsDemographics
          data={selectedDemographicDataSet}
          countyName={countyName}
          steps={steps.length > 1}
          error={demographicsContext.error}
        />
      )}
    </div>
  );
}

export default FastFactsWrapper;
