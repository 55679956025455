import Axios from 'axios';
import { PropertyBill, PropertyBills } from '../models/Property';
import { REACT_APP_GOOGLE_STREET_VIEW_API } from '../helpers/constants';
import { getAxios, API_PREFIX } from './espAxios';
import { Address } from '../models/Address';

const BASE_SERVICE_PATH = '/product';

export type PropertyBillResponse = PropertyBill;

export type PropertyBillsResponse = PropertyBills;

export function getPropertyBillByBillNumber(
  taxrollId: string,
  apn: string,
  billNumber: string,
  includeEstimate?: boolean,
  today?: string
): Promise<PropertyBillResponse> {
  let url = `${BASE_SERVICE_PATH}${API_PREFIX}/bills/${taxrollId}/${apn}/${billNumber}`;

  const params: string[] = [];
  if (today) {
    params.push(`today=${today}`);
  }

  if (includeEstimate) {
    params.push(`includeEstimate=true`);
  }

  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  return getAxios('product')
    .get<PropertyBillResponse>(url)
    .then(resp => resp.data);
}

export function getPropertyBillsByAPN(
  taxrollId: string,
  apn: string,
  today?: string
): Promise<PropertyBillsResponse> {
  let url = `${BASE_SERVICE_PATH}${API_PREFIX}/bills/${taxrollId}/find-by-apn?apn=${apn}`;

  if (today) {
    url += `&today=${today}`;
  }
  return getAxios('product')
    .get<PropertyBillsResponse>(url)
    .then(resp => resp.data);
}

export function getPropertyBillsByAddress(
  taxrollId: string,
  address: string
): Promise<PropertyBillsResponse> {
  return getAxios('product')
    .get<PropertyBillsResponse>(
      `${BASE_SERVICE_PATH}${API_PREFIX}/bills/${taxrollId}/find-by-address?address=${address}`
    )
    .then(resp => resp.data);
}

export function determinePropertyImage(property: { address: Address }): Promise<boolean> {
  return new Promise(resolve => {
    if (property.address) {
      Axios.create({ baseURL: 'https://maps.googleapis.com' })
        .get<any>(
          `/maps/api/streetview/metadata?location=${property.address.formattedAddress}&key=${REACT_APP_GOOGLE_STREET_VIEW_API}`
        )
        .then((results: any) => {
          if (results.data.status === 'OK') {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    } else {
      resolve(false);
    }
  });
}
