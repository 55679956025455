import React, { FunctionComponent, useCallback } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router';
import { AccountHistoryByYear } from '../../../global/models/AccountHistory';
import { Product } from '../../../global/models/Product';
import PropertyYearDetails from './propertyYearDetails/PropertyYearDetails';
import YearPropertyList from './yearPropertyList/YearPropertyList';

interface Props {
  products: Product[];
  accountHistoryByYear: AccountHistoryByYear;
}

const PropertyHistory: FunctionComponent<Props> = ({ products, accountHistoryByYear }: Props) => {
  const navigate = useNavigate();
  const year = useParams().year!;

  const handleYearChange = useCallback(selectedYear => {
    navigate(`../${selectedYear}`);
  }, []);

  const handleYearBillNumberChange = useCallback((selectedYear, billNumber) => {
    navigate(`../${selectedYear}/${billNumber}`);
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <YearPropertyList
            currentYear={parseInt(year!)}
            products={products}
            accountHistoryByYear={accountHistoryByYear}
            onYearChange={handleYearChange}
            onPropertySelected={handleYearBillNumberChange}
          />
        }
      />
      <Route
        path="/:billNumber/*"
        element={
          <PropertyYearDetails
            year={year!}
            products={products}
            accountHistory={accountHistoryByYear[year]}
            onYearChange={handleYearBillNumberChange}
          />
        }
      />
    </Routes>
  );
};

export default PropertyHistory;
