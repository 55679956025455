import React from 'react';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';
import { Translate } from '../../../libraries/translate';
import { InlineTranslation } from '../../../libraries/translate/translate';
import './LanguageSelector.css';
import { Language } from '../../models/Languages.enum';

export interface LanguageProps {
  onClose: () => void;
  onChangeLanguage: (lang: Language) => void;
  selectedLang: Language;
  supportedLanguages: Map<Language, string>;
}

const LanguageSelector = ({
  onClose,
  onChangeLanguage,
  selectedLang,
  supportedLanguages,
}: LanguageProps) => (
  <section className="langContainer">
    <div className="languageContents">
      <h3 className="langHeader">
        <span>
          <Translate>Website Language Preference</Translate>
        </span>
        <div className="closeIcon">
          <IconButton aria-label="Close" disableRipple onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </h3>
      <div className="languageGrid">
        {Array.from(supportedLanguages.entries()).map(([code, lang]) => (
          <div
            key={code}
            className={classNames('languageGridElem', selectedLang === lang ? 'selectedLang' : '')}
            onClick={() => {
              onChangeLanguage(code);
              onClose();
            }}
            onKeyDown={event => (event.key === 'Enter' ? onChangeLanguage(code) : '')}
            role="presentation"
          >
            {lang}
          </div>
        ))}
      </div>
    </div>

    <p className="googleDisclaimer">
      <Translate>Powered by</Translate>
      {'\u00A0'}
      <img
        src="/google-translate.png"
        alt={InlineTranslation('google translate logo')}
        height={24}
        width={119}
      />
    </p>
  </section>
);

export default LanguageSelector;
