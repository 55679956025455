import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { Installment, PaymentOption } from '../../../../../global/models/Property';
import DueIcon from '../../../../../global/components/icons/DueIcon';
import toPrettyDate from '../../../../../global/helpers/toPrettyDate';
import {
  BORDER_STYLE,
  COLORS,
  FONT_SANS_SERIF,
  FONT_SERIF,
} from '../../../../../global/constants/styles';
import toPrettyInstallments from '../../../../../global/helpers/toPrettyInstallments';
import toPrettyUSD from '../../../../../global/helpers/toPrettyUSD';
import useButtonStyles from '../../../../../global/hooks/useButtonStyles';
import DisclaimerTag from '../../../../../global/components/DisclaimerTag';
import BREAKPOINTS from '../../../../../global/constants/breakpoints';
import TitleColumn from '../../../../../global/components/Layout/TitleColumn/TitleColumn';
import formatTaxYear from '../../../../../global/helpers/formatTaxYear';
import usePaymentRowStyles from '../../../../../global/hooks/usePaymentRowStyles';

interface Props {
  type: string;
  apn: string;
  option: PaymentOption;
  installments: Installment[];
  taxRollId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    maxWidth: 'initial !important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.3)',
    marginBottom: '24px',
    borderRadius: '3px',

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'block',
    },

    '& .paySection': {
      flexBasis: 395,
      display: 'block',
      margin: 0,
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '16px',

      '& p': {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.3px',
        marginBottom: '16px',
      },
    },

    '&::after': {
      content: '" "',
      position: 'absolute',
      left: '24px',
      top: 0,
      height: '8px',
      width: '40px',
      backgroundColor: COLORS.DEFAULT,
    },

    '& > *:first-child': {
      flexBasis: 200,
    },
  },

  dueDate: {
    flexBasis: 230,
  },

  onetime: {
    // margin: 0,
    // paddingTop: '16px',
    // paddingBottom: '16px'
  },

  multiple: {
    '& .paySection': {
      background: '#F3FBFF',
    },
  },

  label: {
    color: COLORS.BLUE_03,
    fontFamily: FONT_SANS_SERIF,
    fontWeight: 'bold',
    letterSpacing: '0.34px',
    lineHeight: '24px',
  },

  titleCol: (inputs: { minWidth: number }) => ({
    padding: '24px !important',
    margin: '0',
    borderRight: BORDER_STYLE,
    minWidth: 190,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    [theme.breakpoints.down(inputs.minWidth)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '0',
      padding: '24px 16px 16px 16px',
      borderBottom: BORDER_STYLE,
      borderRight: 'none',
    },

    '& > *': {
      marginRight: 24,

      '&:last-child': {
        marginRight: 0,
      },
    },
    '& > * > div, & > * > p': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 8px 0',
      margin: 0,

      [theme.breakpoints.down(inputs.minWidth)]: {
        flexDirection: 'row',
        borderRight: 'none',
      },
    },

    '& h3': {
      color: COLORS.GREY_02,
      fontWeight: 'bold',
      fontFamily: FONT_SERIF,
      fontSize: '20px',
      lineHeight: '25px',
      marginBottom: '8px',
    },

    '& p': {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.3px',
      lineHeight: '20px',
    },
  }),
}));

const PaymentOptionRow: FunctionComponent<Props> = ({
  apn,
  type,
  option,
  installments,
  taxRollId,
}: Props) => {
  const styles = useStyles({ minWidth: 768 });
  const rowStyles = usePaymentRowStyles({ minWidth: 768 });
  const buttonStyles = useButtonStyles();
  const taxYear = 2021;
  const isEstimated = option.schedule?.some(sch => !sch.isVoucherInstallment);
  const dateCopy =
    installments.length > 1
      ? 'First installment due to your county on'
      : 'Installment due to your county on';
  const date = toPrettyDate(installments[0].lastPayDate);

  let typeStyle: string;
  let label: string;

  if (type === 'onetime') {
    typeStyle = styles.onetime;
    label = 'One-time';
  } else {
    typeStyle = styles.multiple;

    if (isEstimated) {
      label = 'Estimated Monthly';
    } else {
      label = 'Monthly';
    }
  }

  function renderPayNow(optionId: string, copy: string, className: string) {
    return (
      <NavLink to={`/property/${taxRollId}/${apn}/${optionId}/add`} tabIndex={-1}>
        <button type="button" className={className}>
          {copy}
        </button>
      </NavLink>
    );
  }

  function renderOneTimePay() {
    let desc;
    let buttonCopy;
    const installmentNumbers = installments
      .filter(i => !i.isPaid && !i.isUnderContract)
      .map(i => i.installmentNumber);
    const installmentCopy = toPrettyInstallments(installmentNumbers);
    const amountCopy = (
      <>
        in the amount of <strong>{toPrettyUSD(option.amount)}</strong> and we&apos;ll take care of
        the rest.
      </>
    );
    if (installmentNumbers.length > 1) {
      desc = `installments ${installmentCopy}`;
      buttonCopy = `Installments ${installmentCopy}`;
    } else {
      desc = `installment ${installmentNumbers[0]}`;
      buttonCopy = `Installment ${installmentNumbers[0]}`;
    }
    return (
      <div className={classnames('paySection')}>
        <span className={styles.label}>Your decide what payment works best for you.</span>
        <p>
          Pay for {desc} {amountCopy}
        </p>
        {renderPayNow(option.optionId, `Pay ${buttonCopy}`, buttonStyles.lightBlue)}
      </div>
    );
  }

  function renderMultiplePay() {
    return (
      <div className={classnames('paySection')}>
        <span className={styles.label}>Put your property taxes on auto-pilot.</span>
        <p>
          <span>
            Pay{' '}
            <strong>
              {toPrettyUSD(option.schedule![0].intAmount)}
              /mth
            </strong>{' '}
            to cover them for the year with ESP.
          </span>
          {isEstimated && <DisclaimerTag tag="1" />}
        </p>
        {renderPayNow(option.optionId, `Enroll in ${type} payments`, buttonStyles.default)}
      </div>
    );
  }

  function renderPaySection() {
    if (type === 'onetime') {
      return renderOneTimePay();
    }
    return renderMultiplePay();
  }

  return (
    <section className={classnames([styles.container, typeStyle])}>
      <TitleColumn
        title={`${label} Payment`}
        secondaryTitle={`${
          option.variant.requiredInstallments.length > 1 ? 'Installments' : 'Installment'
        } ${option.variant.requiredInstallments.join(' and ')}`}
        tertiaryTitle={formatTaxYear(taxYear)}
        className={styles.titleCol}
      />
      <div className={rowStyles.row}>
        {renderPaySection()}
        <div className={styles.dueDate}>
          <p>
            <span aria-label="Installment due">
              <DueIcon />
            </span>
          </p>
          <div>
            <span>{dateCopy}</span>
            <p>{date}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentOptionRow;
