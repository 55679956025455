import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { Theme } from '@material-ui/core';
import { COLUMN_WIDTH } from '../constants/styles';

interface Props {
  flex?: boolean;
  children: React.ReactNode;
  width?: number;
  className?: string | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  column: ({ width, flex }: { flex?: boolean; width?: number }) => ({
    maxWidth: width || COLUMN_WIDTH,
    margin: '40px auto 0 auto',
    display: flex ? 'flex' : 'inherit',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    boxSizing: 'content-box',
    padding: '0 24px',

    [theme.breakpoints.down('xxs')]: {
      '& ul': {
        minWidth: 'auto',
      },
    },
  }),
}));

/**
 * A simple fixed-width column to help standardize centered, fix-width content
 */
const ContentColumn: FunctionComponent<Props> = ({ className, children, flex, width }: Props) => {
  const styles = useStyles({ width, flex });

  return <div className={classnames([styles.column, className])}>{children}</div>;
};

export default ContentColumn;
