import React, { FunctionComponent, useEffect, useState } from 'react';
import classnames from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Installment, TaxBill } from '../../../../../global/models/Property';
import { BORDER_STYLE, COLORS, FONT_SERIF } from '../../../../../global/constants/styles';
import BREAKPOINTS from '../../../../../global/constants/breakpoints';
import TitleColumn from '../../../../../global/components/Layout/TitleColumn/TitleColumn';
import formatTaxYear from '../../../../../global/helpers/formatTaxYear';
import { Payment, PaymentContract } from '../../../../../global/models/PaymentContract';
import OrderStatus from '../../../../../global/components/OrderStatus';
import PaymentSummaryColumn from './PaymentSummaryColumn';
import getBillDate from '../../../helpers/getBillDateFromPayment';
import { InlineTranslation } from '../../../../../libraries/translate/translate';

interface Props {
  payment: Payment;
  taxBill: TaxBill;
  label?: string;
  paymentContract: PaymentContract;
  disclaimer?: React.ReactNode;
  showTitle?: boolean;
  showStatus?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: (inputs: { minWidth: number; showTitle: boolean }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    maxWidth: 'initial !important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.3)',
    borderRadius: '3px',

    '&::after': {
      content: '" "',
      position: 'absolute',
      left: '24px',
      top: 0,
      height: '8px',
      width: '40px',
      backgroundColor: COLORS.DEFAULT,
    },

    '&:after': !inputs.showTitle
      ? {
          display: 'none',
        }
      : {},
    [theme.breakpoints.down(inputs.minWidth)]: {
      border: BORDER_STYLE,
      boxShadow: `rgba(0, 0, 0, 0.3) 0px 1px 4px 0px`,
      display: 'block',
    },
  }),

  leftContainer: {
    width: '100%',
  },

  disclaimers: {
    margin: '16px 0',
    padding: '0 24px',
  },

  receipt: {
    display: 'block',
    borderTop: BORDER_STYLE,
    padding: '16px 24px',
    width: '100%',

    '& > p': {
      display: 'none',
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'flex',
      flexDirection: 'row',
      border: 'none',
      padding: 0,
      margin: 0,

      '& > p': {
        display: 'block !important',
        width: 96,
      },

      '& > div': {
        padding: '16px 24px 16px 0',
        borderTop: `${BORDER_STYLE} !important`,
        width: '100%',
      },
    },
  },

  titleCol: (inputs: { minWidth: number }) => ({
    padding: '24px !important',
    margin: '0',
    borderRight: BORDER_STYLE,
    minWidth: 190,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    [theme.breakpoints.down(inputs.minWidth)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '0',
      padding: '24px 16px 16px 16px',
      borderBottom: BORDER_STYLE,
      borderRight: 'none',
    },

    '& > *': {
      marginRight: 24,

      '&:last-child': {
        marginRight: 0,
      },
    },
    '& > * > div, & > * > p': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 8px 0',
      margin: 0,

      [theme.breakpoints.down(inputs.minWidth)]: {
        flexDirection: 'row',
        borderRight: 'none',
      },
    },

    '& h3': {
      color: COLORS.GREY_02,
      fontWeight: 'bold',
      fontFamily: FONT_SERIF,
      fontSize: '20px',
      lineHeight: '25px',
      marginBottom: '8px',
    },

    '& p': {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.3px',
      lineHeight: '20px',
    },
  }),
}));

const SinglePaymentDetails: FunctionComponent<Props> = ({
  disclaimer,
  payment,
  taxBill,
  paymentContract,
  label = 'One-time',
  showTitle = true,
  showStatus = true,
}: Props) => {
  const styles = useStyles({ minWidth: 768, showTitle });
  const [installments, setInstallments] = useState<Installment[]>();
  const [secondaryTitle, setSecondaryTitle] = useState<string>();

  const date = getBillDate(payment);

  useEffect(() => {
    if (paymentContract && taxBill) {
      setInstallments(
        taxBill.installments.filter(
          installment =>
            paymentContract.installmentContracts.findIndex(
              i => i.installmentNumber === installment.installmentNumber
            ) > -1
        )
      );

      setSecondaryTitle(
        `${
          paymentContract.installmentContracts.length > 1 ? 'Installments' : 'Installment'
        } ${paymentContract.installmentContracts
          .sort((a, b) => a.installmentNumber - b.installmentNumber)
          .map(i => i.installmentNumber)
          .join(' and ')}`
      );
    }
  }, [paymentContract && taxBill]);

  function renderOrderStatus() {
    if (paymentContract.status === 'FAILURE') {
      return <OrderStatus type="ERROR" />;
    }

    if (paymentContract.status === 'CANCELED') {
      return <OrderStatus type="INFO" status={paymentContract.status} />;
    }

    return '';
  }

  return (
    <div className="single-payment">
      <section className={classnames([styles.container])}>
        {showTitle && (
          <TitleColumn
            title={InlineTranslation(`${label} Payment`)}
            secondaryTitle={secondaryTitle}
            tertiaryTitle={formatTaxYear(taxBill.taxYear)}
            className={styles.titleCol}
          />
        )}
        <div className={styles.leftContainer}>
          {showStatus && renderOrderStatus()}
          <PaymentSummaryColumn
            amount={payment.total}
            dueDate={date}
            installments={installments}
            status={paymentContract.status}
            receiptId={paymentContract.receiptId}
          />
          {/* <div className={styles.receipt}>
            <p>&nbsp;</p>
            <div>
              <label>Receipt ID </label>
              <span>{order.order.receiptId}</span>
            </div>
          </div> */}
        </div>
      </section>
      {disclaimer && <div className={styles.disclaimers}>{disclaimer}</div>}
    </div>
  );
};

export default SinglePaymentDetails;
