import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { COLORS } from '../../../../../global/constants/styles';
import toPrettyDate from '../../../../../global/helpers/toPrettyDate';
import toPrettyUSD from '../../../../../global/helpers/toPrettyUSD';
import PaymentScheduledIcon from '../../../../../global/components/icons/PaymentScheduledIcon';
import FailedIcon from '../../../../../global/components/icons/FailedIcon';
import PendingIcon from '../../../../../global/components/icons/PendingIcon';
import CanceledIcon from '../../../../../global/components/icons/CanceledIcon';
import DisclaimerTag from '../../../../../global/components/DisclaimerTag';
import ReceiptIcon from '../../../../../global/components/icons/ReceiptIcon';
import { Payment, PaymentContract } from '../../../../../global/models/PaymentContract';
import usePaymentRowStyles from '../../../../../global/hooks/usePaymentRowStyles';
import getBillDate from '../../../helpers/getBillDateFromPayment';
import isValidPaymentContractStatus from '../../../helpers/isValidPaymentContractStatus';
import { Translate } from '../../../../../libraries/translate';

interface Props {
  payment: Payment;
  paymentContract: PaymentContract;
}

interface PaymentState {
  amountLabel: string;
  dateLabel: string;
  rowStyle: 'pendingRow' | 'failedRow' | 'scheduledRow' | 'canceledRow' | 'successRow';
  amountIcon: any;
  dateIcon: any;
}
const STATUS_STATES: { [id: string]: PaymentState } = {
  PENDING: {
    amountLabel: 'Payment pending',
    dateLabel: 'Payment date',
    rowStyle: 'pendingRow',
    amountIcon: <PendingIcon />,
    dateIcon: <PendingIcon />,
  },
  FAILED: {
    amountLabel: 'Payment failed',
    dateLabel: 'Payment date due',
    rowStyle: 'failedRow',
    amountIcon: <FailedIcon />,
    dateIcon: <PaymentScheduledIcon />,
  },
  SCHEDULED: {
    amountLabel: 'Payment amount',
    dateLabel: 'Payment date due',
    rowStyle: 'scheduledRow',
    amountIcon: <AttachMoneyIcon />,
    dateIcon: <PaymentScheduledIcon />,
  },
  CANCELED: {
    amountLabel: 'Payment canceled',
    dateLabel: 'Payment date due',
    rowStyle: 'canceledRow',
    amountIcon: <CanceledIcon />,
    dateIcon: <CanceledIcon />,
  },
  SUCCESS: {
    amountLabel: 'Amount paid',
    dateLabel: 'Payment date',
    rowStyle: 'successRow',
    amountIcon: <AttachMoneyIcon />,
    dateIcon: <CheckIcon />,
  },
};

const useStyles = makeStyles({
  money: {
    width: '.7em',
    height: '.7em',
  },

  pendingRow: {
    '& td:last-child': {
      fontWeight: 'bold',
    },
  },

  successRow: {},
  scheduledRow: {},
  canceledRow: {},
  failedRow: {
    '& p': {
      color: COLORS.RED_01,
    },
  },
});

const ScheduledPaymentDetails: FunctionComponent<Props> = ({ payment, paymentContract }: Props) => {
  const styles = useStyles({ minWidth: 768 });
  const rowStyles = usePaymentRowStyles({ minWidth: 768 });
  const date = getBillDate(payment);
  const billedAmount = toPrettyUSD(payment.total);

  let state: PaymentState;

  if (payment.status === 'SUCCESS') {
    state = STATUS_STATES.SUCCESS;
  } else if (!payment.orderId && paymentContract.status === 'CANCELED') {
    state = STATUS_STATES.CANCELED;
  } else if (!payment.status) {
    state = STATUS_STATES.paymentD;
  } else if (!isValidPaymentContractStatus(payment.status)) {
    state = STATUS_STATES.FAILED;
  } else {
    state = STATUS_STATES.PENDING;
  }

  return (
    <section className={styles[state.rowStyle]}>
      <div className={rowStyles.row}>
        <div>
          <p className="amount">
            <span aria-label={state.amountLabel}>{state.amountIcon}</span>
          </p>
          <div>
            <span>
              <Translate>{state.amountLabel}</Translate>
            </span>
            <p>
              {billedAmount} {!payment?.voucherInstallmentId && <DisclaimerTag tag="1" />}
            </p>
          </div>
        </div>
        <div>
          <p>
            <span aria-label={state.dateLabel}>{state.dateIcon}</span>
          </p>
          <div>
            <span>
              <Translate>{state.dateLabel}</Translate>
            </span>
            <p>{toPrettyDate(date)}</p>
          </div>
        </div>
        {payment.receiptId && (
          <div>
            <p>
              <span aria-label="Receipt ID">
                <ReceiptIcon />
              </span>
            </p>
            <div>
              <span>Receipt ID</span>
              <p>{payment.receiptId}</p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ScheduledPaymentDetails;
