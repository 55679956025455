import { Language } from '../models/Languages.enum';
import { SubmitProfileData, UpdateProfileData } from '../models/SubmitProfileData';
import { API_PREFIX, getAxios } from './espAxios';

const BASE_SERVICE_PATH = '/profile';
/**
 * User login
 */
export function requestLogin(email: string, password: string): Promise<APIResponseProfile> {
  return getAxios('auth')
    .post<APIResponseProfile>(`${BASE_SERVICE_PATH}${API_PREFIX}/login`, { email, password })
    .then(resp => resp.data);
}

/**
 * Profile update
 */
export function requestProfile(): Promise<APIResponseProfile> {
  return getAxios('auth')
    .get<APIResponseProfile>(`${BASE_SERVICE_PATH}${API_PREFIX}/profile`)
    .then(resp => resp.data);
}

/**
 * User logout
 */
export function requestLogout(): Promise<undefined> {
  return getAxios('auth').post(`${BASE_SERVICE_PATH}${API_PREFIX}/logout`);
}

/**
 * Profile update
 */
export function updateProfile(profile: UpdateProfileData): Promise<APIResponseProfile> {
  return getAxios('auth')
    .patch<APIResponseProfile>(`${BASE_SERVICE_PATH}${API_PREFIX}/profile`, profile)
    .then(resp => resp.data);
}

/**
 * Profile update
 */
export function createProfile(profile: SubmitProfileData): Promise<APIResponseProfile> {
  return getAxios('auth')
    .post<APIResponseProfile>(`${BASE_SERVICE_PATH}${API_PREFIX}/profile`, profile)
    .then(resp => resp.data);
}

export type APIResponseProfile = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phone?: string;
  language: Language;
};
