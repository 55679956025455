// Width of content column
export const COLUMN_WIDTH = 960;

// Not ideal, but make sure this matches `font-size` specified in `src/index.css`. This constant
// here is used for em calculations in JS
export const FONT_SIZE_BASIS_PX = 16;
export const FONT_SANS_SERIF = '"Lato", sans-serif';
export const FONT_SERIF = '"Merriweather", serif';
export const BASE_FONT = FONT_SANS_SERIF;
export const COLORS = {
  BLUE_O1: 'rgba(243,251,255,1.0)',
  BLUE_03: 'rgba(52,172,224,1.0)',
  BLUE_05: 'rgba(34,112,147,1.0)',
  ORANGE_01: 'rgba(205, 97, 51,1.0)',
  GREEN_01: 'rgba(40, 140, 116,1.0)',
  RED_01: 'rgba(255,82,82,1.0)',
  PINK_01: 'rgba(255,243,243,1.0)',
  DEFAULT: 'rgba(34,34,34,1)',
  BORDER: '#DDDDDD',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  SUB_HEADER: '#9E9E9E',
  GREY: '#666666',
  GREY_02: '#222222',
};

export const BORDER_COLOR = COLORS.BORDER;
export const BORDER_STYLE = `1px solid ${BORDER_COLOR}`;
