import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { Theme, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CardContainer from './Layout/CardContainer/CardContainer';
import { COLORS, BORDER_STYLE } from '../constants/styles';
import toPrettyUSD from '../helpers/toPrettyUSD';
import pxToEms from '../helpers/pxToEms';
import { TaxBill } from '../models/Property';
import { Address } from '../models/Address';
import { Product } from '../models/Product';
import formatTaxYear from '../helpers/formatTaxYear';
import BREAKPOINTS from '../constants/breakpoints';
import { Translate } from '../../libraries/translate';

interface Props {
  property: {
    taxBill?: TaxBill;
    address?: Address;
    product?: Product;
  };
  onNext?: (() => void) | undefined;
  onPrevious?: (() => void) | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  billSummary: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: 1250,
    width: 'calc(100% - 16px)',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.3)',

    '& > *': {
      padding: `${pxToEms(16)} 0`,
    },
  },

  contentCardContainer: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: 0,
    width: '100%',

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      display: 'block',
      '&.show-nav': {
        marginBottom: 66,
        borderBottom: BORDER_STYLE,
        paddingBottom: '48px',
      },
    },
  },

  content: {
    position: 'relative',
  },

  duration: {
    textTransform: 'capitalize',
  },

  navButton: {
    padding: '27px 24px',
    width: '87px',
    cursor: 'pointer',
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    border: 'none',
    background: 'none',

    [theme.breakpoints.down(768)]: {
      position: 'absolute',
      padding: '16px',
      bottom: 0,
      flexDirection: 'row',
      marginBottom: '0 !important',
    },

    [theme.breakpoints.down(430)]: {
      width: '50%',
    },
  },

  navButtonDisabled: {
    cursor: 'not-allowed',
  },

  navNextButton: {
    paddingRight: 28,
    paddingLeft: 14,
    borderLeft: BORDER_STYLE,
    right: 0,

    '& svg': {
      transform: 'rotate(180deg)',
    },
  },

  navPreviousButton: {
    paddingRight: 0,
    paddingLeft: 30,

    borderRight: BORDER_STYLE,
    left: 0,

    [theme.breakpoints.down(430)]: {
      borderRight: 'none',
    },
  },

  summaryLeft: {
    padding: `16px 0 16px 0`,
    width: '-webkit-fill-available',
    margin: '0 24px -1px 24px',
    flex: 1,

    borderBottom: BORDER_STYLE,

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      margin: '0 16px -0 16px',
      border: 'none',
      paddingBottom: 0,

      '& h2': {
        fontWeight: 'bold',
      },

      '& h4': {
        fontWeight: 'bold',
      },
    },

    '& div': {
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textTransform: 'capitalize',

      '&::after': {
        content: '" "',
        position: 'absolute',
        left: 16,
        top: 0,
        height: '8px',
        width: '40px',
        backgroundColor: COLORS.DEFAULT,
      },
    },

    '& p.no-address': {
      textTransform: 'none',
      '&::after': {
        backgroundColor: COLORS.ORANGE_01,
      },
      '& span': {
        color: '#CD6133',
        opacity: 0.45,
      },
    },

    '& h2, & h4': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },

  summaryRight: {
    width: 'fit-content',
    whiteSpace: 'nowrap',
    padding: `16px 0`,
    margin: `0 ${pxToEms(24)}`,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      margin: `0 ${pxToEms(16)}`,
      width: 'auto',
    },

    '& .field-info-label': {
      textTransform: 'capitalize',
    },

    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',

      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        display: 'block',
      },

      '& li': {
        display: 'inline-block',

        padding: `0 ${pxToEms(24)}`,

        '&:not(:first-child)': {
          borderLeft: `${pxToEms(1)} solid #DDD`,
        },

        '&:first-child': {
          paddingLeft: 0,

          '& .field-info': {
            color: 'rgba(28,111,149,1)',
            fontSize: '20px',
            fontWeight: 'bold',
            lineHeight: '26px',
            letterSpacing: '0.43px',
          },
        },

        '& .field-info-label': {
          fontSize: pxToEms(14),
          fontStyle: 'italic',
          letterSpacing: 0.3,
          lineHeight: '20px',
          display: 'block',
          marginBottom: 4,

          [theme.breakpoints.down(BREAKPOINTS.MD)]: {
            lineHeight: '17px',
          },

          [theme.breakpoints.down(BREAKPOINTS.SM)]: {
            fontSize: pxToEms(14),
            lineHeight: '20px',
          },
        },

        '& .field-info-label--total': {
          marginBottom: 0,
        },

        '& .field-info': {
          fontSize: pxToEms(16),
          letterSpacing: 0.34,
          lineHeight: '22px',
          display: 'block',
          color: COLORS.GREY,
        },

        [theme.breakpoints.down('xs')]: {
          display: 'block',
          width: '100%',
          padding: `8px 0`,
          borderLeft: 'none !important',

          '&:first-child': {
            borderTop: `${pxToEms(1)} solid #DDD`,
            borderBottom: `${pxToEms(1)} solid #DDD`,
            marginBottom: 8,
          },

          '& .field-info-label': {
            display: 'inline-block',
          },

          '& .field-info': {
            float: 'right',
          },
        },
      },
    },
  },
  annualTaxAmount: {
    color: '#1C6F95',
    fontSize: pxToEms(20),
    fontWeight: 'bold',
  },
}));

const PropertySummaryBar: FunctionComponent<Props> = ({ property, onNext, onPrevious }: Props) => {
  const showNav = onPrevious || onNext;
  const hasPrevious = !!onPrevious;
  const hasNext = !!onNext;
  const styles = useStyles();
  const { taxBill } = property;

  let billTotal = '--';
  let billBalance = '--';
  let billPeriod = '--';
  if (taxBill) {
    const amount = taxBill.isEstimate ? taxBill.estimateAmount : taxBill.amount;
    billTotal = toPrettyUSD(amount);
    billBalance = toPrettyUSD(amount - taxBill.amountPaid);
    billPeriod = formatTaxYear(taxBill.taxYear);
  }

  let address1 = '--';
  let address2 = <>--</>;

  function renderCityState(addr: Address) {
    return (
      <>
        {addr.city ? `${addr.city.toLowerCase()},` : ''} {addr.state} {addr.zipCode}
      </>
    );
  }

  if (property.address) {
    address1 = property.address.fullAddress.toLowerCase();
    address2 = <>{renderCityState(property.address)}</>;
  }

  function renderAddress() {
    return (
      <div>
        <Typography variant="h2">{address1}</Typography>
        <Typography variant="h4">{address2}</Typography>
      </div>
    );
  }

  function renderTotalHeader() {
    if (taxBill?.isEstimate) {
      return 'Estimated tax bill*';
    }
    if (property.product?.billing) {
      return property.product.billing.period.duration;
    }
    return 'Annual';
  }

  function renderNavButton(type: 'next' | 'previous', callback: any) {
    let appliedStyles = styles.navButton;
    const hasBehavior = type === 'next' ? hasNext : hasPrevious;
    if (!hasBehavior) {
      appliedStyles = `${appliedStyles} ${styles.navButtonDisabled}`;
    }

    appliedStyles = `${appliedStyles} ${
      type === 'next' ? styles.navNextButton : styles.navPreviousButton
    }`;
    return (
      <button type="button" onClick={callback} className={appliedStyles} aria-label={type}>
        {type === 'next' && (
          <ArrowBackIosIcon fontSize="large" color={hasBehavior ? 'inherit' : 'disabled'} />
        )}
        {type === 'previous' && (
          <ArrowBackIosIcon fontSize="large" color={hasBehavior ? 'inherit' : 'disabled'} />
        )}
      </button>
    );
  }

  function handlePrevious(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();

    if (onPrevious) {
      onPrevious();
    }
  }

  function handleNext(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();

    if (onNext) {
      onNext();
    }
  }

  const navStyle = showNav ? 'show-nav' : '';
  return (
    <CardContainer className={styles.billSummary} showBar={false}>
      {showNav && renderNavButton('previous', handlePrevious)}
      <div className={classnames(styles.contentCardContainer, navStyle)}>
        <section className={styles.summaryLeft}>
          {property.address && renderAddress()}
          {!property.address && (
            <div className="no-address">
              <Typography variant="h2">
                <Translate>Address unavailable</Translate>
              </Typography>
              <Typography variant="h4">
                <Translate>Address unavailable</Translate>
              </Typography>
            </div>
          )}
        </section>
        <section className={styles.summaryRight}>
          <ul>
            <li>
              <span
                className={classnames(
                  'field-info-label',
                  'field-info-label--total',
                  styles.duration
                )}
              >
                <Translate>{renderTotalHeader()}</Translate>
              </span>
              <span className={classnames('field-info', styles.annualTaxAmount)}>{billTotal}</span>
            </li>
            <li>
              <span className="field-info-label">
                <Translate>Tax year</Translate>
              </span>
              <span className="field-info">{billPeriod}</span>
            </li>
            <li>
              <span className="field-info-label">
                <Translate>Tax bill balance</Translate>
              </span>
              <span className="field-info">{billBalance}</span>
            </li>
            <li>
              <span className="field-info-label">
                <Translate>Total tax bill</Translate>
              </span>
              <span className="field-info">{billTotal}</span>
            </li>
          </ul>
        </section>
      </div>
      {showNav && renderNavButton('next', handleNext)}
    </CardContainer>
  );
};

export default PropertySummaryBar;
