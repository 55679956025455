import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import imgPlaceholder from '../../images/default-image.jpg';
import { FONT_SANS_SERIF } from '../constants/styles';

interface Props {
  address?: string;
  className?: string;
  hideBackground?: boolean;
}

const useStyles = makeStyles({
  container: ({ hideBackground }: any) => ({
    position: 'relative',
    height: '100%',
    margin: 'auto',
    '&::after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
      background: hideBackground
        ? 'none'
        : 'linear-gradient(90deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 51.56%, rgba(0,0,0,0.85) 100%)',
    },
  }),

  banner: ({ url }: any) => ({
    backgroundImage: `url('${url}')`,
    backgroundPosition: `center`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: '0.1875rem 0.1875rem 0 0',
  }),

  disclaimer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '60px',
    width: '652px',
    padding: '8px 24px',
    backgroundColor: 'rgba(255,255,255,0.26)',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.15)',
    color: '#FFFFFF',
    fontFamily: FONT_SANS_SERIF,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.3px',
    lineHeight: '20px',
    textAlign: 'right',
    zIndex: 100,

    '& a': {
      color: '#FFFFFF',
      textDecoration: 'none',
      fontStyle: 'italic',
    },
  },
});

/**
 * A simple component to display a cover photo of a house. Defaults to displaying a placeholder
 * if no image URL is provided
 */
const PropertyBanner: FunctionComponent<Props> = ({
  address,
  className,
  hideBackground,
}: Props) => {
  let url = imgPlaceholder;
  if (address) {
    url = `https://maps.googleapis.com/maps/api/streetview?size=1440x600&location=${address}&fov=120&key=AIzaSyCxfn1aCVDrlZ-YTOE_nZOCbUXoz2_DM1E`;
  }
  const styles = useStyles({ url, hideBackground });

  return (
    <div className={className}>
      <div className={styles.container}>
        <div className={styles.banner} />
      </div>
    </div>
  );
};

export default PropertyBanner;
