import { makeStyles, Snackbar } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React, { createContext, useContext, useState } from 'react';
import { COLORS } from '../constants/styles';

interface SnackbarMessageContextType {
  setMessage: (message: string, icon?: 'success') => void;
}

interface SnackbarState {
  message: string;
  icon: 'success';
  timeToLive: number;
}

const useStyles = makeStyles({
  snackbar: {
    background: COLORS.WHITE,
    color: '#227193',
    padding: '12px 24px',
    borderRadius: '50px',
    display: 'flex',
    boxShadow: `0 2px 4px 0 ${COLORS.GREY}`,
    '& > p': {
      margin: 0,
    },
    '& > div': {
      display: 'flex',
      borderRadius: '100%',
      border: '2px solid #227193',
      marginRight: '8px',
      '& > div': {
        padding: '4px',
        borderRadius: '100%',
        border: '2px solid #3BADDF',
        height: '24px',
        width: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > svg': {
          height: '20px',
          width: '20px',
        },
      },
    },
  },
});

export const SnackbarMessageContext = createContext<SnackbarMessageContextType>(null!);

export function SnackbarMessageProvider({ children }: { children: React.ReactNode }) {
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarState>({
    message: '',
    icon: 'success',
    timeToLive: 5000,
  });

  const styles = useStyles();

  const handleClose = () => {
    setSnackbarMessage({ ...snackbarMessage, message: '' });
  };

  const setMessage = (
    message: string,
    icon: 'success' = 'success',
    autoClose: boolean = true,
    timeToLive: number = 5000
  ) => {
    setSnackbarMessage({ message, icon, timeToLive });
    if (autoClose) {
      setTimeout(() => {
        setSnackbarMessage({ ...snackbarMessage, message: '' });
      }, timeToLive);
    }
  };

  const value = {
    setMessage,
  };

  function getMessageIcon() {
    switch (snackbarMessage.icon) {
      // add new icons here
      case 'success':
      default:
        return <CheckIcon />;
    }
  }

  return (
    <SnackbarMessageContext.Provider value={value}>
      {children}
      <Snackbar
        open={!!snackbarMessage.message}
        autoHideDuration={snackbarMessage.timeToLive}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div className={styles.snackbar}>
          <div>
            <div>{getMessageIcon()}</div>
          </div>
          <p>{snackbarMessage.message}</p>
        </div>
      </Snackbar>
    </SnackbarMessageContext.Provider>
  );
}

export function useSnackbarMessage() {
  return useContext(SnackbarMessageContext);
}
