import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const PaymentScheduledIcon: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <polygon points="0 0 22 0 22 22 0 22" />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M18.3333333,2.75 L17.4166667,2.75 L17.4166667,0.916666667 L15.5833333,0.916666667 L15.5833333,2.75 L6.41666667,2.75 L6.41666667,0.916666667 L4.58333333,0.916666667 L4.58333333,2.75 L3.66666667,2.75 C2.65833333,2.75 1.83333333,3.575 1.83333333,4.58333333 L1.83333333,19.25 C1.83333333,20.2583333 2.65833333,21.0833333 3.66666667,21.0833333 L18.3333333,21.0833333 C19.3416667,21.0833333 20.1666667,20.2583333 20.1666667,19.25 L20.1666667,4.58333333 C20.1666667,3.575 19.3416667,2.75 18.3333333,2.75 Z M18.3333333,4.58333333 L18.3333333,7.33333333 L3.66666667,7.33333333 L3.66666667,4.58333333 L18.3333333,4.58333333 Z M3.66666667,19.25 L3.66666667,9.16666667 L18.3333333,9.16666667 L18.3333333,19.25 L3.66666667,19.25 Z"
      />
      <polygon
        fill="#34ACE0"
        fillRule="nonzero"
        points="3.667 4.593 18.333 4.593 18.333 7.333 3.667 7.333"
      />
    </g>
  </SvgIcon>
);

export default PaymentScheduledIcon;
