/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Theme } from '@material-ui/core';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { InfoOutlined } from '@material-ui/icons';
import classNames from 'classnames';
import CardContainer from '../../../../../../global/components/Layout/CardContainer/CardContainer';
import TitleColumn from '../../../../../../global/components/Layout/TitleColumn/TitleColumn';
import ContentColumn from '../../../../../../global/components/Layout/ContentColumn/ContentColumn';
import ReconciledIcon from '../../../../../../global/components/icons/ReconciledIcon';
import formatTaxYear from '../../../../../../global/helpers/formatTaxYear';
import toPrettyUSD from '../../../../../../global/helpers/toPrettyUSD';
import BREAKPOINTS from '../../../../../../global/constants/breakpoints';
import { BORDER_STYLE, COLORS, FONT_SERIF } from '../../../../../../global/constants/styles';
import { FastFactGraphData, FastFactHeader, FastFactsProps } from './models/FastFact';
import InfoBox from '../../../../../../global/components/InfoBox/InfoBox';
import { Translate } from '../../../../../../libraries/translate';

function getHeaderData(
  state: string,
  countyName: string,
  dataSet: string | undefined
): FastFactHeader | undefined {
  switch (state) {
    // add new states under new cases
    case 'CA':
      // add new universal data here, i.e population or demographics
      switch (dataSet) {
        case 'tax':
          return {
            header: 'County Tax Distribution',
            subHeader: `Net property tax collected in ${countyName} County*`,
            source: {
              link: 'https://www.counties.org/post/datapile',
              title: 'California County Datapile',
            },
            disclaimers: ['1% of Net taxable assessed value'],
            footer: `Net Property Tax collected-(${countyName} County)`,
            taxYear: 2018,
          };
        case 'race':
          return {
            header: 'County Race Demographics',
            subHeader: ``,
            source: {
              link: 'https://www.counties.org/post/datapile',
              title: 'California County Datapile',
            },
            disclaimers: ['Values are an estimate and not exact'],
            footer: `Total Population-(${countyName} County)`,
            taxYear: 2019,
          };
        case 'collected':
          return {
            header: 'County Race Demographics',
            subHeader: ``,
            source: {
              link: 'https://www.counties.org/post/datapile',
              title: 'California County Datapile',
            },
            disclaimers: ['Values are an estimate and not exact'],
            footer: `Total Population-(${countyName} County)`,
            taxYear: 2019,
          };
        default:
          return undefined;
      }
    default:
      return undefined;
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  smallTitle: {
    lineHeight: '10px !important',
    fontWeight: 'bold !important',
    fontStyle: 'italic',
    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'inline-block',
    },
  },
  source: {
    display: 'block !important',
    bottom: '24px',
    maxWidth: 175,

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      position: 'initial',
      marginBottom: 0,
      paddingBottom: 16,
      marginTop: '0 !important',
      maxWidth: '100%',
    },

    '& p': {
      margin: 0,
      fontFamily: 'Lato',
      fontSize: '12px !important',
      letterSpacing: '0.26px',
      lineHeight: '16px',
      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        marginRight: 4,
      },
      '&:last-child': {
        color: '#666666',
        fontStyle: 'italic',
        marginTop: 8,
        [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
          paddingBottom: 16,
        },
      },
    },
    '& a': {
      margin: 0,
      color: '#34ACE0',
      fontFamily: 'Lato',
      fontSize: '12px',
      fontStyle: 'italic',
      letterSpacing: '0.26px',
      lineHeight: '16px',
      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        lineHeight: '20px',
      },
    },
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    alignItems: 'center',
    padding: '16px 0',

    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'block !important',
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'block',
      padding: 0,
      marginBottom: 16,
      '&:last-child': {
        marginBottom: 0,
      },
      '&:first-child': {
        marginTop: 24,
      },
    },
  },
  graph: {
    gridColumn: 'span 2 / span 2',
    fontFamily: 'Lato',
    fontSize: '16px',
    letterSpacing: '0.34px',
    lineHeight: '24px',
    '& p': {
      marginTop: 4,
      marginBottom: 0,
      '&:last-child': {
        color: COLORS.SUB_HEADER,
        marginLeft: 4,
        fontWeight: 'normal',
      },
    },
  },
  taxAmount: {
    fontFamily: 'Lato',
    fontSize: '16px',
    letterSpacing: '0.34px',
    lineHeight: '24px',
    textAlign: 'right',
    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      textAlign: 'left',
    },
    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      textAlign: 'left',
      marginTop: 4,
    },
  },
  graphBar: {
    height: '100%',
    animation: `$expandRight 2s ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes expandRight': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: '100%',
    },
  },
  contentColumnHeaderWrapper: {
    width: '100%',
    borderBottom: `1px solid ${COLORS.BORDER}`,
    padding: '16px 24px 16px 24px',
  },
  preFooter: {
    borderTop: `1px dashed ${COLORS.BORDER}`,
    padding: '16px 24px 16px 24px',
  },
  netTaxWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    alignItems: 'center',
    color: COLORS.GREY_02,
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.34px',
    lineHeight: '24px',

    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'block',
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      padding: 0,
    },
  },
  netTax: {
    gridColumn: 'span 2 / span 2',
    display: 'flex',
    justifyContent: 'flex-start',

    '& p': {
      margin: '0 0 0 16px',
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      marginBottom: 8,
      '& p': {
        margin: '0 0 0 24px',
      },
      '& svg': {
        marginLeft: 16,
      },
    },
  },
  netTaxMessageDesktop: {
    display: 'block',
    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'none',
    },
  },
  netTaxMessageMobile: {
    display: 'none',
    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      display: 'block',
    },
  },
  totalTax: {
    textAlign: 'right',
    fontSize: 20,
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    [theme.breakpoints.down(BREAKPOINTS.MD)]: {
      textAlign: 'left',
      marginLeft: 'calc(1em + 20px)',
      marginTop: 8,
    },

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      // the icon has a width of 1em
      // the icon also has margin left of 16px
      // the p tag has margin left of 24px
      marginLeft: 'calc(1em + 40px)',
    },
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 325,
  },
  container: {
    marginBottom: '50px',
    display: 'inherit',
    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      display: 'block !important',
      marginBottom: 0,
    },
  },
  steps: {
    paddingTop: '65px !important',
  },
  titleColumn: (inputs: { minWidth: number }) => ({
    padding: '24px',
    margin: 0,
    borderRight: BORDER_STYLE,
    minWidth: 190,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',

    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      display: 'block',
      margin: 0,
      padding: 0,
      borderBottom: BORDER_STYLE,
      borderRight: 'none',
    },

    '& > *': {
      '&:last-child': {
        marginRight: 0,
      },
    },
    '& > * > div, & > * > p': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0 8px 0',
      margin: 0,

      [theme.breakpoints.down(inputs.minWidth)]: {
        flexDirection: 'row',
        borderRight: 'none',
      },
    },

    '& hr': {
      width: '40px',
      height: '1px',
      backgroundColor: COLORS.BORDER,
      margin: '16px 0',
      borderWidth: 0,
    },

    '& h3': {
      color: COLORS.GREY_02,
      fontWeight: 'bold',
      fontFamily: FONT_SERIF,
      fontSize: '20px',
      lineHeight: '25px',
      marginBottom: '8px',
    },

    '& p': {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.3px',
      lineHeight: '20px',
    },
  }),
  percentLabel: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  },
}));

function FastFacts({ county, type, steps, error }: FastFactsProps) {
  const [dataKeys, setDataKeys] = useState<string[]>([]);
  const [data, setData] = useState<FastFactGraphData[]>();
  const [total, setTotal] = useState<number>(0);

  const styles = useStyles({ minWidth: 768 });
  // the first four values here are what Chris provided in the design
  // the others are ones I made up
  const colors: string[] = [
    '#CD6133',
    '#34ACE0',
    '#FFDA79',
    '#227093',
    '#33CD61',
    '#6133CD',
    '#EB3B14',
    '#E034AC',
    '#ACE034',
    '#79FFDA',
    '#DA79FF',
  ];

  useEffect(() => {
    setDataKeys(
      Object.keys(county || {}).filter(
        (x: string) =>
          !['id', 'taxRollId', 'total', 'netTaxableValue', 'countyName', 'state'].includes(x)
      )
    );
    setTotal(county?.total || 0);
  }, [county, type]);

  useEffect(() => {
    setData(
      dataKeys.map((key: string, index: number) => ({
        percentage: county ? (county[key] as number) : 0,
        hexCode: colors[index],
        label: key.split(/(?=[A-Z])/).join(' '),
      }))
    );
  }, [dataKeys]);

  function calculateTotal(): number {
    return (
      dataKeys
        .map((key: string) => (county ? (county[key] as number) : 0))
        //  the total value here is the net taxable assessed value of all property in the county
        //  1% of that value is collected as tax revenue
        .reduce((prevVal: number, currVal: number) => prevVal + total * (currVal || 1), 0)
    );
  }

  function getHeaderDisplayedValue(): string {
    const xTotal = calculateTotal();
    switch (type) {
      case 'tax':
        return toPrettyUSD(xTotal * 0.0001);
      default:
        return Math.ceil(xTotal).toLocaleString('en-US');
    }
  }

  function getPreFooterDisplayedValue(): string {
    const xTotal = calculateTotal();

    switch (type) {
      case 'tax':
        return toPrettyUSD(xTotal * 0.0001);
      default:
        return Math.ceil(xTotal).toLocaleString('en-US');
    }
  }

  function getFooterDisplayedValue(): string {
    const xTotal = calculateTotal();

    switch (type) {
      case 'tax':
        return toPrettyUSD(xTotal * 0.0001);
      default:
        return Math.ceil(xTotal).toLocaleString('en-US');
    }
  }

  function getPreFooterTextIcon() {
    return <InfoOutlined />;
  }

  function getFooterIcon() {
    switch (type) {
      case 'tax':
        return <ReconciledIcon />;
      case 'race':
      default:
        return <PeopleAltIcon />;
    }
  }

  function getContentHeaderIcon() {
    return <InfoOutlined />;
  }

  function getFieldValue(percentage: number): string | JSX.Element {
    switch (type) {
      case 'tax':
        return toPrettyUSD(percentage * total * 0.0001);
      case 'race':
      default:
        return (
          <>
            {Math.floor(percentage * total).toLocaleString('en-US')}
            <span style={{ color: '#666666' }}>{' *'}</span>
          </>
        );
    }
  }

  const headerData = getHeaderData(county?.state || '', county?.countyName || '', type);

  const footerText = headerData?.footer?.split('-');

  const preFooterText = headerData?.annotation?.split('-');

  const contentColumnHeaderText = headerData?.contentColumnHeader?.split('-');

  if (!county || !data) {
    return (
      <CardContainer className={styles.container}>
        <ContentColumn>
          <div className={styles.loading}>
            {error && (
              <InfoBox type="error" header="Oops, there was a problem">
                <Translate>
                  Sorry, we are unable to load county fast fact information at this time
                </Translate>
              </InfoBox>
            )}
            {!error && <CircularProgress />}
          </div>
        </ContentColumn>
      </CardContainer>
    );
  }

  return (
    <CardContainer className={styles.container}>
      <TitleColumn
        title={<Translate>{headerData?.header}</Translate>}
        secondaryTitle={<Translate>{headerData?.subHeader}</Translate>}
        tertiaryTitle={formatTaxYear(headerData?.taxYear || 2018)}
        className={classNames([styles.titleColumn, steps ? styles.steps : null])}
        miniDivider={false}
      >
        <div className={styles.source}>
          <hr />
          <p className={styles.smallTitle}>
            <Translate>Source:</Translate>
          </p>
          <a
            href={headerData?.source.link || 'https://www.counties.org/post/datapile'}
            target="_blank"
            rel="noreferrer"
          >
            <Translate>{headerData?.source.title || 'California County Datapile'}</Translate>
          </a>
          {headerData?.disclaimers?.map((disclaimer: string, index: number) => (
            <p key={disclaimer}>
              {'*'.repeat(index + 1)}
              <Translate>{disclaimer}</Translate>
            </p>
          ))}
        </div>
      </TitleColumn>
      <ContentColumn
        minWidth={635}
        header={
          !!headerData?.contentColumnHeader && (
            <div className={styles.contentColumnHeaderWrapper}>
              <div className={styles.netTaxWrapper}>
                <div className={styles.netTax}>
                  {getContentHeaderIcon()}
                  {/* on desktop, this text is all on one line */}
                  {/* on mobile, this text splits to a new line */}
                  <div className={styles.netTaxMessageDesktop}>
                    <p>
                      <Translate>{contentColumnHeaderText?.join(' ')}</Translate>
                    </p>
                  </div>
                  <div className={styles.netTaxMessageMobile}>
                    {contentColumnHeaderText?.map((text: string) => (
                      <p key={text}>
                        <Translate>{text}</Translate>
                      </p>
                    ))}
                  </div>
                </div>
                <div className={styles.totalTax}>{getHeaderDisplayedValue()}</div>
              </div>
            </div>
          )
        }
        preFooter={
          !!headerData?.annotation && (
            <div className={classNames([styles.netTaxWrapper, styles.preFooter])}>
              <div className={styles.netTax}>
                {getPreFooterTextIcon()}
                {/* on desktop, this text is all on one line */}
                {/* on mobile, this text splits to a new line */}
                <div className={styles.netTaxMessageDesktop}>
                  <p>
                    <Translate>{preFooterText?.join(' ')}</Translate>
                  </p>
                </div>
                <div className={styles.netTaxMessageMobile}>
                  {preFooterText?.map((text: string) => (
                    <p key={text}>
                      <Translate>{text}</Translate>
                    </p>
                  ))}
                </div>
              </div>
              <div className={styles.totalTax}>{getPreFooterDisplayedValue()}</div>
            </div>
          )
        }
        footer={
          <div className={styles.netTaxWrapper}>
            <div className={styles.netTax}>
              {getFooterIcon()}
              {/* on desktop, this text is all on one line */}
              {/* on mobile, this text splits to a new line */}
              <div className={styles.netTaxMessageDesktop}>
                <p>
                  <Translate>{footerText?.join(' ')}</Translate>
                </p>
              </div>
              <div className={styles.netTaxMessageMobile}>
                {footerText?.map((text: string) => (
                  <p key={text}>
                    <Translate>{text}</Translate>
                  </p>
                ))}
              </div>
            </div>
            <div className={styles.totalTax}>{getFooterDisplayedValue()}</div>
          </div>
        }
      >
        {data?.map(field => (
          <div className={styles.wrapper} key={field.label}>
            <div className={styles.graph}>
              <div style={{ width: `${field.percentage}%`, height: '3px' }}>
                <div className={styles.graphBar} style={{ background: field.hexCode }} />
              </div>
              <div className={styles.percentLabel}>
                <p>
                  <Translate>{field.label}</Translate>{' '}
                </p>
                <p>{`${field.percentage}%`}</p>
              </div>
            </div>
            <div className={styles.taxAmount}>{getFieldValue(field.percentage)}</div>
          </div>
        ))}
      </ContentColumn>
    </CardContainer>
  );
}

export default FastFacts;
