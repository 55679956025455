import { Button, makeStyles, Theme } from '@material-ui/core';
import classnames from 'classnames';

import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import DueIcon from '../../../../../global/components/icons/DueIcon';
import PaymentScheduledIcon from '../../../../../global/components/icons/PaymentScheduledIcon';
import ReconciledIcon from '../../../../../global/components/icons/ReconciledIcon';
import InfoBox from '../../../../../global/components/InfoBox/InfoBox';
import PropertyCard from '../../../../../global/components/PropertyCard/PropertyCard';
import { BORDER_STYLE, COLORS, FONT_SANS_SERIF } from '../../../../../global/constants/styles';
import formatTaxYear from '../../../../../global/helpers/formatTaxYear';
import pxToEms from '../../../../../global/helpers/pxToEms';
import parseCurrentYearPropertyToCard from '../../../helpers/parseCurrentYearPropertyToCard';
import { CurrentYearProperty } from '../../../models/currentYearProperty';
import useButtonStyles from '../../../../../global/hooks/useButtonStyles';
import { Translate } from '../../../../../libraries/translate';

interface Props {
  currentYear: number;
  currentYearProperties: CurrentYearProperty[];
  onPropertySelect: (billNumber: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cardList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    maxWidth: 960,
    alignItems: 'baseline',

    [theme.breakpoints.down(864)]: {
      justifyContent: 'center',
    },
  },

  singleCardList: {
    justifyContent: 'center !important',
  },

  infoColTitle: {
    color: COLORS.GREY_02,
    fontFamily: FONT_SANS_SERIF,
    fontSize: pxToEms(14),
    fontStyle: 'italic',
    letterSpacing: pxToEms(0.3),
    lineHeight: pxToEms(17),
    flex: 1,
  },
  infoColSubTitle: {
    color: '#666666',
    fontFamily: FONT_SANS_SERIF,
    fontSize: pxToEms(16),
    letterSpacing: pxToEms(0.34),
    lineHeight: pxToEms(26),
  },
  paymentStatus: {
    padding: '16px 8px',
    margin: '0 16px',
    borderTop: BORDER_STYLE,
    fontSize: '14px',
    fontFamily: FONT_SANS_SERIF,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row' as 'row',
    lineHeight: '24px',
  },
  svg: {
    marginTop: '2px',
    marginRight: '8px',
  },
}));

const PropertyList: FunctionComponent<Props> = ({
  currentYear,
  currentYearProperties,
  onPropertySelect,
}: Props) => {
  const styles = useStyles();
  const listStyles = [styles.cardList];
  const buttonStyles = useButtonStyles();
  // Repsonsibilities
  // Get Tax Bill
  // Render Header and bar
  // Render Available Years for APN
  // Route to sub pages

  if (currentYearProperties.length > 0) {
    return (
      <div className={classnames(listStyles)}>
        {currentYearProperties.map(currentYearProperty => {
          const cardData = parseCurrentYearPropertyToCard(currentYearProperty);

          function renderStatusIcon() {
            switch (cardData.status) {
              case 'PAID':
                return <ReconciledIcon />;
              case 'INSUFFICIENT':
                return <DueIcon />;
              case 'SCHEDULED':
                return <PaymentScheduledIcon />;
              default:
                return '';
            }
          }
          return (
            <PropertyCard
              width={392}
              cardData={cardData}
              onPropertySelect={onPropertySelect}
              year={currentYearProperty.taxYear}
              key={currentYearProperty.billNumber}
              rows={[
                {
                  label: cardData.billNumberLabel,
                  value: cardData.billNumber,
                },
                {
                  label: 'Billing period',
                  value: cardData.taxYear,
                },
                {
                  label: cardData.abbreviation,
                  value: cardData.apn,
                },
              ].map(row => (
                <>
                  <span className={styles.infoColTitle}>
                    {row.label !== 'APN' ? <Translate>{row.label}</Translate> : row.label}
                  </span>
                  <span className={styles.infoColSubTitle}>{row.value}</span>
                </>
              ))}
              rowDisplay="block"
              header={
                <section className={styles.paymentStatus}>
                  {renderStatusIcon()}
                  <Translate>{cardData.statusText}</Translate>
                </section>
              }
            />
          );
        })}
      </div>
    );
  }
  const noPropertiesText = `We are sorry, no properties were found for the tax year ${formatTaxYear(
    currentYear
  )}`;
  return (
    <InfoBox header={`${formatTaxYear(currentYear)}`}>
      <p>
        <Translate>{noPropertiesText}</Translate>
      </p>
      <br />

      <NavLink to="/">
        <Button className={buttonStyles.default}>
          <Translate>Find My Bill</Translate>
        </Button>
      </NavLink>
    </InfoBox>
  );
};

export default PropertyList;
