/* eslint-disable react/destructuring-assignment */
import { makeStyles, Theme } from '@material-ui/core';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import InfoBox from '../../global/components/InfoBox/InfoBox';
import Loading from '../../global/components/Loading';
import OrderSuccess from '../../global/components/OrderSuccess';
import ScrollToTop from '../../global/components/ScrollToTop';
import OrderHistoryContext from '../../global/contexts/OrderHistoryContext';
import ProductsContext from '../../global/contexts/ProductsContext';
import getUrlParams from '../../global/helpers/getUrlParams';
import { AccountHistoryByYear } from '../../global/models/AccountHistory';
import { OrderViaParams } from '../../global/models/Order';
import { Product } from '../../global/models/Product';
import { Errors } from '../../global/models/ResponseError';
import { PaymentContract } from '../../global/models/PaymentContract';
import getCurrentTaxYear from './getCurrentTaxYear';
import PropertyHistory from './propertyHistory/PropertyHistory';
import { Translate } from '../../libraries/translate';

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    marginTop: 40,
  },

  errorContainer: {
    maxWidth: 1250,
    marginBottom: 72,
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginRight: 16,
      marginLeft: 16,
    },
  },

  orderSuccess: {
    maxWidth: '768px',
    margin: '40px auto 40px auto',
    [theme.breakpoints.down(768)]: {
      margin: '40px 16px 40px 16px',
    },
  },
}));

const Account: FunctionComponent = () => {
  const styles = useStyles();
  const orderHistoryContext = useContext(OrderHistoryContext);
  const productContext = useContext(ProductsContext);
  const [orderViaParams, setOrderViaParams] = useState<OrderViaParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>();
  const [products, setProducts] = useState<Product[]>();
  const [accountHistoryByYear, setAccountHistoryByYear] = useState<AccountHistoryByYear>();

  useEffect(() => {
    const params = getUrlParams();
    if (!!params.billNumber && !!orderHistoryContext.state.accountHistoryByAPN) {
      const { accountHistoryByAPN } = orderHistoryContext.state;
      const billNumbers = Object.keys(accountHistoryByAPN);
      const { billNumber } = params;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < billNumbers.length; i++) {
        const x = billNumbers[i];

        if (x === billNumber) {
          const matchedValue = accountHistoryByAPN[x];
          const { paymentContracts } = matchedValue.taxYears[getCurrentTaxYear()];

          if (!paymentContracts.length) {
            return;
          }

          const { amount, receiptId, schedule } = paymentContracts.sort(
            (a: PaymentContract, b: PaymentContract) =>
              Date.parse(b.createdAt) - Date.parse(a.createdAt)
          )[0];

          const amountPaid = schedule.find(y => y.isInitialPayment)!.total;

          setOrderViaParams({
            apn: matchedValue.apn,
            billNumber: matchedValue.billNumber,
            taxrollId: matchedValue.taxRollId,
            receiptId,
            amount,
            amountPaid,
          });
          break;
        }
      }
    }
  }, [window.location, orderHistoryContext.state]);

  useEffect(() => {
    setLoaded(orderHistoryContext.state.loaded && !!productContext.state.products);
    setLoading(orderHistoryContext.state.loading || productContext.state.loading);
  }, [orderHistoryContext.state, productContext.state]);

  useEffect(() => {
    setErrors(orderHistoryContext.state.errors);
  }, [orderHistoryContext.state.errors]);

  useEffect(() => {
    if (!loading && !loaded) {
      orderHistoryContext.methods.loadOrders();
      productContext.methods?.getProducts();
    }

    if (loaded) {
      setAccountHistoryByYear(orderHistoryContext.state.accountHistoryByYear);
      setProducts(productContext.state.products);
    }
  }, [loading, loaded]);

  function renderOrderSummary() {
    if (orderViaParams) {
      return (
        <section className={styles.orderSuccess}>
          <OrderSuccess orderViaParams={orderViaParams} />
        </section>
      );
    }

    return <></>;
  }

  function renderLoading() {
    if (loading) {
      return <Loading className={styles.loading} label="Loading Account Details" />;
    }

    return <></>;
  }

  function renderError() {
    if (errors) {
      return (
        <div className={styles.errorContainer}>
          <InfoBox type="error" header="Oops, there was a problem">
            <Translate>{errors.message}</Translate>
          </InfoBox>
        </div>
      );
    }

    return <></>;
  }

  return (
    <ScrollToTop matchIndex={3}>
      {renderOrderSummary()}
      {renderLoading()}
      {renderError()}

      {products && accountHistoryByYear && (
        <Routes>
          <Route path="/" element={<Navigate to={`${getCurrentTaxYear()}`} />} />
          <Route
            path="/:year/*"
            element={
              <PropertyHistory products={products} accountHistoryByYear={accountHistoryByYear} />
            }
          />
        </Routes>
      )}
    </ScrollToTop>
  );
};

export default Account;
