import { makeStyles } from '@material-ui/core';
import { COLORS, FONT_SANS_SERIF } from '../constants/styles';

const BUTTON_STYLE_BASE = {
  padding: '12px 16px',
  borderRadius: '3px',
  fontFamily: FONT_SANS_SERIF,
  fontWeight: 'bold',
  lineHeight: '24px',
  letterSpacing: 0.34,
  border: 'none',
  cursor: 'pointer',
  '&:disabled': {
    pointerEvents: 'none',
  },
} as any;

const useButtonStyles = makeStyles({
  lightBlue: {
    ...BUTTON_STYLE_BASE,

    backgroundColor: '#F3FBFF',
    color: '#227093',

    '&:hover, &:active, &:focus': {
      backgroundColor: 'rgba(52,172,224,1)',
      color: COLORS.WHITE,
    },
  },

  default: {
    ...BUTTON_STYLE_BASE,

    backgroundColor: COLORS.BLUE_03,
    color: COLORS.WHITE,

    '&:hover, &:active, &:focus': {
      backgroundColor: 'rgba(34, 112, 147, 1.0)',
      color: COLORS.WHITE,
    },
  },

  white: {
    ...BUTTON_STYLE_BASE,

    backgroundColor: COLORS.WHITE,
    color: COLORS.GREY,

    '&:hover, &:active, &:focus': {
      backgroundColor: COLORS.GREY,
      color: COLORS.WHITE,
    },
  },

  red: {
    ...BUTTON_STYLE_BASE,

    backgroundColor: COLORS.WHITE,
    color: COLORS.RED_01,

    '&:hover, &:active, &:focus': {
      backgroundColor: COLORS.RED_01,
      color: COLORS.WHITE,
    },
  },
});

export default useButtonStyles;
