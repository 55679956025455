import React, { useReducer, FunctionComponent } from 'react';

import { BaseState } from '../models/BaseState';
import { ResponseError } from '../models/ResponseError';
import { PropertyBill } from '../models/Property';
import {
  getPropertyBillByBillNumber,
  determinePropertyImage,
  PropertyBillResponse,
} from '../services/property';
import formatTaxBill from './helpers/formatTaxBill';

// State
interface PropertiesState extends BaseState {
  properties: { [id: string]: PropertyBill };
}

const defaultState: PropertiesState = { loading: false, properties: {} };

// Context
type ContextProps = {
  state: PropertiesState;
  dispatch?: {};
  selectors?: {
    selectProperty: (apn: string, billNumber: string, today?: string) => PropertyBill;
  };
  methods?: {
    getProperty: (
      taxrollId: string,
      apn: string,
      billNumber: string,
      includeEstimate?: boolean,
      today?: string
    ) => Promise<void>;
    clearError: () => void;
  };
};

const PropertyBillContext = React.createContext<ContextProps>({
  state: defaultState,
});

// Reducer & Actions
type Action = {
  type: ACTION;
  payload?: PropertyBill | ResponseError;
};

enum ACTION {
  GET_PROPERTY = 'actionGetProperty',
  GET_PROPERTY_SUCCESS = 'actionGetPropertySuccess',
  GET_PROPERTY_FAILURE = 'actionGetPropertyFailure',
  CLEAR_ERRORS = 'actionClearErrors',
}

function PropertyBillReducer(state: PropertiesState, action: Action): PropertiesState {
  switch (action.type) {
    case ACTION.GET_PROPERTY:
      return {
        ...state,
        loading: true,
        errors: undefined,
      };
    case ACTION.GET_PROPERTY_SUCCESS: {
      const property = action.payload as PropertyBill;
      const properties = { ...state.properties };
      const { today } = property;
      properties[
        `${property.apn}-${property.billNumber}-${today.month}/${today.day}/${today.year}`
      ] = property;
      return {
        ...state,
        properties,
        errors: undefined,
        loading: false,
      };
    }
    case ACTION.GET_PROPERTY_FAILURE:
      return {
        ...state,
        loading: false,
        errors: (action.payload as ResponseError).errors,
      };
    case ACTION.CLEAR_ERRORS:
      return {
        ...state,
        errors: undefined,
      };
    default:
      return state;
  }
}
// Business Methods

async function submitGetProperty(
  dispatch: React.Dispatch<Action>,
  taxrollId: string,
  apn: string,
  billNumber: string,
  includeEstimate?: boolean,
  today?: string
) {
  dispatch({ type: ACTION.GET_PROPERTY });
  getPropertyBillByBillNumber(taxrollId, apn, billNumber, includeEstimate, today)
    .then((response: PropertyBillResponse) => {
      if (response.taxBill) {
        response.taxBill = formatTaxBill(response.taxBill);
      }

      determinePropertyImage(response)
        .then(hasImage => {
          response.hasImage = hasImage;
        })
        .catch(() => {
          response.hasImage = false;
        })
        .finally(() => {
          dispatch({
            type: ACTION.GET_PROPERTY_SUCCESS,
            payload: response,
          });
        });
    })
    .catch(() => {
      dispatch({
        type: ACTION.GET_PROPERTY_FAILURE,
        payload: {
          errors: {
            message: "Unable to find your property's tax bill details",
          },
        },
      });
    });
}

function selectProperty(
  state: PropertiesState,
  apn: string,
  billNumber: string,
  today?: string
): PropertyBill {
  return state?.properties[`${apn}-${billNumber}-${today}`];
}

// Provider
export const PropertyBillProvider: FunctionComponent = props => {
  const [state, dispatch] = useReducer(PropertyBillReducer, defaultState);
  return (
    <PropertyBillContext.Provider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={{
        state,
        dispatch: {},
        selectors: {
          selectProperty: (apn: string, billNumber: string, today?: string) =>
            selectProperty(state, apn, billNumber, today),
        },
        methods: {
          getProperty: (
            taxrollId: string,
            apn,
            billNumber,
            includeEstimate?: boolean,
            today?: string
          ) => submitGetProperty(dispatch, taxrollId, apn, billNumber, includeEstimate, today),
          clearError: () => dispatch({ type: ACTION.CLEAR_ERRORS }),
        },
      }}
    />
  );
};

export default PropertyBillContext;
