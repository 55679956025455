/**
 * Converts a list of numbers into proper structured comma separated copy
 */
export default function toPrettyInstallments(installments: number[]): string {
  if (installments.length === 0) {
    return '';
  }

  if (installments.length === 1) {
    return `${installments[0]}`;
  }

  if (installments.length === 2) {
    return installments.join(' and ');
  }

  let copy: string = '';
  installments.forEach((installment, index) => {
    if (index === 0) {
      copy = `${installment}`;
    } else if (index === installments.length - 1) {
      copy += `, and ${installment}`;
    } else {
      copy += `, ${installment}`;
    }
  });

  return copy;
}
