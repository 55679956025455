import React, { FunctionComponent, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Route, Navigate, Routes, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import Header from './global/components/Header/Header';
import Footer from './global/components/Footer/Footer';
import ProtectedRoute from './global/components/ProtectedRoute';
import AppWrapper from './global/components/AppWrapper';
import { ROUTE } from './global/constants';
import AuthContext from './global/contexts/AuthContext';
import Account from './pages/account/Account';
import { REACT_APP_WEB_URL } from './global/helpers/constants';
import Reroute from './global/components/Reroute';
import { Translator } from './libraries/translate';
import languages, { Language } from './global/models/Languages.enum';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    zero: true;
    xxs: true;
    xs: true; // removes the `xs` breakpoint
    ssm: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flex: 1,
    paddingBottom: 40,
    position: 'relative',
  },
});

enum AppState {
  BOOTED = 'booted',
  INITIALIZING = 'initializing',
  READY = 'ready',
}

const App: FunctionComponent = () => {
  const [appState, setAppState] = useState<AppState>(AppState.BOOTED);
  const [currentRootPath, setCurrentRootPath] = useState<string | undefined>('');
  const styles = useStyles();
  const { state, methods } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [language, setLanguage] = useState<Language>(Language.en);

  const changeLanguage = (newLang: Language) => {
    setLanguage(newLang);

    if (methods && state.userData?.language && state.userData?.language !== newLang) {
      methods.updateProfile({ language: newLang });
    }
  };

  useEffect(() => {
    const userLanguage = localStorage.getItem('lang') as Language | null;

    if (userLanguage && languages.has(userLanguage) && userLanguage !== Language.en) {
      setLanguage(userLanguage);
    }
  }, []);

  useEffect(() => {
    if (state.userData?.language) {
      setLanguage(state.userData.language);
    }
  }, [state.userData?.language]);

  useEffect(() => {
    if (state.isLoggedIn && pathname.indexOf('/account') === -1) {
      navigate(ROUTE.ACCOUNT);
    }
  }, [state.isLoggedIn]);
  /**
   * Perform various initializations on app boot
   */
  useEffect(() => {
    if (!methods) {
      return;
    }

    if (appState !== AppState.BOOTED) {
      return;
    }

    setAppState(AppState.INITIALIZING);

    // Request the user's profile, then display the app
    methods
      .initAuth()
      .catch(async () => {
        await methods.logOut();
        // There was an error, probably when retrieving the user's profile (expired session, etc...)
        // Take the user to an unprotected route
        // navigate(ROUTE.HOME);
      })
      .finally(() => {
        // Good or bad, the app is done booting up
        setAppState(AppState.READY);
      });
  }, [appState, methods, navigate]);

  useLayoutEffect(() => {
    const rootPath = pathname.split('/')[1];

    if (rootPath !== currentRootPath) {
      window.scrollTo(0, 0);
      setCurrentRootPath(rootPath);
    }
  }, [currentRootPath, pathname]);

  const appReady = appState === AppState.READY;

  return (
    <AppWrapper>
      <Translator to={language} from="en" googleApiKey="AIzaSyBC5rGY7QiBxcZCRdFPXC2xx9WPeuCRPP0">
        <div className={styles.container}>
          <Helmet defaultTitle="Easy Smart Pay™" titleTemplate="Easy Smart Pay™ - %s" />
          {appReady && (
            <>
              <Header
                state={state}
                methods={methods}
                onChangeLanguage={changeLanguage}
                supportedLanguages={languages}
                selectedLang={languages.get(language) as Language}
              />
              <main className={styles.content}>
                <Routes>
                  {/* Public Routes */}
                  <Route
                    path={ROUTE.SIGN_IN}
                    element={<Navigate to={`${REACT_APP_WEB_URL}/sign-in`} replace />}
                  />
                  <Route
                    path={ROUTE.CREATE_ACCOUNT}
                    element={<Navigate to={`${REACT_APP_WEB_URL}/create-account`} replace />}
                  />
                  <Route
                    path={`${ROUTE.FORGOT_PASSWORD}/*`}
                    element={<Navigate to={`${REACT_APP_WEB_URL}/forgot-password`} replace />}
                  />
                  <Route
                    path={`${ROUTE.FIND_MY_BILL}/*`}
                    element={<Reroute route={`${REACT_APP_WEB_URL}/`} />}
                  />
                  <Route
                    path={`${ROUTE.PROPERTY_DETAILS}/:taxrollId/:apn/*`}
                    element={<Reroute route={`${REACT_APP_WEB_URL}/`} />}
                  />

                  {/* Protected Routes */}
                  <Route
                    path={`${ROUTE.PROFILE}/*`}
                    element={
                      <ProtectedRoute>
                        <Reroute route={`${REACT_APP_WEB_URL}/${ROUTE.PROFILE}`} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={`${ROUTE.ACCOUNT}/*`}
                    element={
                      <ProtectedRoute>
                        <Account />
                      </ProtectedRoute>
                    }
                  />

                  {/* When there's no match, send the user to the marketing app */}
                  <Route path="*" element={<Reroute route={`${REACT_APP_WEB_URL}/`} />} />
                </Routes>
              </main>
              <Footer />
            </>
          )}
        </div>
      </Translator>
    </AppWrapper>
  );
};

export default App;
