import { FastFact } from '../models/Facts/FastFact';
import { API_PREFIX, getAxios } from './espAxios';
import { TaxFact } from '../models/Facts/TaxFact';
import { DemographicFact } from '../models/Facts/DemographicFact';

export function loadFacts(county: string): Promise<TaxFact> {
  return getAxios('product')
    .get<FastFact>(`/product${API_PREFIX}/fast-facts/${county}`)
    .then((response: any) => response.data);
}

export function loadDemographics(county: string): Promise<DemographicFact> {
  return getAxios('product')
    .get<DemographicFact>(`/product${API_PREFIX}/fast-facts/${county}/demographics`)
    .then((response: any) => response.data);
}
