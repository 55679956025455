import { BillingInfo } from '../models/BillingInfo';
import { PaymentMethod } from '../models/PaymentMethod';
import { API_PREFIX, getAxios } from './espAxios';

const SERVICE_NAME = 'order';
const BASE_SERVICE_PATH = `/${SERVICE_NAME}`;

export function loadPaymentMethods(): Promise<PaymentMethod[]> {
  return getAxios(SERVICE_NAME)
    .get<PaymentMethod[]>(`${BASE_SERVICE_PATH}${API_PREFIX}/payment-methods`)
    .then(resp => resp.data);
}

export function createPaymentMethod(
  paymentMethodNonce: string,
  billingDetails: BillingInfo,
  paymentTypeId: 'ACH' | 'Credit',
  isDefault: boolean
): Promise<PaymentMethod> {
  return getAxios(SERVICE_NAME)
    .post<PaymentMethod>(`${BASE_SERVICE_PATH}${API_PREFIX}/payment-methods`, {
      paymentMethodNonce,
      billingDetails,
      isDefault,
      paymentTypeId,
    })
    .then(resp => resp.data);
}

export function deletePaymentMethod(token: string): Promise<void> {
  return getAxios(SERVICE_NAME)
    .delete<void>(`${BASE_SERVICE_PATH}${API_PREFIX}/payment-methods/${token}`)
    .then(resp => resp.data);
}

export function updatePaymentMethod(
  token: string,
  // the api will return an error if all fields are empty
  update: { billingDetails?: Partial<BillingInfo>; isDefault?: boolean; updateNonce?: string }
): Promise<PaymentMethod> {
  return getAxios(SERVICE_NAME)
    .patch<PaymentMethod>(`${BASE_SERVICE_PATH}${API_PREFIX}/payment-methods/${token}`, update)
    .then(resp => resp.data);
}
