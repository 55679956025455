import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import classnames from 'classnames';
import BREAKPOINTS from '../../../constants/breakpoints';
import { COLORS } from '../../../constants/styles';

export interface CardContainerProps {
  children: React.ReactNode;
  showBar?: boolean;
  minWidth?: number;
  className?: string;
}

const CardContainer: FunctionComponent<CardContainerProps> = ({
  children,
  showBar = true,
  minWidth,
  className,
}: CardContainerProps) => {
  const useStyles = makeStyles((theme: Theme) => ({
    cardContainer: {
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.3)',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      borderRadius: '3px',

      '& span': {
        fontWeight: 'bold',
      },

      '&.showBar': {
        '&::after': {
          content: '" "',
          position: 'absolute',
          left: '24px',
          top: 0,
          height: '8px',
          width: '40px',
          backgroundColor: COLORS.DEFAULT,

          [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
            left: '16px',
          },
        },
      },

      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        display: 'block',
        padding: '0px',
        margin: '0 auto',

        '& > *:first-child': {
          marginBottom: 16,
        },
      },

      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        display: 'block',
      },
    },
  }));
  const styles = useStyles({ ...(minWidth && { minWidth }) });

  return (
    <section className={classnames([styles.cardContainer, showBar ? 'showBar' : '', className])}>
      {children}
    </section>
  );
};

export default CardContainer;
