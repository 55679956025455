import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS, BASE_FONT } from '../constants/styles';

interface Props {
  tag: string;
  showLink?: boolean;
}

const useStyles = makeStyles({
  disclaimer: {
    color: COLORS.ORANGE_01,
    fontFamily: BASE_FONT,
    fontSize: '8px',
    fontWeight: 'bold',
    letterSpacing: '0.3px',
    lineHeight: '20px',
    textDecoration: 'none',
    top: '-1em',
  },
});

const DisclaimerTag: FunctionComponent<Props> = (props: Props) => {
  const { tag, showLink } = props;
  const styles = useStyles();

  function renderTag() {
    if (showLink === false) {
      return tag;
    }
    return (
      <a className={styles.disclaimer} href={`#disclaimer-${tag}`}>
        {tag}
      </a>
    );
  }

  return <sup className={styles.disclaimer}>{renderTag()}</sup>;
};

export default DisclaimerTag;
