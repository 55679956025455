import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { IconButton, Modal } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { CloseOutlined, Search } from '@material-ui/icons';
import { AuthState } from '../../contexts/AuthContext';
import { ROUTE } from '../../constants';
import { REACT_APP_WEB_URL } from '../../helpers/constants';
import LogoIcon from '../icons/LogoIcon';
import YogaIcon from '../icons/YogaIcon';
import { SubmitProfileData } from '../../models/SubmitProfileData';
import { Translate } from '../../../libraries/translate';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import AccountIcon from '../icons/AccountIcon';
import { InlineTranslation } from '../../../libraries/translate/translate';
import LanguageIcon from '../icons/LanguageIcon';
import { Language } from '../../models/Languages.enum';

export interface HeaderProps {
  state: AuthState;
  methods:
    | {
        initAuth: () => Promise<void>;
        logIn: (email: string, password: string) => Promise<void>;
        logOut: () => Promise<void>;
        updateProfile: (profile: SubmitProfileData) => Promise<void>;
        createProfile: (profile: SubmitProfileData) => Promise<void>;
        clearErrors: () => void;
      }
    | undefined;
  onChangeLanguage: (a: Language) => void;
  selectedLang: Language;
  supportedLanguages: Map<Language, string>;
}

const Header = ({
  state,
  methods,
  onChangeLanguage,
  selectedLang,
  supportedLanguages,
}: HeaderProps) => {
  const { pathname } = useLocation();
  const [languageSelect, setLanguageSelect] = useState(false);
  const [navOpen, setNavOpen] = useState<boolean>(false);
  const navOpenRef = useRef<HTMLInputElement>(null);

  const handleLanguageModalOpen = () => setLanguageSelect(true);
  const handleLanguageModalClose = () => setLanguageSelect(false);
  const handleClick = () => {
    document.getElementById('header')?.classList.add('open');
  };
  const handleClose = () => {
    document.getElementById('header')?.classList.remove('open');
  };
  const handleCloseNav = () => {
    setNavOpen(false);
  };

  useEffect(() => {
    handleClose();
  }, []);

  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (navOpen && navOpenRef.current && !navOpenRef.current.contains(event.target as Node)) {
        handleCloseNav();
      }
    };

    const escapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCloseNav();
      }
    };

    document.addEventListener('click', clickOutside);
    document.addEventListener('keydown', escapeKey);
    return () => {
      document.removeEventListener('click', clickOutside);
      document.removeEventListener('keydown', escapeKey);
    };
  }, [navOpen]);

  const translateCommonLanguage = {
    account: InlineTranslation('Account'),
    accountOverview: InlineTranslation('Account Overview'),
    profile: InlineTranslation('Profile'),
    signOut: InlineTranslation('Sign Out'),
    signIn: InlineTranslation('Sign In'),
    language: InlineTranslation('Language'),
  };

  function renderWideNav() {
    return (
      <section className="wide-nav">
        <ul>
          <li>
            <div className="navDropdown" ref={navOpenRef}>
              <button
                className={classnames('navDropdownButton', navOpen && 'navDropdownActive')}
                onClick={() => {
                  setNavOpen(!navOpen);
                }}
                type="button"
              >
                <span>
                  <AccountIcon />
                </span>
                <p>{translateCommonLanguage.account}</p>
              </button>
              {navOpen && !state.isLoggedIn && (
                <div className="navDropdownItems">
                  <a href={`${REACT_APP_WEB_URL}/auth/sign-in`}>
                    <span
                      onClick={handleCloseNav}
                      onKeyDown={handleCloseNav}
                      role="button"
                      tabIndex={0}
                    >
                      {translateCommonLanguage.signIn}{' '}
                    </span>
                  </a>
                  <span
                    onClick={handleLanguageModalOpen}
                    onKeyDown={handleLanguageModalOpen}
                    className={languageSelect ? 'languageSelectorActive' : ''}
                    role="button"
                    tabIndex={0}
                  >
                    <button className="languageButton" type="button">
                      <LanguageIcon />
                      <span
                        onClick={handleCloseNav}
                        onKeyDown={handleCloseNav}
                        role="button"
                        tabIndex={0}
                      >
                        <p>{selectedLang}</p>
                        <p>{translateCommonLanguage.language}</p>
                      </span>
                    </button>
                  </span>
                </div>
              )}
              {navOpen && state.isLoggedIn && (
                <div className="navDropdownItems">
                  <NavLink
                    to={ROUTE.ACCOUNT}
                    className={
                      pathname.indexOf('/account') === -1 ? 'rightInactiveNav' : 'rightActiveNav'
                    }
                  >
                    <span
                      onClick={handleCloseNav}
                      onKeyDown={handleCloseNav}
                      role="button"
                      tabIndex={0}
                    >
                      {translateCommonLanguage.accountOverview}
                    </span>
                  </NavLink>
                  <a
                    href={`${REACT_APP_WEB_URL}${ROUTE.PROFILE}`}
                    className={
                      pathname.indexOf('/profile') === -1 ? 'rightInactiveNav' : 'rightActiveNav'
                    }
                  >
                    <span
                      onClick={handleCloseNav}
                      onKeyDown={handleCloseNav}
                      role="button"
                      tabIndex={0}
                    >
                      {translateCommonLanguage.profile}
                    </span>
                  </a>
                  <span
                    role="button"
                    data-testid="wide-menu-signOut"
                    tabIndex={0}
                    className="rightInactiveNav"
                    onClick={() => {
                      methods?.logOut();
                      handleCloseNav();
                    }}
                    onKeyDown={() => {
                      methods?.logOut();
                      handleCloseNav();
                    }}
                  >
                    <span
                      onClick={() => {
                        methods?.logOut();
                        handleCloseNav();
                      }}
                      onKeyDown={() => {
                        methods?.logOut();
                        handleCloseNav();
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      {translateCommonLanguage.signOut}
                    </span>
                  </span>
                  <span
                    onClick={() => {
                      handleLanguageModalOpen();
                      handleCloseNav();
                    }}
                    onKeyDown={handleLanguageModalOpen}
                    className={languageSelect ? 'languageSelectorActive' : ''}
                    role="button"
                    tabIndex={0}
                  >
                    <button className="languageButton" type="button">
                      <LanguageIcon />
                      <span
                        onClick={handleCloseNav}
                        onKeyDown={handleCloseNav}
                        role="button"
                        tabIndex={0}
                      >
                        <p>{selectedLang}</p>
                        <p>{translateCommonLanguage.language}</p>
                      </span>
                    </button>
                  </span>
                </div>
              )}
              <Modal open={languageSelect} onClose={handleLanguageModalClose} className="langModal">
                <LanguageSelector
                  onClose={handleLanguageModalClose}
                  onChangeLanguage={onChangeLanguage}
                  selectedLang={selectedLang}
                  supportedLanguages={supportedLanguages}
                />
              </Modal>
            </div>
          </li>
        </ul>
      </section>
    );
  }

  function renderNarrowNav() {
    return (
      <>
        <section id="narrowNav" className="narrow-nav">
          <IconButton
            aria-label="account options"
            aria-controls="menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
        </section>
      </>
    );
  }

  function renderMobileMenu() {
    return (
      <section className="mobile-menu" role="dialog">
        <div className="background" />
        <div className="inner-container">
          <IconButton id="closeMenu" data-testid="closeMenu" onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
          <ul className="root-nav">
            <li>
              <a href={`${REACT_APP_WEB_URL}/`} className="inActiveNav">
                <>
                  <span>
                    <LogoIcon />
                  </span>
                  <span>
                    <Translate>Home</Translate>
                  </span>
                </>
              </a>
            </li>
            <li>
              <a href={`${REACT_APP_WEB_URL}/`} className="inActiveNav">
                <>
                  <span>
                    <Search />
                  </span>
                  <span>
                    <Translate>Find my bill</Translate>
                  </span>
                </>
              </a>
            </li>
            <li>
              <a
                href={`${REACT_APP_WEB_URL}/how-it-works`}
                className={pathname.indexOf('/how-it-works') === -1 ? 'inActiveNav' : 'activeNav'}
              >
                <>
                  <span>
                    <YogaIcon />
                  </span>
                  <span>
                    <Translate>How it works</Translate>
                  </span>
                </>
              </a>
            </li>
          </ul>
          <ul className="user-nav">
            {!state.isLoggedIn ? (
              <>
                <li>
                  <NavLink
                    to={`${REACT_APP_WEB_URL}/sign-in`}
                    className={navigationData =>
                      navigationData.isActive ? 'activeNav' : 'inActiveNav'
                    }
                  >
                    <span onClick={handleClose} onKeyDown={handleClose} role="button" tabIndex={0}>
                      {translateCommonLanguage.signIn}
                    </span>
                  </NavLink>
                </li>
                <div
                  onClick={() => {
                    handleLanguageModalOpen();
                    handleClose();
                  }}
                  onKeyDown={handleLanguageModalOpen}
                  className={languageSelect ? 'languageSelectorActive' : ''}
                  role="button"
                  tabIndex={0}
                >
                  <button className="languageButton" type="button">
                    <LanguageIcon />
                    <span onClick={handleClose} onKeyDown={handleClose} role="button" tabIndex={0}>
                      <p>{selectedLang}</p>
                      <p>{translateCommonLanguage.language}</p>
                    </span>
                  </button>
                </div>
              </>
            ) : (
              <>
                <li>
                  <NavLink
                    to={ROUTE.ACCOUNT}
                    className={
                      pathname.indexOf('/account') === -1 ? 'rightInactiveNav' : 'rightActiveNav'
                    }
                  >
                    <span onClick={handleClose} onKeyDown={handleClose} role="button" tabIndex={0}>
                      {translateCommonLanguage.accountOverview}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${REACT_APP_WEB_URL}${ROUTE.PROFILE}`}
                    className={
                      pathname.indexOf('/profile') === -1 ? 'rightInactiveNav' : 'rightActiveNav'
                    }
                  >
                    <span onClick={handleClose} onKeyDown={handleClose} role="button" tabIndex={0}>
                      {translateCommonLanguage.profile}
                    </span>
                  </NavLink>
                </li>
                <li>
                  <span
                    role="button"
                    data-testid="wide-menu-signOut"
                    onKeyDown={methods?.logOut}
                    tabIndex={0}
                    className="rightInactiveNav"
                    onClick={methods?.logOut}
                  >
                    <span onClick={handleClose} onKeyDown={handleClose} role="button" tabIndex={0}>
                      {translateCommonLanguage.signOut}
                    </span>
                  </span>
                </li>
                <div
                  onClick={() => {
                    handleLanguageModalOpen();
                    handleClose();
                  }}
                  onKeyDown={handleLanguageModalOpen}
                  className={languageSelect ? 'languageSelectorActive' : ''}
                  role="button"
                  tabIndex={0}
                >
                  <button className="languageButton" type="button">
                    <LanguageIcon />
                    <span onClick={handleClose} onKeyDown={handleClose} role="button" tabIndex={0}>
                      <p>{selectedLang}</p>
                      <p>{translateCommonLanguage.language}</p>
                    </span>
                  </button>
                </div>
              </>
            )}
            <Modal open={languageSelect} onClose={handleLanguageModalClose} className="langModal">
              <LanguageSelector
                onClose={handleLanguageModalClose}
                onChangeLanguage={onChangeLanguage}
                selectedLang={selectedLang}
                supportedLanguages={supportedLanguages}
              />
            </Modal>
          </ul>
          <ul className="footer-nav">
            <li>
              <a href={`${REACT_APP_WEB_URL}/faq`}>
                <Translate>Frequently Asked Questions</Translate>
              </a>
            </li>
            <li>
              <a href={`${REACT_APP_WEB_URL}/about`}>
                <Translate>About us</Translate>
              </a>
            </li>
            <li>
              <a href={`${REACT_APP_WEB_URL}/legal/privacy`}>
                <Translate>Privacy policy</Translate>
              </a>
            </li>
          </ul>
        </div>
      </section>
    );
  }
  return (
    <header id="header" className="header">
      <nav className={classnames(['links', 'links--left'])}>
        <div className="title">
          <a href={`${REACT_APP_WEB_URL}/`}>
            <LogoIcon />
            <span> Easy Smart Pay</span>
          </a>
        </div>
        <ul>
          <li>
            <a href={`${REACT_APP_WEB_URL}/`}>
              <Translate>Find my bill</Translate>
            </a>
          </li>
          <li>
            <a href={`${REACT_APP_WEB_URL}/how-it-works`}>
              <Translate>How it works</Translate>
            </a>
          </li>
        </ul>
      </nav>
      <nav className={classnames(['links', 'links--right'])}>
        {renderWideNav()}
        {renderNarrowNav()}
      </nav>
      {renderMobileMenu()}
    </header>
  );
};

export default Header;
