import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const CanceledIcon: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <g fill="#222" fillRule="evenodd" transform="translate(2 2)">
      <path
        fillRule="nonzero"
        d="M10,0 C4.48421053,0 0,4.48421053 0,10 C0,15.5157895 4.48421053,20 10,20 C15.5157895,20 20,15.5157895 20,10 C20,4.48421053 15.5157895,0 10,0 Z M1.81052632,10 C1.81052632,8.06315789 2.48421053,6.29473684 3.6,4.88421053 L4.88421053,3.62105263 C6.27368421,2.50526316 8.06315789,1.83157895 9.97894737,1.83157895 C14.4842105,1.83157895 18.1684211,5.49473684 18.1684211,10.0210526 C18.1684211,11.9578947 17.4947368,13.7263158 16.3789474,15.1157895 L15.0947368,16.4 C13.7052632,17.5157895 11.9157895,18.1894737 10,18.1894737 C5.49473684,18.1894737 1.81052632,14.5052632 1.81052632,10 Z"
      />
      <rect width="18" height="2.2" x="1" y="9" transform="rotate(45 10 10.1)" />
    </g>
  </SvgIcon>
);

export default CanceledIcon;
