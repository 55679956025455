import { makeStyles } from '@material-ui/core/styles';

import downArrow from './caret-down-solid.png';

import { COLORS, FONT_SANS_SERIF } from '../constants/styles';
import pxToEms from '../helpers/pxToEms';

const selectPadding = '1em';

const errorColor = COLORS.ORANGE_01;
interface Props {
  widthEms?: number;
}

const useFormStyles = makeStyles({
  form: ({ widthEms }: Props) => ({
    position: 'relative',
    fontFamily: FONT_SANS_SERIF,
    '& label': {
      fontWeight: 700,
      display: 'block',
      marginBottom: '0.8em',
      letterSpacing: '0.34px',
      lineHeight: '24px',
    },
    '& input': {
      width: widthEms ? `${widthEms}em` : '100%',
      padding: '1em',
      border: '1px solid #9E9E9E',
      borderRadius: 4,
      transition: '0.2s',

      '&:focus, &:active': {
        outline: 'none',
      },
    },

    '& .esp-MuiOutlinedInput-notchedOutline': {
      borderColor: '#9E9E9E',
    },

    '& .esp-MuiOutlinedInput-root input,': {
      border: 'none',
      marginTop: 0,
    },

    '& .esp-MuiOutlinedInput-root:hover .esp-MuiOutlinedInput-notchedOutline': {
      borderColor: '#9E9E9E',
    },

    '& .esp-MuiFormControl-root:focus, & .esp-MuiInputBase-root:focus': {
      outline: 'none',
      border: 'none',
    },

    '& .esp-MuiOutlinedInput-root.Mui-focused .esp-MuiOutlinedInput-notchedOutline': {
      outline: 'none',
      border: '1px solid #9E9E9E !important',
    },

    '& .esp-MuiSelect-select:focus': {
      backgroundColor: COLORS.WHITE,
    },

    '& .esp-MuiInput-underline': {
      border: '1px solid #9E9E9E',
      borderRadius: 4,

      '& input': {
        border: 'none',
      },
    },

    '& .esp-MuiInput-underline:before, .esp-MuiInput-underline:after': {
      display: 'none',
    },
    '& > button': {
      padding: '1em 1.5em',
      textTransform: 'uppercase',
      borderRadius: 4,
      border: 0,
      cursor: 'pointer',
      marginTop: '1.5em',
      fontFamily: FONT_SANS_SERIF,
      fontWeight: 700,
      fontSize: pxToEms(16),
      color: COLORS.WHITE,
      letterSpacing: pxToEms(1.14),
      textAlign: 'center',
      lineHeight: pxToEms(20),
      backgroundColor: COLORS.BLUE_03,

      '&:disabled': {
        color: 'rgba(52,174,224,0.4) !important',
        backgroundColor: `${COLORS.BLUE_O1}  !important`,
        cursor: 'not-allowed',
      },

      '&:active, &:hover, &:focus': {
        backgroundColor: COLORS.BLUE_05,
        outline: 'none',
      },
    },
    '& select': {
      width: widthEms ? `${widthEms}em` : '100%',
      padding: selectPadding,
      border: '1px solid #9E9E9E',
      borderRadius: 4,
      appearance: 'none',
      background: `url(${downArrow}) no-repeat right 10px center`,
      backgroundPositionX: `calc(${widthEms}em - (${selectPadding} * 2))`,
      /**
       * Hide dropdown arrow in IE11
       *
       * This will cause the following console error:
       *
       * Warning: [JSS] An invalid or illegal string was specified
       */
      // '&::-ms-expand': {
      //   display: 'none',
      // },
    },
  }),
  // Assign this as a className when form state indicates an error
  errorInput: {
    color: '#CD6133 !important',
    border: '2px solid #CD6133 !important',
  },
  // Assign this to an element that will display an error message about the form
  errorMessage: {
    display: 'block',
    marginTop: pxToEms(8),
    color: errorColor,
    fontSize: pxToEms(14),
    lineHeight: pxToEms(20),
    letterSpacing: pxToEms(0.3),
    fontFamily: FONT_SANS_SERIF,
    fontWeight: 500,
  },

  '.formGroup.error': {
    '& input,& .esp-MuiInput-underline, & .esp-MuiOutlinedInput-root fieldset': {
      borderColor: `${errorColor} !important`,
      color: errorColor,
    },

    '& .esp-MuiInput-underline': {
      '& input': {
        border: 'none',
      },
    },

    '& span': {
      color: errorColor,
    },
  },

  select: {
    width: '100%',
    height: pxToEms(19),
  },
});

export default useFormStyles;
