import React, { FunctionComponent } from 'react';
import Disclaimer from '../Disclaimer';
import { Translate } from '../../../libraries/translate';

export interface TransactionFeeDisclaimerProps {
  rate: number;
  tag?: string;
}

/**
 * A simple "hit the browser's back button" component
 */
const TransactionFeeDisclaimer: FunctionComponent<TransactionFeeDisclaimerProps> = ({
  rate,
  tag = '*',
}: TransactionFeeDisclaimerProps) => {
  const adustedRate = (rate * 0.01).toFixed(2);

  return (
    <Disclaimer tag={tag}>
      <Translate>{`All payments require a transaction fee of ${adustedRate}
      %, which applies to each one-time or monthly payment and will be applied during checkout.`}</Translate>
    </Disclaimer>
  );
};

export default TransactionFeeDisclaimer;
