const getCurrentTaxYear = () => {
  const currentDate = new Date();
  let currentYear = currentDate.getFullYear();

  // if first part of the year (before May), we assume we are in the
  // tail end of the tax year.
  // TODO: This is a temp fix will need to be adjusted once we migrate to the marketing app
  if (currentDate.getMonth() < 3) {
    currentYear -= 1;
  }

  return currentYear;
};

export default getCurrentTaxYear;
