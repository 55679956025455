import { AxiosError } from 'axios';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { BillingInfo } from '../models/BillingInfo';
import { PaymentMethod } from '../models/PaymentMethod';
import {
  loadPaymentMethods,
  createPaymentMethod,
  deletePaymentMethod,
  updatePaymentMethod,
} from '../services/paymentMethod';
import { SnackbarMessageContext } from './SnackbarMessageContext';

interface PaymentMethodsContextType {
  loading: boolean;
  loaded: boolean;
  error: AxiosError | undefined;
  data: PaymentMethod[] | null;
  getPaymentMethods: () => Promise<void>;
  addPaymentMethod: (
    paymentNonce: string,
    billingDetails: BillingInfo,
    paymentTypeId: 'ACH' | 'Credit',
    isDefault?: boolean
  ) => Promise<void>;
  removePaymentMethod: (token: string) => Promise<boolean>;
  editPaymentMethod: (
    token: string,
    update: {
      billingDetails?: Partial<BillingInfo>;
      isDefault?: boolean;
      updateNonce?: string;
      expirationDate?: string;
    }
  ) => Promise<void>;
  resetError: () => void;
}

const PaymentMethodsContext = createContext<PaymentMethodsContextType>(null!);

export function PaymentMethodsProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | undefined>();
  const [data, setData] = useState<PaymentMethod[] | null>(null);
  const snackbarMessageContext = useContext(SnackbarMessageContext);

  function handleError(err: AxiosError) {
    setError(err);
    setLoading(false);
    setLoaded(true);
  }

  const getPaymentMethods = async () => {
    setLoading(true);
    setLoaded(false);
    loadPaymentMethods()
      .then((response: any) => {
        const responseData = response as PaymentMethod[];
        setData(responseData);
        setLoaded(true);
        setLoading(false);
        setError(undefined);
      })
      .catch(handleError);
  };

  const addPaymentMethod = async (
    paymentNonce: string,
    billingDetails: BillingInfo,
    paymentTypeId: 'ACH' | 'Credit',
    isDefault?: boolean
  ) => {
    setLoaded(false);
    setError(undefined);
    createPaymentMethod(paymentNonce, billingDetails, paymentTypeId, isDefault || false)
      .then((result: any) => {
        const response = result as PaymentMethod;
        let newData: PaymentMethod[] = [];
        if (data) {
          // since we are requesting the update payment methods
          // we need to add this payment method to the existing array
          newData = [
            response,
            ...data.map((x: PaymentMethod) => ({
              ...x,
              // if the new payment method is default
              // the previous default isn't
              // otherwise, we can leave it alone
              ...(isDefault && { default: false }),
            })),
          ];
        } else {
          newData.push(response);
        }
        snackbarMessageContext.setMessage('Your new secure payment method has been added.');
        setData(newData);
        setLoaded(true);
        setError(undefined);
      })
      .catch(handleError);
  };

  const removePaymentMethod = async (token: string) => {
    setError(undefined);
    let success = false;
    await deletePaymentMethod(token)
      .then(() => {
        if (data) {
          const filteredData = data.filter((x: PaymentMethod) => x.token !== token);
          setTimeout(() => {
            setData(filteredData);
          }, 3000);
        }
        success = true;
      })
      .catch(handleError);
    return success;
  };

  const editPaymentMethod = async (
    token: string,
    update: {
      billingDetails?: Partial<BillingInfo>;
      expirationDate?: string;
      isDefault?: boolean;
      updateNonce?: string;
    }
  ) => {
    setError(undefined);
    updatePaymentMethod(token, update)
      .then(result => {
        let newData: PaymentMethod[] = [];
        if (data) {
          newData = data.map((x: PaymentMethod) => {
            // replace the updated payment method with the api response
            if (x.token === token) {
              return result as PaymentMethod;
            }
            // if the one we are updating is the default, then all other methods aren't
            if (update.isDefault) {
              return { ...x, default: false };
            }
            return x;
          });
        } else {
          newData.push(result as PaymentMethod);
        }
        setData(newData);
        snackbarMessageContext.setMessage('Your payment method has been updated.');
      })
      .catch(handleError);
  };

  const resetError = () => {
    setError(undefined);
  };

  const value = {
    loading,
    loaded,
    error,
    data,
    getPaymentMethods,
    addPaymentMethod,
    removePaymentMethod,
    editPaymentMethod,
    resetError,
  };

  return <PaymentMethodsContext.Provider value={value}>{children}</PaymentMethodsContext.Provider>;
}

export function usePaymentMethods(): PaymentMethodsContextType {
  return useContext(PaymentMethodsContext);
}
