import formatTaxYear from '../../helpers/formatTaxYear';
import { TaxBill } from '../../models/Property';

const formatTaxBill = (taxBill: TaxBill, installmentNumbers: number[] = []) => {
  const isEstimate = taxBill.billType === 3;
  const billPeriod = formatTaxYear(taxBill.taxYear);

  if (installmentNumbers.length > 0) {
    taxBill.installments.forEach(installment => {
      if (installmentNumbers.includes(installment.installmentNumber)) {
        installment.isUnderContract = true;
      }
    });
  }
  return { ...taxBill, isEstimate, billPeriod };
};

export default formatTaxBill;
