const BREAKPOINTS = {
  ZERO: 0,
  XXS: 320,
  XS: 360,
  SSM: 430,
  SM: 768,
  MD: 1024,
  LG: 1280,
  XL: 1920,
};

export default BREAKPOINTS;
