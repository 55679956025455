import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@material-ui/core';
import { BORDER_STYLE, COLORS } from '../constants/styles';
import BREAKPOINTS from '../constants/breakpoints';
import { Translate } from '../../libraries/translate';

interface Props {
  type: 'ERROR' | 'INFO' | 'GRACE_PERIOD';
  description?: string;
  status?: string;
}

const STATUSES: any = {
  PAYMENT: {
    title: 'Payment Failed',
    desc: 'Please contact us to resolve your payment status.',
  },
  CANCELED: {
    title: 'Payment canceled',
    desc:
      'Unless this is reconciled, ESP will not have sufficient funds to submit to your county on your behalf.',
  },
  GRACE_PERIOD: {
    title: 'Why is my installment not shown as being paid to the county?',
    desc:
      'Great question. Easy Smart Pay allows payments up until <date>. After this, we work with the county to transfer and credit your payment. This process can take up to 10 days. During this time your payment may not be reflected with the county or ESP.',
  },
};
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    fontSize: 14,
    borderBottom: BORDER_STYLE,

    '& > *': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: '16px 24px 0 24px',
      width: '100%',

      '& .label': {
        fontSize: 16,
      },

      '& > div p:last-child': {
        paddingBottom: 16,
      },

      '& .description': {
        minWidth: 50,
      },

      '& .contactInfo': {
        textAlign: 'right',

        '& a': {
          color: COLORS.DEFAULT,
        },
      },

      [theme.breakpoints.down(BREAKPOINTS.MD)]: {
        display: 'block',

        '& .contactInfo': {
          textAlign: 'left',
        },
      },

      [theme.breakpoints.down(BREAKPOINTS.SM)]: {
        display: 'flex',

        '& .contactInfo': {
          textAlign: 'right',
        },
      },

      [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
        display: 'block',

        '& .contactInfo': {
          textAlign: 'left',
        },
      },
    },

    '& p': {
      margin: 0,
    },
  },

  error: {
    background: 'rgba(255,245,240,1)',

    '& .label': {
      color: 'rgba(255,82,82,1)',
    },
  },

  info: {
    background: 'rgba(243,251,255,1)',

    '& .label': {
      color: 'rgba(52,172,224,1)',
    },

    '& p': {
      fontSize: '14px',
      letterSpacing: '0.3px',
      lineHeight: '20px',
    },
  },
}));

const OrderStatus: FunctionComponent<Props> = (props: Props) => {
  const { type, description, status = 'CANCELED' } = props;
  const styles = useStyles({ minWidth: 768 });
  let style;
  let statusData = STATUSES[status];
  let showContactInfo;

  switch (type) {
    case 'INFO':
      statusData = statusData && STATUSES.CANCELED;
      style = styles.info;
      showContactInfo = false;
      break;
    case 'GRACE_PERIOD':
      statusData = statusData && STATUSES.GRACE_PERIOD;
      // re-using the description prop for the date
      statusData.desc = statusData.desc.replace('<date>', description);
      style = styles.info;
      showContactInfo = false;
      break;
    case 'ERROR':
    default:
      statusData = statusData && STATUSES.PAYMENT;
      style = styles.error;
      showContactInfo = true;
      break;
  }

  const { title } = statusData;
  // since we are re-using the description prop for the date, we need to remove it for desc
  const desc = type === 'GRACE_PERIOD' ? statusData.desc : description || statusData.desc;

  return (
    <div className={styles.container}>
      <section className={style}>
        <div className="description">
          <span className="label">
            <Translate>{title}</Translate>
          </span>
          <p>
            <Translate>{desc}</Translate>
          </p>
        </div>
        {showContactInfo && (
          <div className="contactInfo">
            <p>
              <a href="tel:8333976278">833-397-6278</a>
            </p>
            <p>
              <a href="mailto:support@easysmartpay.net">support@easysmartpay.net</a>
            </p>
          </div>
        )}
      </section>
    </div>
  );
};

export default OrderStatus;
