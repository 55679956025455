import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const ReceiptIcon: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <polygon fill="#222" fillRule="nonzero" points="15.75 12 17.25 12 17.25 13.5 15.75 13.5" />
      <polygon fill="#222" fillRule="nonzero" points="6.75 12 12.75 12 12.75 13.5 6.75 13.5" />
      <polygon fill="#222" fillRule="nonzero" points="15.75 9 17.25 9 17.25 10.5 15.75 10.5" />
      <polygon fill="#222" fillRule="nonzero" points="6.75 9 12.75 9 12.75 10.5 6.75 10.5" />
      <polygon fill="#222" fillRule="nonzero" points="6.75 6 17.25 6 17.25 7.5 6.75 7.5" />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M18.75,1.5 L5.25,1.5 C4.42191561,1.50082684 3.75082684,2.17191561 3.75,3 L3.75,21.75 C3.75,22.1642136 4.08578644,22.5 4.5,22.5 L5.25,22.5 C5.48609586,22.5 5.70843703,22.3889476 5.85,22.2 L7.5,20.00025 L9.15,22.2 C9.29744452,22.3796249 9.51761022,22.4837421 9.75,22.4837421 C9.98238978,22.4837421 10.2025555,22.3796249 10.35,22.2 L12,20.00025 L13.65,22.2 C13.7974445,22.3796249 14.0176102,22.4837421 14.25,22.4837421 C14.4823898,22.4837421 14.7025555,22.3796249 14.85,22.2 L16.5,20.00025 L18.15,22.2 C18.2916408,22.3888544 18.513932,22.5 18.75,22.5 L19.5,22.5 C19.9142136,22.5 20.25,22.1642136 20.25,21.75 L20.25,3 C20.2491732,2.17191561 19.5780844,1.50082684 18.75,1.5 Z M18.75,20.49975 L17.1,18.3 C16.9525555,18.1203751 16.7323898,18.0162579 16.5,18.0162579 C16.2676102,18.0162579 16.0474445,18.1203751 15.9,18.3 L14.25,20.49975 L12.6,18.3 C12.4525555,18.1203751 12.2323898,18.0162579 12,18.0162579 C11.7676102,18.0162579 11.5474445,18.1203751 11.4,18.3 L9.75,20.49975 L8.1,18.3 C7.95255548,18.1203751 7.73238978,18.0162579 7.5,18.0162579 C7.26761022,18.0162579 7.04744452,18.1203751 6.9,18.3 L5.25,20.49975 L5.25,3 L18.75,3 L18.75,20.49975 Z"
      />
    </g>
  </SvgIcon>
);

export default ReceiptIcon;
