import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Theme } from '@material-ui/core';
import TaxBillInstallment from './TaxBillInstallment/TaxBillInstallment';
import SectionCarousel from './SectionCarousel';
import { Installment, TaxBill } from '../models/Property';
import Disclaimer from './Disclaimer';
import { Product } from '../models/Product';
import { BORDER_STYLE } from '../constants/styles';
import { Translate } from '../../libraries/translate';

interface Props {
  taxBill: TaxBill;
  product: Product;
  showPayNow?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: 80,
    '& > p': {
      padding: '0 24px',
    },
  },

  installments: (inputs: { minWidth: number }) => ({
    '& > section': {
      padding: '0',
      margin: '16px 0',
      borderRadius: 3,
    },

    '& > section:last-child': {
      marginBottom: '0 !important',
    },

    [theme.breakpoints.down(inputs.minWidth)]: {
      border: BORDER_STYLE,
      boxShadow: '0 1px 4px 0 rgba(0,0,0,0.3)',

      '& > section': {
        margin: '0 !important',
      },
    },
  }),
}));

const TaxBillInstallments: FunctionComponent<Props> = ({
  taxBill,
  product,
  showPayNow = true,
}: Props) => {
  const lastDays = product.billing ? product.billing.lastDayToPayNumDays * -1 : 0;
  const styles = useStyles({ minWidth: 768 });

  return (
    <div className={styles.container}>
      <SectionCarousel
        className={styles.installments}
        items={taxBill.installments}
        label="Installment"
      >
        {(installment: Installment) => (
          <TaxBillInstallment
            key={`${taxBill.apn}-${installment.installmentNumber}`}
            installment={installment}
            taxRollId={product.taxrollId!}
            showPayNow={showPayNow}
          />
        )}
      </SectionCarousel>
      <Disclaimer tag="1">
        <Translate>{`your last day to pay Easy Smart Pay is ${lastDays} days prior to the installment due date.`}</Translate>
      </Disclaimer>
    </div>
  );
};

export default TaxBillInstallments;
