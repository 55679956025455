export enum ROUTE {
  HOME = '/',
  HOW_IT_WORKS = '/how-it-works',
  FIND_MY_BILL = '/property',
  FIND_MY_BILL_LEGACY = '/find-my-bill',
  PROPERTY_DETAILS = '/property',
  SIGN_IN = '/sign-in',
  CREATE_ACCOUNT = '/create-account',
  FORGOT_PASSWORD = '/forgot-password',
  FORGOT_PASSWORD_RESET = '/forgot-password/reset',
  ACCOUNT = '/account',
  PROFILE = '/profile',
  USER_AGREEMENT = '/legal/user-agreement',
}

export const SERVER_DATE_FORMAT = 'M/d/yyyy';

export const PASSWORD_MIN_LENGTH = 12;
