import React, { useReducer, FunctionComponent, useContext } from 'react';

import { BaseState } from '../models/BaseState';
import { ResponseError } from '../models/ResponseError';
import { PropertyBill, PropertyBills } from '../models/Property';
import ProductsContext from './ProductsContext';
// import formatTaxBill from './helpers/formatTaxBill';
import {
  getPropertyBillsByAddress,
  getPropertyBillsByAPN,
  PropertyBillsResponse,
} from '../services/property';

// State
interface PropertiesState extends BaseState {
  properties: { [id: string]: PropertyBills };
}

const defaultState: PropertiesState = { loading: false, properties: {} };

// Context
type ContextProps = {
  state: PropertiesState;
  dispatch?: {};
  selectors?: {
    selectPropertyBillsByAPN: (apn: string) => PropertyBills;
    selectPropertyBillsByAddress: (address: string) => PropertyBills | undefined;
  };
  methods?: {
    getPropertyBillsByAPN: (taxrollId: string, apn: string, today?: string) => Promise<void>;
    getPropertyBillsByAddress: (address: string) => Promise<void>;
    clearError: () => void;
  };
};

const PropertyBillsContext = React.createContext<ContextProps>({
  state: defaultState,
});

// Reducer & Actions
type Action = {
  type: ACTION;
  payload?: PropertyBills | PropertyBill | ResponseError;
};

enum ACTION {
  GET_PROPERTY_BILLS = 'actionGetPropertyBills',
  GET_PROPERTY_BILLS_SUCCESS = 'actionGetPropertyBillsSuccess',
  GET_PROPERTY_BILLS_FAILURE = 'actionGetPropertyBillsFailure',
  CLEAR_ERRORS = 'actionClearErrors',
}

function PropertyBillsReducer(state: PropertiesState, action: Action): PropertiesState {
  switch (action.type) {
    case ACTION.GET_PROPERTY_BILLS:
      return {
        ...state,
        loading: true,
        errors: undefined,
      };
    case ACTION.GET_PROPERTY_BILLS_SUCCESS: {
      const property = action.payload as PropertyBills;
      const properties = { ...state.properties };

      properties[property.apn] = property;
      return {
        ...state,
        properties,
        errors: undefined,
        loading: false,
      };
    }
    case ACTION.GET_PROPERTY_BILLS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: (action.payload as ResponseError).errors,
      };
    case ACTION.CLEAR_ERRORS:
      return {
        ...state,
        errors: undefined,
      };
    default:
      return state;
  }
}
// Business Methods

async function submitGetPropertyBillsByAPN(
  dispatch: React.Dispatch<Action>,
  taxrollId: string,
  apn: string,
  today?: string
) {
  dispatch({ type: ACTION.GET_PROPERTY_BILLS });

  getPropertyBillsByAPN(taxrollId, apn, today)
    .then((response: PropertyBillsResponse) => {
      dispatch({
        type: ACTION.GET_PROPERTY_BILLS_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: ACTION.GET_PROPERTY_BILLS_FAILURE,
        payload: {
          errors: {
            message: "Unable to find your property's tax bill details",
          },
        },
      });
    });
}

function selectPropertyBillsByAPN(state: PropertiesState, apn: string): PropertyBills {
  return state?.properties[apn.trim()];
}

async function submitgetPropertyBillsByAddress(
  dispatch: React.Dispatch<Action>,
  selectProductByAddress: Function | undefined,
  address: string
) {
  dispatch({ type: ACTION.GET_PROPERTY_BILLS });

  let addressProperty;
  if (selectProductByAddress) {
    addressProperty = selectProductByAddress(address) as PropertyBills;
  }

  if (addressProperty) {
    const { taxrollId } = addressProperty;

    getPropertyBillsByAddress(taxrollId, address)
      .then((response: PropertyBillsResponse) => {
        // const { property } = response;
        // if (property.taxBill) {
        //   property.taxBill = formatTaxBill(property.taxBill);
        // }

        dispatch({
          type: ACTION.GET_PROPERTY_BILLS_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        // TODO: Get error message from response
        dispatch({
          type: ACTION.GET_PROPERTY_BILLS_FAILURE,
          payload: {
            errors: {
              message: 'Unable to find a property tax bill for address, please try ',
            },
          },
        });
      });
  } else {
    dispatch({
      type: ACTION.GET_PROPERTY_BILLS_FAILURE,
      payload: {
        errors: {
          message: 'At this time we do not process secured property tax payments for this address.',
        },
      },
    });
  }
}

// Selectors

function selectPropertyBillsByAddress(state: PropertiesState, address: string) {
  if (state.properties) {
    // TODO: Any is not right, we should be able to have (property:Property)
    const apn = Object.keys(state.properties).find((key: any) => {
      const property: PropertyBills = state.properties[key];
      return address?.toLowerCase().indexOf(property.address?.fullAddress.toLowerCase()) > -1;
    });

    if (apn) {
      return state.properties[apn];
    }
  }

  return undefined;
}

// Provider
export const PropertyBillsProvider: FunctionComponent = props => {
  const [state, dispatch] = useReducer(PropertyBillsReducer, defaultState);
  const productsContext = useContext(ProductsContext);
  return (
    <PropertyBillsContext.Provider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={{
        state,
        dispatch: {},
        selectors: {
          selectPropertyBillsByAPN: (apn: string) => selectPropertyBillsByAPN(state, apn),
          selectPropertyBillsByAddress: (address: string) =>
            selectPropertyBillsByAddress(state, address),
        },
        methods: {
          getPropertyBillsByAPN: (taxrollId: string, apn, today?: string) =>
            submitGetPropertyBillsByAPN(dispatch, taxrollId, apn, today),
          getPropertyBillsByAddress: address =>
            submitgetPropertyBillsByAddress(
              dispatch,
              productsContext.selectors?.selectProductByAddress,
              address
            ),
          clearError: () => dispatch({ type: ACTION.CLEAR_ERRORS }),
        },
      }}
    />
  );
};

export default PropertyBillsContext;
