/**
 * Take a dollar-and-cents amount as an integer and format it "$xxxx.yy"
 *
 * Example:
 *
 * IN: 400000
 * OUT: "$4,000.00"
 */
export default function toPrettyUSD(amountInCents: number): string {
  const toFloat = amountInCents / 100;

  return toFloat.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}
