import React, { FunctionComponent, ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { COLORS, FONT_SERIF, BASE_FONT } from '../../constants/styles';
import { Translate } from '../../../libraries/translate';

export interface InfoBoxProps {
  children: ReactNode;
  header: string;
  type?: 'error' | 'info';
}

const useStyles = makeStyles((theme: Theme) => ({
  section: (inputs: { type: string }) => {
    let backgroundColor;
    let color;
    let flag;
    if (inputs.type === 'error') {
      backgroundColor = COLORS.PINK_01;
      color = COLORS.RED_01;
      flag = COLORS.RED_01;
    } else {
      backgroundColor = '#F3FBFF';
      color = COLORS.DEFAULT;
      flag = COLORS.BLUE_03;
    }
    return {
      boxSizing: 'border-box',
      minHeight: '173px',
      margin: '0 auto',
      padding: '32px 80px',
      border: `1px solid ${COLORS.BORDER}`,
      backgroundColor,
      color: '#666666',
      fontFamily: BASE_FONT,
      fontSize: '20px',
      letterSpacing: '0.43px',
      lineHeight: '28px',
      textAlign: 'center',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        padding: '24px 16px',
        fontSize: '16px',
        lineHeight: '24px',
      },

      '& a': {
        color,
      },

      '& p': {
        margin: 0,
      },

      '& h2': {
        fontFamily: FONT_SERIF,
        fontSize: '24px',
        letterSpacing: '0.51px',
        lineHeight: '36px',
        textAlign: 'center',
        marginTop: 0,
        color,
        [theme.breakpoints.down('xs')]: {
          fontSize: '20px',
          lineHeight: '30px',
        },
      },

      '&::after': {
        content: '" "',
        position: 'absolute',
        left: 0,
        right: 0,
        top: -2,
        margin: '0 auto',
        height: '8px',
        width: '40px',
        backgroundColor: flag,
      },
    };
  },
}));

/**
 * Component used to show information header and copy.
 */
const InfoBox: FunctionComponent<InfoBoxProps> = ({
  children,
  header,
  type = 'info',
}: InfoBoxProps) => {
  const styles = useStyles({ type });

  return (
    <section className={styles.section}>
      <h2>
        <Translate>{header}</Translate>
      </h2>
      {children}
    </section>
  );
};

export default InfoBox;
