import { FONT_SIZE_BASIS_PX } from '../constants/styles';

type RelativeUnit = 'em' | 'rem';

/**
 * Convert the px measurements specified in Invision into rems or ems
 */
export default function pxToEms(px: number, unit: RelativeUnit = 'rem'): string {
  return `${px / FONT_SIZE_BASIS_PX}${unit}`;
}
