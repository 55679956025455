import React, { createContext, ReactNode, useContext, useState } from 'react';
import { AxiosError } from 'axios';
import { loadDemographics } from '../services/FastFacts';
import { DemographicFact } from '../models/Facts/DemographicFact';

interface DemographicsContextType {
  loading: boolean;
  loaded: boolean;
  error: AxiosError | undefined;
  data: DemographicFact | null;
  getTaxDistribution: (county: string) => Promise<void>;
  clearTaxDistribution: () => void;
}

const DemographicsContext = createContext<DemographicsContextType>(null!);

export function DemographicsProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [data, setData] = useState<DemographicFact | null>(null);
  const [error, setError] = useState<AxiosError | undefined>();
  // keep track of last county to prevent excessive requests
  const [lastCounty, setLastCounty] = useState<string>('');

  function handleError(err: AxiosError) {
    setError(err);
    setLoading(false);
    setLoaded(true);
  }

  const getTaxDistribution = async (county: string) => {
    if (county !== lastCounty) {
      setLoading(true);
      loadDemographics(county)
        .then(taxData => {
          setData(taxData);
          setLoaded(true);
          setLoading(false);
          setLastCounty(county);
        })
        .catch(handleError);
    }
  };

  const clearTaxDistribution = () => {
    setLoaded(false);
    setLoading(false);
    setData(null);
  };

  const value = {
    getTaxDistribution,
    loading,
    loaded,
    error,
    data,
    clearTaxDistribution,
  };

  return <DemographicsContext.Provider value={value}>{children}</DemographicsContext.Provider>;
}

export function useDemographics(): DemographicsContextType {
  return useContext(DemographicsContext);
}
