import axios from 'axios';
import axiosRetry from 'axios-retry';

import { REACT_APP_API_URL, REACT_APP_SERVER_ENVIRONMENT } from '../helpers/constants';

const retryConfig = {
  retries: 3,
  retryCondition: (e: any) => e.response?.status === 404,
  retryDelay: () => 1000,
};

/**
 * Axios instance set up with a root request URL, and support for setting and sending cookies
 */
const espAxios = axios.create({
  baseURL: REACT_APP_API_URL,
  withCredentials: true,
});

axiosRetry(espAxios, retryConfig);

export const API_PREFIX = '/v1';

export const LOCAL_CONFIG: any = {
  auth: 'http://localhost:8080',
  product: 'http://localhost:8081',
  order: 'http://localhost:8082',
  // auth: 'https://dev-web.api.easysmartpay.net',
  // product: 'https://dev-web.api.easysmartpay.net',
  // order: 'https://dev-web.api.easysmartpay.net',
};
export function getAxios(service: string) {
  if (REACT_APP_SERVER_ENVIRONMENT === 'local') {
    const client = axios.create({
      baseURL: LOCAL_CONFIG[service],
      withCredentials: true,
    });
    axiosRetry(client, retryConfig);

    return client;
  }

  return espAxios;
}

export default espAxios;
