import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FONT_SERIF } from '../constants/styles';

import pxToEms from '../helpers/pxToEms';

const activeBorderHeight = 4;

/**
 * These styles make NavLink's and buttons look like tabs:
 *
 * ```
 * const tabsStyles = useTabsStyles();
 *
 * return (
 *   <section className={tabsStyles.tabs}>
 *     <ul>
 *       <li>
 *         // React Router NavLink (outputs an <a> element)
 *         <NavLink activeClassName={tabsStyles.activeTab}>
 *           Tab 2
 *         </NavLink>
 *       </li>
 *       <li>
 *         // Button that does something when clicked (show another component, etc...)
 *         <button className={tabsStyles.activeTab}>
 *           Tab 3
 *         </button>
 *       </li>
 *     </ul>
 *   </section>
 * );
 * ```
 *
 */
const useTabsStyles = makeStyles((theme: Theme) => ({
  tabs: {
    fontSize: pxToEms(16),
    [theme.breakpoints.up('xs')]: {
      fontSize: pxToEms(20),
    },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      marginBottom: 24,
      padding: 0,
      borderBottom: '1px solid #0D7191',
      width: '100%',
      '& li': {
        display: 'inline-block',
        marginRight: pxToEms(12),
        fontFamily: FONT_SERIF,
      },
    },
    '& a, & button': {
      color: '#0D7191',
      textDecoration: 'none',
      display: 'inline-block',
      height: `calc(100% - ${activeBorderHeight}px)`,
      paddingRight: 24,
      // It looks better when we subtract the border height
      paddingBottom: 8 - activeBorderHeight,
      paddingLeft: 4,

      [theme.breakpoints.down(430)]: {
        paddingRight: 12,
      },
    },
    '& button': {
      background: 'unset',
      border: 0,
      cursor: 'pointer',
    },
  },
  activeTab: {
    color: '#0CAEDD !important',
    borderBottom: `${activeBorderHeight}px solid #0CAEDD !important`,
  },
  inactiveTab: {
    color: '#666 !important',
  },
}));

export default useTabsStyles;
