import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import pxToEms from '../helpers/pxToEms';
import DisclaimerTag from './DisclaimerTag';

interface Props {
  tag: string;
  children: React.ReactNode;
}

const useStyles = makeStyles({
  disclaimer: {
    fontSize: '14px',
    marginBottom: pxToEms(8),
    lineHeight: '20px',

    '& sup': {
      top: '-.75em',
    },
    '& > span': {
      paddingLeft: pxToEms(2),
    },
  },
});

const Disclaimer: FunctionComponent<Props> = (props: Props) => {
  const { tag, children } = props;
  const styles = useStyles();

  return (
    <p id={`disclaimer-${tag}`} className={styles.disclaimer}>
      <DisclaimerTag tag={tag} showLink={false} />
      <span>{children}</span>
    </p>
  );
};

export default Disclaimer;
