import React, { FunctionComponent } from 'react';
import { TaxBill } from '../../../../../global/models/Property';
import { Product } from '../../../../../global/models/Product';
import TaxBillInstallments from '../../../../../global/components/TaxBillInstallments';

interface Props {
  taxBill: TaxBill;
  product: Product;
}

const TaxBillDetails: FunctionComponent<Props> = ({ taxBill, product }: Props) => (
  <>
    <TaxBillInstallments taxBill={taxBill} product={product} />
  </>
);

export default TaxBillDetails;
