import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Installment, PaymentOption, TaxBill } from '../../../../../global/models/Property';
import Disclaimer from '../../../../../global/components/Disclaimer';
import { Product } from '../../../../../global/models/Product';
import TransactionFeeDisclaimer from '../../../../../global/components/disclaimers/TransactionFeeDisclaimer';
import InfoBox from '../../../../../global/components/InfoBox/InfoBox';
import formatTaxYear from '../../../../../global/helpers/formatTaxYear';
import BREAKPOINTS from '../../../../../global/constants/breakpoints';
import { PaymentContract } from '../../../../../global/models/PaymentContract';
import SinglePaymentDetails from './SinglePaymentDetails';
import MultiplePaymentDetails from './MultiplePaymentDetails';
import PaymentOptionRow from './PaymentOptionsRow';
import { Translate } from '../../../../../libraries/translate';

interface Props {
  paymentContracts: PaymentContract[];
  taxBill: TaxBill;
  product: Product;
}

const useStyles = makeStyles((theme: Theme) => ({
  orderRow: {
    marginBottom: 24,
  },

  container: {
    marginBottom: 80,

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      marginBottom: 0,
    },
  },

  disclaimerWrapper: {
    margin: 0,

    [theme.breakpoints.down(BREAKPOINTS.SM)]: {
      margin: '0 24px',
    },
  },
}));

const PaymentDetails: FunctionComponent<Props> = ({
  paymentContracts,
  taxBill,
  product,
}: Props) => {
  const styles = useStyles();
  const availableInstallments = taxBill.installments.filter(
    installment => !installment.isUnderContract
  );
  const paymentOptions: { type: string; option: PaymentOption; installments: Installment[] }[] = [];

  useEffect(() => {
    if (taxBill) {
      Object.keys(taxBill.paymentOptions).forEach(optionKey => {
        const paymentOption = taxBill.paymentOptions[optionKey];

        paymentOption.options.forEach(option => {
          const installments = availableInstallments.filter(installment =>
            option.variant.requiredInstallments.some(num => installment.installmentNumber === num)
          );
          if (installments.length > 0) {
            const optionObj = { type: optionKey, option, installments };
            if (optionKey === 'monthly') {
              paymentOptions.unshift(optionObj);
            } else {
              paymentOptions.push(optionObj);
            }
          }
        });
      });
    }
  }, [taxBill]);

  function renderPaymentContract(contract: PaymentContract) {
    const rate = contract.schedule[0].transactionRatePct * 100;
    if (contract.schedule.length > 1) {
      const disclaimer = <TransactionFeeDisclaimer key={contract.id} rate={rate} />;
      return (
        <div>
          <MultiplePaymentDetails
            key={contract.id}
            taxBill={taxBill}
            paymentContract={contract}
            disclaimer={disclaimer}
          />
        </div>
      );
    }
    const disclaimer = <Disclaimer tag="1">Including transaction fee of {rate * 0.01}%</Disclaimer>;
    return (
      <SinglePaymentDetails
        key={contract.id}
        taxBill={taxBill}
        paymentContract={contract}
        payment={contract.schedule[0]}
        disclaimer={disclaimer}
      />
    );
  }

  return (
    <div className={styles.container}>
      {paymentContracts.map(contract => (
        <section key={contract.id} className={styles.orderRow}>
          {renderPaymentContract(contract)}
        </section>
      ))}
      {paymentOptions.map(option => (
        <PaymentOptionRow
          option={option.option}
          type={option.type}
          installments={taxBill.installments}
          apn={taxBill.apn}
          taxRollId={product.taxrollId!}
        />
      ))}
      {paymentContracts.length === 0 && paymentOptions.length === 0 && (
        <InfoBox header="">
          <Translate>{`No payments or payment options are available for the ${formatTaxYear(
            taxBill.taxYear
          )} tax
          year.`}</Translate>
        </InfoBox>
      )}
      <div className={styles.disclaimerWrapper}>
        {taxBill.isEstimate && (
          <Disclaimer tag="1">
            <Translate>
              Payments will be recalculated when the official tax bill becomes available.
            </Translate>
          </Disclaimer>
        )}
      </div>
      {paymentContracts.length === 0 && paymentOptions.length > 0 && (
        <TransactionFeeDisclaimer rate={taxBill.percent * 0.01} />
      )}
    </div>
  );
};

export default PaymentDetails;
