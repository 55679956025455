import { FormControl, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import ContentColumn from '../../../../global/components/ContentColumn';
import FormGroup from '../../../../global/components/FormGroup';
import BREAKPOINTS from '../../../../global/constants/breakpoints';
import { BORDER_COLOR } from '../../../../global/constants/styles';
import findProductByTaxRollId from '../../../../global/helpers/findProductByTaxRollId';
import pxToEms from '../../../../global/helpers/pxToEms';
import { AccountHistoryByYear } from '../../../../global/models/AccountHistory';
import { Product } from '../../../../global/models/Product';
import { CurrentYearProperty } from '../../models/currentYearProperty';
import PropertyList from './components/PropertyList';
import getCurrentTaxYear from '../../getCurrentTaxYear';
import Translate, { InlineTranslation } from '../../../../libraries/translate/translate';

interface Props {
  currentYear: number;
  products: Product[];
  accountHistoryByYear: AccountHistoryByYear;
  onYearChange: (year: string) => void;
  onPropertySelected: (year: number, billNumber: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
    marginTop: 40,
  },

  billPeriod: {
    maxWidth: 500,
    padding: 24,
    margin: `24px auto 40px auto`,
    textAlign: 'center',
    borderTop: `1px solid ${BORDER_COLOR}`,
    borderBottom: `1px solid ${BORDER_COLOR}`,

    '& > div > div': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& label': {
      marginRight: pxToEms(16),

      [theme.breakpoints.up('xxs')]: {
        lineHeight: pxToEms(56),
      },
      [theme.breakpoints.up('xs')]: {
        fontSize: pxToEms(20),
      },
    },
  },

  loading: {
    marginTop: 40,
  },

  orderSuccess: {
    maxWidth: '768px',
    margin: '40px auto 40px auto',
    [theme.breakpoints.down(768)]: {
      margin: '40px 16px 40px 16px',
    },
  },

  errorContainer: {
    margin: '72px auto',
    maxWidth: 768,

    [theme.breakpoints.down(768)]: {
      marginRight: 16,
      marginLeft: 16,
    },
  },

  listColumn: {
    [theme.breakpoints.down(BREAKPOINTS.SSM)]: {
      padding: '0',
    },
  },
}));

const YearPropertyList: FunctionComponent<Props> = ({
  products,
  accountHistoryByYear,
  currentYear,
  onYearChange,
  onPropertySelected,
}: Props) => {
  const [availableYears, setAvailableYears] = useState<number[]>([]);
  const [yearFound, setYearFound] = useState<boolean>();
  const [currentYearProperties, setCurrentYearProperties] = useState<CurrentYearProperty[]>([]);

  const styles = useStyles();

  useEffect(() => {
    // set available years based off account history
    const accountHistoryAvailableYears = Object.keys(accountHistoryByYear)
      .map((key: string) => parseInt(key))
      .sort((a, b) => b - a);

    const currentTaxYear = getCurrentTaxYear();
    if (!accountHistoryAvailableYears.includes(currentTaxYear)) {
      accountHistoryAvailableYears.push(currentTaxYear);
    }

    setAvailableYears(accountHistoryAvailableYears);
  }, [accountHistoryByYear]);

  useEffect(() => {
    setYearFound(availableYears.includes(currentYear || -1));
  }, [availableYears, currentYear]);

  useEffect(() => {
    const values: CurrentYearProperty[] = [];
    const accountHistory = accountHistoryByYear[currentYear];
    if (accountHistory) {
      const billNumbers = Object.keys(accountHistory);

      billNumbers.forEach(billNumber => {
        const propertyHistory = accountHistory[billNumber];
        const { apn, taxRollId, address } = propertyHistory;
        const propertyYearHistory = propertyHistory.taxYears[currentYear];
        const product = findProductByTaxRollId(products, taxRollId)!;

        values.push({
          taxYear: currentYear,
          apn,
          billNumber,
          taxRollId,
          address,
          propertyYearHistory,
          product,
        });
      });
    }

    setCurrentYearProperties(values);
  }, [accountHistoryByYear, currentYear]);

  const handleYearChange = useCallback(
    element => {
      onYearChange(element.target.value);
    },
    [onYearChange]
  );

  const handlePropertySelect = useCallback(
    property => {
      onPropertySelected(currentYear, property);
    },
    [currentYear]
  );

  // Repsonsibilities
  // Handle Year change and pass to parent component.
  // Display dropdown of available years and select current year
  // Render list of properties for the given year
  const titleText = InlineTranslation('Account Overview');
  return (
    <div className={styles.container}>
      <Helmet>
        <title>{titleText}</title>
      </Helmet>
      <Typography variant="h1">
        <Translate>Your ESP Overview</Translate>
      </Typography>
      <>
        <div className={styles.billPeriod}>
          <FormGroup inputName="billingYears" label="Billing Period" hideError>
            <FormControl variant="outlined">
              <Select
                labelId="billingYears"
                id="billingYears"
                displayEmpty
                value={currentYear}
                defaultValue=""
                onChange={handleYearChange}
                inputProps={{ name: 'billingYear', 'aria-label': 'billing year' }}
              >
                {!yearFound && (
                  <MenuItem key="default" value="">
                    <Translate>Select available billing year</Translate>
                  </MenuItem>
                )}

                {availableYears?.map((year: number) => (
                  <MenuItem key={year} value={year}>
                    {year} -{year + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </div>
        <ContentColumn flex className={styles.listColumn}>
          <PropertyList
            onPropertySelect={handlePropertySelect}
            currentYear={currentYear}
            currentYearProperties={currentYearProperties}
          />
        </ContentColumn>
      </>
    </div>
  );
};

export default YearPropertyList;
