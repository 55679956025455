import React, { FunctionComponent, useLayoutEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

interface Props {
  children: React.ReactNode;
  matchIndex?: number;
}

const ScrollToTop: FunctionComponent<Props> = ({ children, matchIndex }: Props) => {
  const { pathname } = useLocation();
  const params = useParams();
  const [currentPath, setCurrentPath] = useState<string>('');

  useLayoutEffect(() => {
    let match: boolean = true;
    if (matchIndex) {
      const paths = pathname.split('/');
      const currentPaths = currentPath.split('/');
      for (let i = 0; i <= matchIndex; i += 1) {
        if (currentPaths[i] !== paths[i]) {
          match = false;
        }
      }
    } else {
      match = false;
    }

    setCurrentPath(pathname);

    if (!match) {
      window.scrollTo(0, 0);
    }
  }, [pathname, params, matchIndex]);

  return <>{children}</>;
};

export default ScrollToTop;
