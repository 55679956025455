import React, { FunctionComponent, useCallback, useState } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import classnames from 'classnames';
import { BORDER_STYLE } from '../constants/styles';

interface Props {
  children: (item: any, index?: number) => React.ReactNode;
  items: any[];
  label: string;
  minWidth?: number;
  className?: string | undefined;
  disclaimer?: React.ReactNode;
  showBorder?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  carousel: (inputs: { minWidth: number; showBorder: boolean }) => ({
    '& > *:first-child': inputs.showBorder
      ? {
          [theme.breakpoints.down(inputs.minWidth)]: {
            border: BORDER_STYLE,
            boxShadow: '0 1px 4px 0 rgba(0,0,0,0.3)',

            '& > *:first-child': {
              border: 'none !important',
              boxShadow: 'none !important',
            },
          },
        }
      : {},
  }),

  navigation: (inputs: { minWidth: number }) => ({
    display: 'none',
    justifyContent: 'space-evenly',
    borderTop: BORDER_STYLE,
    padding: '0 12px',

    [theme.breakpoints.down(inputs.minWidth)]: {
      display: 'flex',
    },

    '& .label': {
      padding: '24px',
      width: '100%',
      textAlign: 'center',
      color: '#666666',
      fontSize: '14px',
    },
  }),

  navButton: {
    cursor: 'pointer',
    color: '#000',
    padding: '24px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: 'none',
    background: 'none',
  },

  navButtonDisabled: {
    cursor: 'not-allowed',
  },

  navNextButton: {
    paddingRight: '24px',
  },

  navPreviousButton: {
    paddingLeft: '24px',
  },

  disclaimers: {
    padding: '0 24px',
  },
}));

const SectionCarousel: FunctionComponent<Props> = ({
  children,
  items,
  label,
  minWidth,
  className,
  disclaimer,
  showBorder = true,
}: Props) => {
  const width = minWidth || 768;
  const isMinWidth = useMediaQuery(`(min-width:${width}px)`);
  const styles = useStyles({ minWidth: width, showBorder });

  const [index, setIndex] = useState<number>(0);

  const handlePrevious = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (index === 0) return;

      setIndex(index - 1);
    },
    [index]
  );

  const handleNext = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (index >= items.length - 1) return;

      setIndex(index + 1);
    },
    [index, items.length]
  );
  function renderNavButton(type: 'next' | 'previous', callback: any) {
    let appliedStyles = styles.navButton;
    const hasBehavior = type === 'previous' ? index !== 0 : index < items.length - 1;

    if (!hasBehavior) {
      appliedStyles = `${appliedStyles} ${styles.navButtonDisabled}`;
    }

    appliedStyles = `${appliedStyles} ${
      type === 'next' ? styles.navNextButton : styles.navPreviousButton
    }`;

    return (
      <button type="button" onClick={callback} className={appliedStyles}>
        {type === 'next' && (
          <ArrowForwardIosIcon fontSize="medium" color={hasBehavior ? 'inherit' : 'disabled'} />
        )}
        {type === 'previous' && (
          <ArrowBackIosIcon fontSize="medium" color={hasBehavior ? 'inherit' : 'disabled'} />
        )}
      </button>
    );
  }

  return (
    <div className={styles.carousel}>
      <div className={classnames([className])}>
        {isMinWidth && items.map((item: any, itemIndex: number) => children(item, itemIndex))}
        {!isMinWidth && children(items[index])}
        <div className={styles.navigation}>
          {renderNavButton('previous', handlePrevious)}
          <span className="label">
            {label} {index + 1} of {items.length}
          </span>
          {renderNavButton('next', handleNext)}
        </div>
      </div>
      {disclaimer && <div className={styles.disclaimers}>{disclaimer}</div>}
    </div>
  );
};

export default SectionCarousel;
